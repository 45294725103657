import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import AuditsPreview from 'components/Audit/AuditsPreview';
import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';

import DeleteCompanyButton from './DeleteCompanyButton';
import UpdateCompany from './UpdateCompany';
import UpdateCompanyStatus from './UpdateCompanyStatus';

const COMPANY_QUERY = gql(`
  query getCompanyInformation($id: ID!) {
    company(id: $id) {
      id
      oldPlatformId
      name
      legalName
      countryOfIncorporation
      dateOfIncorporation
      cin
      pan
      website
      image
      description
      status
      createdAt
      updatedAt
      statistics {
        currentValue
        numberOfAssets
        numberOfDeals
        totalInvestmentAmount
        realizedValue
      }
    }
  }
`);

const CompanyInformation: FC = () => {
  const { companyId } = useParams<{ companyId: string }>() as {
    companyId: string;
  };

  const { loading, error, data, refetch } = useQuery(COMPANY_QUERY, {
    variables: {
      id: companyId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const company = data.company;
  const statistics = company.statistics;

  return (
    <>
      <DetailsPanel
        data={[
          {
            label: 'Old Platform (Startup) ID',
            value: company.oldPlatformId,
          },
        ]}
      />
      <UpdateCompany
        id={company.id}
        name={company.name}
        legalName={company.legalName}
        countryOfIncorporation={company.countryOfIncorporation}
        dateOfIncorporation={company.dateOfIncorporation}
        description={company.description}
        cin={company.cin}
        pan={company.pan}
        website={company.website}
        image={company.image}
      />
      <UpdateCompanyStatus id={company.id} status={company.status} />
      <DetailsPanel
        title="Statistics"
        data={[
          {
            label: 'Total Investment Amount',
            value: statistics.totalInvestmentAmount,
            type: 'CURRENCY',
          },
          { label: 'Current Value', value: statistics.currentValue, type: 'CURRENCY' },
          { label: 'Realized Value', value: statistics.realizedValue, type: 'CURRENCY' },
          { label: 'Number of Deals', value: statistics.numberOfDeals, type: 'NUMBER' },
          { label: 'Number of Assets', value: statistics.numberOfAssets, type: 'NUMBER' },
        ]}
      />
      <AuditsPreview id={company.id} type="COMPANY" />
      <DeleteCompanyButton id={company.id} />
    </>
  );
};

export default CompanyInformation;
