import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import EmptyState from 'primitives/EmptyState';
import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import AddBankAccountDialogButton from './AddBankAccountDialogButton';
import UpdateBankAccount from './UpdateBankAccount';

const INVESTMENT_ENTITY_QUERY = gql(`
  query getInvestmentEntityBankAccounts($id: ID!) {
    investmentEntity(id: $id) {
      id
      bankAccounts {
        id
        bankName
        accountType
        accountHolderName
        accountNumber
        ifscCode
        isVerified
      }
    }
  }
`);

const InvestmentEntityBankAccounts: FC = () => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch } = useQuery(INVESTMENT_ENTITY_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const investmentEntity = data.investmentEntity;
  const bankAccounts = investmentEntity.bankAccounts;

  return (
    <>
      {bankAccounts.length === 0 ? (
        <EmptyState
          title="No bank accounts added"
          description="Add a bank account to receive funds."
          actionButton={<AddBankAccountDialogButton investmentEntityId={investmentEntity.id} />}
        />
      ) : (
        <AddBankAccountDialogButton investmentEntityId={investmentEntity.id} />
      )}
      {bankAccounts.map(bankAccount => (
        <UpdateBankAccount
          key={bankAccount.id}
          investmentEntityId={investmentEntity.id}
          id={bankAccount.id}
          accountNumber={bankAccount.accountNumber}
          accountHolderName={bankAccount.accountHolderName}
          ifscCode={bankAccount.ifscCode}
          bankName={bankAccount.bankName}
          accountType={bankAccount.accountType}
        />
      ))}
    </>
  );
};

export default InvestmentEntityBankAccounts;
