import { FC } from 'react';

import Badge from 'primitives/Badge';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableHeadRow,
  TableRow,
} from 'primitives/Table';

import { getDateTime } from 'utils/format-helper';

import { getFieldNameForCompany } from './utils/companyAuditHelper';
import { getFieldnameForDeal } from './utils/dealAuditHelper';
import { getFieldNameForFund } from './utils/fundAuditHelper';
import { getFieldNameForInvestmentEntity } from './utils/investmentEntityAuditHelper';
import { getFieldNameForSyndicate } from './utils/syndicateAuditHelper';

const Audit: FC<{
  audit: {
    operation?: 'CREATE' | 'UPDATE' | 'DELETE';
    changes: {
      fieldName: string;
      oldValue: string;
      newValue: string;
    }[];
    updatedAt: string;
    updatedBy: { name: string };
  };
  type: 'DEAL' | 'COMPANY' | 'SYNDICATE' | 'FUND' | 'INVESTMENT_ENTITY';
}> = ({ audit, type }) => {
  const { updatedAt, updatedBy, operation, changes } = audit;
  return (
    <div className="flex flex-col space-y-1 p-2 items-start">
      <p className="text-xs text-gray-600 mb-3">
        On {updatedAt}, {updatedBy.name}
        {'  '}
        <span>
          {operation === 'CREATE' && <Badge color="green" label="Created" />}
          {operation === 'UPDATE' && <Badge color="yellow" label="Updated" />}
          {operation === 'DELETE' && <Badge color="red" label="Deleted" />}
          {!operation && <Badge color="gray" label="Unknown" />}
        </span>
      </p>

      <div className="self-start bg-gray-50 rounded-lg">
        <Table>
          <TableHead>
            <TableHeadRow>
              <TableHeadCell>Field</TableHeadCell>
              <TableHeadCell>Old</TableHeadCell>
              <TableHeadCell>New</TableHeadCell>
            </TableHeadRow>
          </TableHead>
          <TableBody>
            {changes?.map((change, index) => (
              <TableRow key={index}>
                <TableCell>
                  {type === 'DEAL' && getFieldnameForDeal(change.fieldName)}
                  {type === 'COMPANY' && getFieldNameForCompany(change.fieldName)}
                  {type === 'SYNDICATE' && getFieldNameForSyndicate(change.fieldName)}
                  {type === 'FUND' && getFieldNameForFund(change.fieldName)}
                  {type === 'INVESTMENT_ENTITY' &&
                    getFieldNameForInvestmentEntity(change.fieldName)}
                </TableCell>
                <TableCell>
                  {!change.fieldName.toLowerCase().includes('date')
                    ? change.oldValue || '-'
                    : getDateTime(change.oldValue)}
                </TableCell>
                <TableCell>
                  {!change.fieldName.toLowerCase().includes('date')
                    ? change.newValue || '-'
                    : getDateTime(change.newValue)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Audit;
