import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FundClosingStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_FUND_CLOSING_MUTATION = gql(`
  mutation UpdateFundClosingStatus(
    $id: ID!
    $status: FundClosingStatusEnumType!
    $wiredAt: String
    $executedAt: String
    $finalisedAt: String
  ) {
    updateFundClosingStatus(
      id: $id
      status: $status
      wiredAt: $wiredAt
      executedAt: $executedAt
      finalisedAt: $finalisedAt
    ) {
      id
      status
      wiredAt
      executedAt
      finalisedAt
      updatedAt
      fund {
        id
        statistics {
          totalRaisedAmount
        }
      }
    }
  }
`);

const UpdateFundClosingStatus: FC<{
  id: string;
  status: FundClosingStatusEnumType;
  wiredAt?: string | null;
  executedAt?: string | null;
  finalisedAt?: string | null;
}> = ({ id, status, wiredAt, executedAt, finalisedAt }) => {
  const [updateFundClosing, { loading, error }] = useMutation(UPDATE_FUND_CLOSING_MUTATION, {
    refetchQueries: ['getFundClosingAudit', 'getFundClosingEmailAudit'],
  });

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateFundClosing({
          variables: {
            id,
            status: data.status,
            wiredAt: data.wiredAt,
            executedAt: data.executedAt,
            finalisedAt: data.finalisedAt,
          },
        });
      }}
    >
      <FormInput
        type="select"
        fieldName="status"
        label="Status"
        defaultValue={status}
        options={[
          { value: 'CREATED', label: 'Created', color: 'bg-blue-500' },
          { value: 'COMMITTED', label: 'Committed', color: 'bg-yellow-500' },
          { value: 'WIRED', label: 'Wired', color: 'bg-purple-500' },
          { value: 'EXECUTED', label: 'Executed', color: 'bg-indigo-500' },
          { value: 'FINALISED', label: 'Finalised', color: 'bg-pink-500' },
          { value: 'CANCELLED', label: 'Cancelled', color: 'bg-red-500' },
        ]}
      />
      <FormInput
        type="date"
        fieldName="wiredAt"
        label="Wired At"
        defaultValue={wiredAt || ''}
        conditionsToShow={{
          matches: [
            {
              field: 'status',
              condition: '===',
              value: 'WIRED',
            },
            {
              field: 'status',
              condition: '===',
              value: 'EXECUTED',
            },
            {
              field: 'status',
              condition: '===',
              value: 'FINALISED',
            },
          ],
          type: 'some',
        }}
      />
      <FormInput
        type="date"
        fieldName="executedAt"
        label="Executed At"
        defaultValue={executedAt || ''}
        conditionsToShow={{
          matches: [
            {
              field: 'status',
              condition: '===',
              value: 'EXECUTED',
            },
            {
              field: 'status',
              condition: '===',
              value: 'FINALISED',
            },
          ],
          type: 'some',
        }}
      />
      <FormInput
        type="date"
        fieldName="finalisedAt"
        label="Finalised At"
        defaultValue={finalisedAt || ''}
        conditionsToShow={{
          matches: [
            {
              field: 'status',
              condition: '===',
              value: 'FINALISED',
            },
          ],
        }}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateFundClosingStatus;
