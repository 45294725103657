import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { UserRoleEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_USER_ROLE_MUTATION = gql(`
  mutation UpdateUserRole($id: ID!, $role: UserRoleEnumType!) {
    updateUserRole(id: $id, role: $role) {
      id
      role
    }
  }
`);

const UpdateUserRole: FC<{
  id: string;
  role: UserRoleEnumType;
}> = ({ id, role }) => {
  const [updateUserRole, { loading, error }] = useMutation(UPDATE_USER_ROLE_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateUserRole({
          variables: {
            id,
            role: data.role,
          },
        });
      }}
      title="Role"
    >
      <FormInput
        type="select"
        fieldName="role"
        label="Role"
        defaultValue={role}
        options={[
          { label: 'Investor', value: UserRoleEnumType.Investor },
          { label: 'Operations', value: UserRoleEnumType.OperationsHandler },
          { label: 'Legal', value: UserRoleEnumType.LegalHandler },
          { label: 'Admin', value: UserRoleEnumType.Admin },
          { label: 'Super Admin', value: UserRoleEnumType.SuperAdmin },
        ]}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateUserRole;
