import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import DealFileTypeOptions from '../constants';
import RemoveDealDocumentButton from './RemoveDealDocument';

const GET_DEAL_DOCUMENT_QUERY = gql(`
  query DealDocument($id: ID!) {
    dealDocument(id: $id) {
      id
      type
      name
      description
      url
    }
  }
`);

const UPDATE_DEAL_DOCUMENT_MUTATION = gql(`
  mutation UpdateDealDocument(
    $id: ID!
    $type: DealDocumentTypeEnumType!
    $name: String!
    $description: String
  ) {
    updateDealDocument(id: $id, type: $type, name: $name, description: $description) {
      id
      type
      name
      description
    }
  }
`);

const DealDocumentSlideOver: FC<{
  open: boolean;
  documentId: string;
  onClose: () => void;
}> = ({ open = false, documentId, onClose }) => {
  const { loading, error, data, refetch } = useQuery(GET_DEAL_DOCUMENT_QUERY, {
    variables: {
      id: documentId,
    },
    skip: !open,
  });

  const [updateDocument] = useMutation(UPDATE_DEAL_DOCUMENT_MUTATION);

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const dealDocument = data.dealDocument;

    return (
      <>
        <FormPanelWithReadMode
          loading={loading}
          error={error}
          onSubmit={data =>
            updateDocument({
              variables: {
                id: documentId,
                type: data.type,
                name: data.name,
                description: data.description,
              },
            })
          }
          title="Deal Document"
        >
          <FormInput type="text" fieldName="name" label="Name" defaultValue={dealDocument.name} />
          <FormInput
            type="text"
            fieldName="description"
            label="Description"
            defaultValue={dealDocument.description}
          />
          <FormInput
            type="select"
            fieldName="type"
            label="Type"
            defaultValue={dealDocument.type}
            options={DealFileTypeOptions}
          />
        </FormPanelWithReadMode>
        <Button onClick={() => window.open(dealDocument.url, '_blank')}>View Document</Button>
        <RemoveDealDocumentButton onClose={onClose} dealDocumentId={documentId} />
      </>
    );
  }

  return (
    <SlideOver
      open={open}
      onClose={onClose}
      title="Deal Document"
      subTitle="Manage and audit deal document details"
    >
      {renderContent()}
    </SlideOver>
  );
};

export default DealDocumentSlideOver;
