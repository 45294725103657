import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const ADD_BANK_ACCOUNT_MUTATION = gql(`
  mutation AddBankAccount(
    $investmentEntityId: ID!
    $accountNumber: String!
    $accountHolderName: String!
    $bankName: String!
    $ifscCode: String!
    $accountType: BankAccountTypeEnumType!
  ) {
    addBankAccount(
      investmentEntityId: $investmentEntityId
      accountNumber: $accountNumber
      accountHolderName: $accountHolderName
      bankName: $bankName
      ifscCode: $ifscCode
      accountType: $accountType
    ) {
      id
      accountNumber
      accountHolderName
      bankName
      ifscCode
      accountType
      isVerified
    }
  }
`);

const AddBankAccountDialogButton: FC<{
  investmentEntityId: string;
}> = ({ investmentEntityId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [addBankAccount, { loading, error }] = useMutation(ADD_BANK_ACCOUNT_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: `InvestmentEntityType:${investmentEntityId}`,
        fields: {
          bankAccounts(existingBankAccounts) {
            const newAccountRef = cache.writeFragment({
              data: data.addBankAccount,
              fragment: gql(`
                fragment NewAccount on BankAccountType {
                  id
                  accountNumber
                  accountHolderName
                  bankName
                  ifscCode
                  accountType
                  isVerified
                }
              `),
            });

            return [...existingBankAccounts, newAccountRef];
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Add Bank Account</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Add bank account</DialogTitle>
          <DialogSubTitle>Enter the details of the bank account</DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data =>
              addBankAccount({
                variables: {
                  investmentEntityId,
                  accountNumber: data.accountNumber,
                  accountHolderName: data.accountHolderName,
                  bankName: data.bankName,
                  ifscCode: data.ifscCode,
                  accountType: data.accountType,
                },
              }).then(() => toggleDialog(false))
            }
            buttonRef={buttonRef}
          >
            <FormInput
              type="text"
              fieldName="accountNumber"
              label="Account Number"
              defaultValue={''}
            />
            <FormInput
              type="text"
              fieldName="accountHolderName"
              label="Account Holder Name"
              defaultValue={''}
            />
            <FormInput type="text" fieldName="ifscCode" label="IFSC Code" defaultValue={''} />
            <FormInput type="text" fieldName="bankName" label="Bank Name" defaultValue={''} />
            <FormInput
              type="select"
              fieldName="accountType"
              label="Account Type"
              defaultValue={''}
              options={[
                { label: 'Savings', value: 'SAVINGS' },
                { label: 'Current', value: 'CURRENT' },
              ]}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default AddBankAccountDialogButton;
