import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { SyndicateStatusEnumType, SyndicateTypeEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterPanel from 'components/FilterPanel';

import debounce from 'utils/debounce';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import syndicateTypeToReadable from 'utils/enums/syndicate-type-to-readable';

import CreateSyndicateDialogButton from './CreateSyndicateDialogButton';

const SYNDICATES_QUERY = gql(`
  query fetchSyndicates($cursor: ID, $limit: Int, $filters: SyndicatesFilterType) {
    syndicates(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        name
        status
        isPrivate
        type
        createdAt
        statistics {
          dealFlow
        }
      }
      pageInfo {
        hasNextPage
        cursor
      }
      statistics {
        syndicateStatisticsBreakdownBySyndicateStatus {
          status
          numberOfSyndicates
        }
        syndicateStatisticsBreakdownBySyndicateType {
          type
          numberOfSyndicates
        }
        syndicateStatisticsBreakdownBySyndicatePrivacy {
          isPrivate
          numberOfSyndicates
        }
      }
    }
  }
`);

const SyndicatesPage: FC = () => {
  const navigate = useNavigate();
  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    SYNDICATES_QUERY,
    {
      variables: {
        limit: 50,
        filters: {
          status: SyndicateStatusEnumType.Active,
          type: SyndicateTypeEnumType.Default,
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const renderContent = () => {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const syndicates = data.syndicates.nodes;
    const pageInfo = data.syndicates.pageInfo;
    const statistics = data.syndicates.statistics;

    const filters = [
      {
        type: 'status',
        label: 'Status',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.syndicateStatisticsBreakdownBySyndicateStatus.map(
            ({ status, numberOfSyndicates }) => ({
              label: `${statusEnumToReadable(status).label} (${numberOfSyndicates})`,
              value: status,
            })
          ),
        ],
        readable: statusEnumToReadable,
      },
      {
        type: 'isPrivate',
        label: 'Privacy',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.syndicateStatisticsBreakdownBySyndicatePrivacy.map(
            ({ isPrivate, numberOfSyndicates }) => ({
              label: `${isPrivate ? 'Private' : 'Public'} (${numberOfSyndicates})`,
              value: isPrivate,
            })
          ),
        ],
        readable: (isPrivate: boolean) => ({
          label: isPrivate ? 'Private' : 'Public',
          color: isPrivate ? '#FFC107' : '#4CAF50',
        }),
      },
      {
        type: 'type',
        label: 'Type',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.syndicateStatisticsBreakdownBySyndicateType.map(
            ({ type, numberOfSyndicates }) => ({
              label: `${syndicateTypeToReadable(type).label} (${numberOfSyndicates})`,
              value: type,
            })
          ),
        ],
        readable: syndicateTypeToReadable,
      },
    ];

    return (
      <>
        <FilterPanel activeFilters={variables?.filters} filters={filters} refetch={refetch} />
        <div className="flex items-center justify-between">
          <TextField
            placeholder="Search syndicates"
            onChange={debounce(e => {
              refetch({
                filters: {
                  ...variables?.filters,
                  text: e.target.value,
                },
              });
            }, 500)}
          />
          <CreateSyndicateDialogButton />
        </div>
        <DataTable
          data={syndicates}
          onClick={syndicate => navigate(`/syndicates/${syndicate.id}`)}
          columns={[
            {
              label: 'Name',
              fieldName: 'name',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Type',
              fieldName: 'type',
              type: 'SYNDICATE_TYPE',
            },
            {
              label: 'Is Private?',
              fieldName: 'isPrivate',
              type: 'BOOLEAN',
            },
            {
              label: 'Created At',
              fieldName: 'createdAt',
              type: 'DATETIME',
            },
            {
              label: 'Deal Flow',
              fieldName: 'statistics.dealFlow',
              type: 'DEAL_FLOW',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          filterLoading={networkStatus === NetworkStatus.setVariables}
          paginationLoading={loading}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation title="Syndicates" subTitle="View and manage all the syndicates" />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default SyndicatesPage;
