import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_CONVERTIBLE_VALUATION_CHANGE_MUTATION = gql(`
  mutation RemoveConvertibleValuationChange($id: ID!) {
    removeConvertibleValuationChange(id: $id) {
      id
      company {
        id
      }
    }
  }
`);

const RemoveConvertibleValuationChangeButton: FC<{ id: string; onRemove: () => void }> = ({
  id,
  onRemove,
}) => {
  const [removeConvertibleValuationChange, { loading, error }] = useMutation(
    REMOVE_CONVERTIBLE_VALUATION_CHANGE_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            id: data.removeConvertibleValuationChange.company.id,
            __typename: 'CompanyType',
          }),
          fields: {
            convertibleValuationChanges(existingConvertibleValuationChangesRef, { readField }) {
              return existingConvertibleValuationChangesRef.filter(
                convertibleValuationChangeRef =>
                  id !== readField('id', convertibleValuationChangeRef)
              );
            },
          },
        });
      },
      onCompleted() {
        onRemove();
      },
    }
  );

  return (
    <ConfirmButton
      onConfirm={() => removeConvertibleValuationChange({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will delete all data concerning this Convertible Valuation Change."
    >
      Remove Convertible Valuation Change
    </ConfirmButton>
  );
};

export default RemoveConvertibleValuationChangeButton;
