import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FundingSourceEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import SearchInvestmentEntities from './SearchInvestmentEntities';

const UPDATE_CLOSING_MUTATION = gql(`
  mutation UpdateFundClosing(
    $id: ID!
    $amount: Float!
    $fundingSource: FundingSourceEnumType!
    $investmentEntityId: ID!
  ) {
    updateFundClosing(
      id: $id
      amount: $amount
      fundingSource: $fundingSource
      investmentEntityId: $investmentEntityId
    ) {
      id
      amount
      fundingSource
      investmentEntity {
        id
        name
      }
      updatedAt
      fund {
        id
        statistics {
          totalRaisedAmount
        }
      }
    }
  }
`);

const UpdateFundClosing: FC<{
  id: string;
  amount: number;
  fundingSource: FundingSourceEnumType;
  investmentEntity: {
    id: string;
    name: string;
  };
}> = ({ id, amount, fundingSource, investmentEntity }) => {
  const [updateFundClosing, { loading, error }] = useMutation(UPDATE_CLOSING_MUTATION, {
    refetchQueries: ['getFundClosingAudit', 'getFundClosingEmailAudit'],
  });

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateFundClosing({
          variables: {
            id,
            amount: data.amount,
            fundingSource: data.fundingSource,
            investmentEntityId: data.investmentEntity.id,
          },
        });
      }}
    >
      <FormInput type="currency" fieldName="amount" label="Amount" defaultValue={amount} />
      <FormInput
        type="select"
        fieldName="fundingSource"
        label="Funding Source"
        defaultValue={fundingSource}
        options={[
          { value: 'INDIAN_ACCOUNT', label: 'Indian Account' },
          { value: 'FOREIGN_ACCOUNT', label: 'Foreign Account' },
          { value: 'NON_RESIDENT_ORDINARY_ACCOUNT', label: 'Non Resident Ordinary Account' },
          { value: 'NON_RESIDENT_EXTERNAL_ACCOUNT', label: 'Non Resident External Account' },
        ]}
      />
      <FormInput
        type="custom"
        fieldName="investmentEntity"
        label="Investment Entity"
        defaultValue={investmentEntity}
        customInput={SearchInvestmentEntities}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateFundClosing;
