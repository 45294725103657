import { ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC } from 'react';

import Badge from 'primitives/Badge';

import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import { formatCurrency } from 'utils/format-helper';

type CashAsset = {
  id: string;
  status: string;
  value: number;
  createdAt: string;
  deal: {
    id: string;
    schemeName?: string | null;
  };
};

const CashAssetCard: FC<{
  asset: CashAsset;
  onClick: (id: string) => void;
}> = ({ asset, onClick }) => {
  const { label: statusLabel, color: statusColor } = statusEnumToReadable(asset.status);

  function lineItem(label: string, value: string, color: string = 'text-gray-800') {
    return (
      <div className="my-0.5 gap-x-2 grid grid-cols-2">
        <span className="text-sm leading-5 text-gray-600">{label}:</span>
        <span className={classNames('text-sm font-semibold leading-5', color)}>{value}</span>
      </div>
    );
  }

  return (
    <li
      className="relative flex justify-between items-start gap-x-6 px-4 py-3 hover:bg-gray-50 cursor-pointer"
      onClick={() => onClick(asset.id)}
    >
      <div className="min-w-0 flex-auto">
        <Badge label="Cash Asset" color="green" />
        <div className="grid grid-cols-2 gap-x-4 mt-2">
          {lineItem('Value', formatCurrency(asset.value))}
          {lineItem('Scheme', asset.deal.schemeName || 'Unknown Scheme')}
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-x-4">
        <div className="hidden sm:flex sm:flex-col sm:items-end">
          <Badge label={statusLabel} color={statusColor} />
          <p className="mt-1 text-xs leading-5 text-gray-500">
            Created on{' '}
            <time dateTime={dayjs(asset.createdAt).format('DD MMMM YYYY')}>
              {dayjs(asset.createdAt).format('DD MMMM YYYY')}
            </time>
          </p>
        </div>
        <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
      </div>
    </li>
  );
};

export default CashAssetCard;
