import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const CREATE_SYNDICATE_MUTATION = gql(`
  mutation CreateSyndicate(
    $name: String!
    $tagline: String!
    $description: String
    $isPrivate: Boolean!
  ) {
    createSyndicate(
      name: $name
      tagline: $tagline
      description: $description
      isPrivate: $isPrivate
    ) {
      id
      name
      isPrivate
      tagline
      description
      status
      createdAt
      updatedAt
    }
  }
`);

const CreateSyndicateDialogButton: FC = () => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [createSyndicate, { loading, error }] = useMutation(CREATE_SYNDICATE_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        fields: {
          syndicates(existingSyndicatesRef) {
            const newSyndicateRef = cache.writeFragment({
              data: data.createSyndicate,
              fragment: gql(`
                fragment NewSyndicate on SyndicateType {
                  id
                  name
                  tagline
                  description
                  isPrivate
                  status
                  createdAt
                  updatedAt
                }
              `),
            });

            const updatedSyndicatesRef = { ...existingSyndicatesRef };
            updatedSyndicatesRef.nodes = [newSyndicateRef, ...updatedSyndicatesRef.nodes];
            return updatedSyndicatesRef;
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Create Syndicate</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Create a Syndicate</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              createSyndicate({
                variables: {
                  name: data.name,
                  tagline: data.tagline,
                  description: data.description,
                  isPrivate: data.isPrivate,
                },
              }).then(() => toggleDialog(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput type="text" fieldName="name" label="Name" defaultValue="" />
            <FormInput type="text" fieldName="tagline" label="Tagline" defaultValue="" />
            <FormInput type="text" fieldName="description" label="Description" defaultValue="" />
            <FormInput
              type="switch"
              fieldName="isPrivate"
              label="Is private?"
              defaultValue={false}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateSyndicateDialogButton;
