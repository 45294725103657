import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_EMAIL_PREFERENCES_MUTATION = gql(`
  mutation UpdateUserEmailPreferences(
    $id: ID!
    $newSyndicateApplications: Boolean!
    $syndicateSuggestions: Boolean!
    $monthlyNewsDigest: Boolean!
    $dealProgressUpdates: Boolean!
    $dealInviteReminders: Boolean!
    $dealInvites: EmailFrequencyEnumType!
    $dealInviteUpdates: EmailFrequencyEnumType!
    $portfolioCompanyUpdates: Boolean!
    $messages: Boolean!
  ) {
    updateUserEmailPreferences(
      id: $id
      newSyndicateApplications: $newSyndicateApplications
      syndicateSuggestions: $syndicateSuggestions
      monthlyNewsDigest: $monthlyNewsDigest
      dealProgressUpdates: $dealProgressUpdates
      dealInviteReminders: $dealInviteReminders
      dealInvites: $dealInvites
      dealInviteUpdates: $dealInviteUpdates
      portfolioCompanyUpdates: $portfolioCompanyUpdates
      messages: $messages
    ) {
      id
      newSyndicateApplications
      syndicateSuggestions
      monthlyNewsDigest
      dealProgressUpdates
      dealInviteReminders
      dealInvites
      dealInviteUpdates
      portfolioCompanyUpdates
      messages
    }
  }
`);

const UpdateEmailPreferences: FC<{
  id: string;
  dealInviteReminders: boolean;
  dealInviteUpdates: 'IMMEDIATELY' | 'DAILY' | 'NEVER';
  dealInvites: 'IMMEDIATELY' | 'DAILY' | 'NEVER';
  dealProgressUpdates: boolean;
  messages: boolean;
  monthlyNewsDigest: boolean;
  newSyndicateApplications: boolean;
  portfolioCompanyUpdates: boolean;
  syndicateSuggestions: boolean;
}> = ({
  id,
  dealInviteReminders,
  dealInviteUpdates,
  dealInvites,
  dealProgressUpdates,
  messages,
  monthlyNewsDigest,
  newSyndicateApplications,
  portfolioCompanyUpdates,
  syndicateSuggestions,
}) => {
  const [updateUserEmailPreferences, { loading, error }] = useMutation(
    UPDATE_EMAIL_PREFERENCES_MUTATION
  );

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateUserEmailPreferences({
          variables: {
            id,
            newSyndicateApplications: data.newSyndicateApplications,
            syndicateSuggestions: data.syndicateSuggestions,
            monthlyNewsDigest: data.monthlyNewsDigest,
            dealProgressUpdates: data.dealProgressUpdates,
            dealInviteReminders: data.dealInviteReminders,
            dealInvites: data.dealInvites,
            dealInviteUpdates: data.dealInviteUpdates,
            portfolioCompanyUpdates: data.portfolioCompanyUpdates,
            messages: data.messages,
          },
        });
      }}
      title="Email Preferences"
    >
      <FormInput
        type="switch"
        fieldName="newSyndicateApplications"
        label="New Syndicate Applications"
        helperText="Email notification for each Syndicate application"
        defaultValue={newSyndicateApplications}
      />
      <FormInput
        type="switch"
        fieldName="syndicateSuggestions"
        label="Syndicate Suggestions"
        helperText="Suggestions for syndicates you might like, sent monthly"
        defaultValue={syndicateSuggestions}
      />
      <FormInput
        type="switch"
        fieldName="monthlyNewsDigest"
        label="Monthly News Digest"
        helperText="News articles and updates on companies in your portfolio"
        defaultValue={monthlyNewsDigest}
      />
      <FormInput
        type="switch"
        fieldName="dealProgressUpdates"
        label="Deal Progress Updates"
        helperText="Sent daily 9:30 am IST"
        defaultValue={dealProgressUpdates}
      />
      <FormInput
        type="switch"
        fieldName="dealInviteReminders"
        label="Deal Invite Reminders"
        helperText="Reminders about deals you receive immediate notifications about"
        defaultValue={dealInviteReminders}
      />
      <FormInput
        type="select"
        options={[
          { label: 'Immediately', value: 'IMMEDIATELY' },
          { label: 'Daily Digest', value: 'DAILY' },
          { label: 'Never', value: 'NEVER' },
        ]}
        fieldName="dealInvites"
        label="Deal Invites"
        helperText="Deal invites from Syndicates you have joined"
        defaultValue={dealInvites}
      />
      <FormInput
        type="select"
        options={[
          { label: 'Immediately', value: 'IMMEDIATELY' },
          { label: 'Daily Digest', value: 'DAILY' },
          { label: 'Never', value: 'NEVER' },
        ]}
        fieldName="dealInviteUpdates"
        label="Deal Invite Updates"
        helperText="Posts from leads about invited deals"
        defaultValue={dealInviteUpdates}
      />
      <FormInput
        type="switch"
        fieldName="portfolioCompanyUpdates"
        label="Portfolio Company Updates"
        helperText="Posts from leads about companies you have invested in"
        defaultValue={portfolioCompanyUpdates}
      />
      <FormInput
        type="switch"
        fieldName="messages"
        label="New Messages"
        helperText="You will still see messages in the app"
        defaultValue={messages}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateEmailPreferences;
