import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import alIndiaLogo from 'media/alIndiaLogo.png';

interface TabItem {
  key: string;
  label: string;
  route: string;
  icon?: string;
  exact?: boolean;
}

const Sidebar = ({ tabs, mobile = false }: { tabs: TabItem[]; mobile?: boolean }) => {
  const location = useLocation();

  return (
    <div
      className={classNames(
        'flex grow flex-col gap-y-3 overflow-y-auto bg-gray-50 px-3.5 pb-4',
        mobile ? 'border-none' : null
      )}
    >
      <NavLink to="/">
        <div className="mt-4 ml-1.5">
          <img className="h-6 w-auto" src={alIndiaLogo} alt="Your Company" />
          <p className="text-lg font-black text-indigo-400 ml-[1.3rem]">ADMIN CONSOLE</p>
        </div>
      </NavLink>
      <nav className="mt-1 flex flex-1 flex-col">
        <ul className="flex flex-1 flex-col gap-y-1">
          {tabs.map(tab => {
            function isActive() {
              if (tab?.exact) {
                return location.pathname === tab.route;
              }
              return (
                location.pathname === tab.route ||
                (location.pathname.startsWith(tab.route) && tab.route !== '/')
              );
            }

            return (
              <li key={tab.key}>
                <NavLink
                  to={tab.route}
                  className={classNames(
                    isActive()
                      ? 'bg-gray-200 text-indigo-600'
                      : 'text-gray-700 hover:text-indigo-600 hover:bg-gray-100',
                    'group flex gap-x-3 rounded-md p-1 text-sm leading-6 font-medium'
                  )}
                >
                  <img
                    src={tab.icon}
                    alt="icon"
                    className={classNames(
                      isActive() ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                      'h-5 w-5 shrink-0'
                    )}
                    aria-hidden="true"
                  />
                  {tab.label}
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
