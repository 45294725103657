import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

const UPDATE_SYNDICATE_MUTATION = gql(`
  mutation UpdateSyndicate(
    $id: ID!
    $name: String!
    $isPrivate: Boolean!
    $tagline: String!
    $description: String
  ) {
    updateSyndicate(
      id: $id
      name: $name
      isPrivate: $isPrivate
      tagline: $tagline
      description: $description
    ) {
      id
      name
      isPrivate
      tagline
      description
      updatedAt
    }
  }
`);

const UpdateSyndicate: FC<{
  id: string;
  name: string;
  tagline: string;
  description?: string | null;
  isPrivate: boolean;
}> = ({ id, name, tagline, description, isPrivate }) => {
  const [updateSyndicate, { loading, error }] = useMutation(UPDATE_SYNDICATE_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateSyndicate({
          variables: {
            id,
            name: data.name,
            isPrivate: data.isPrivate,
            tagline: data.tagline,
            description: data.description,
          },
        });
      }}
      title="Information"
    >
      <FormInput type="text" fieldName="name" label="Name" defaultValue={name} />
      <FormInput type="text" fieldName="tagline" label="Tagline" defaultValue={tagline} />
      <FormInput
        type="custom"
        fieldName="description"
        label="Description"
        defaultValue={description}
        customInput={EditorFormInput}
      />
      <FormInput type="switch" fieldName="isPrivate" label="Is private?" defaultValue={isPrivate} />
    </FormPanelWithReadMode>
  );
};

export default UpdateSyndicate;
