import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import debounce from 'utils/debounce';

const DEALS_QUERY = gql(`
  query fetchDealsForApproval(
    $cursor: ID
    $limit: Int
    $filters: DealsFilterType
    $sortBy: DealSortByEnumType
    $sortType: SortTypeEnumType
  ) {
    deals(cursor: $cursor, limit: $limit, filters: $filters, sortBy: $sortBy, sortType: $sortType) {
      nodes {
        id
        companyInformation {
          company {
            name
          }
        }
        type
        allocation
        closingDate
        status
        createdAt
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`);

const ApproveDealsPage: FC = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    DEALS_QUERY,
    {
      variables: {
        limit: 50,
        filters: {
          status: DealStatusEnumType.UnderOpsReview,
        },
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const deals = data.deals.nodes;
  const pageInfo = data.deals.pageInfo;

  return (
    <>
      <TextField
        placeholder="Search deals"
        onChange={debounce(e => {
          refetch({
            filters: {
              ...variables?.filters,
              text: e.target.value,
            },
          });
        }, 500)}
      />
      <DataTable
        data={deals}
        onClick={deal => navigate(`/deals/${deal.id}`)}
        columns={[
          {
            label: 'Company',
            fieldName: 'companyInformation.company.name',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Type',
            fieldName: 'type',
            type: 'DEAL_TYPE',
          },
          {
            label: 'Allocation',
            fieldName: 'allocation',
            type: 'CURRENCY',
          },
          {
            label: 'Closing Date',
            fieldName: 'closingDate',
            type: 'DATE',
          },
          {
            label: 'Created At',
            fieldName: 'createdAt',
            type: 'DATE',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        filterLoading={networkStatus === NetworkStatus.setVariables}
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default ApproveDealsPage;
