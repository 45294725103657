import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';
import LoadingIndicator from 'primitives/LoadingIndicator';

import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';

import RemoveUserFromSyndicateButton from './RemoveUserFromSyndicateButton';

const SYNDICATE_USER_QUERY = gql(`
  query getSyndicateUser($id: ID!) {
    syndicateUser(id: $id) {
      id
      user {
        id
        name
        email
      }
      createdAt
    }
  }
`);

const SyndicateUserDialog: FC<{
  syndicateUserId: string;
  onClose: () => void;
}> = ({ syndicateUserId, onClose }) => {
  const { loading, error, data, refetch } = useQuery(SYNDICATE_USER_QUERY, {
    variables: {
      id: syndicateUserId,
    },
  });

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const syndicateUser = data.syndicateUser;

    return (
      <>
        <RemoveUserFromSyndicateButton syndicateUserId={syndicateUserId} onClose={onClose} />
        <DetailsPanel
          title="User Details"
          data={[
            {
              label: 'Name',
              value: syndicateUser.user.name,
            },
            {
              label: 'Email',
              value: syndicateUser.user.email,
            },
            {
              label: 'Joined At',
              value: syndicateUser.createdAt,
              type: 'DATETIME',
            },
          ]}
        />
      </>
    );
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogPanel>
        <DialogTitle>Syndicate User</DialogTitle>
        {renderContent()}
      </DialogPanel>
    </Dialog>
  );
};

export default SyndicateUserDialog;
