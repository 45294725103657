import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import Statistics from 'components/Statistics';

import PortfolioChart from './PortfolioChart';

const INVESTMENTS_QUERY = gql(`
  query fetchInvestmentsByInvestmentId($id: ID!) {
    investmentEntity(id: $id) {
      id
      closings {
        nodes {
          id
          deal {
            id
            type
            companyInformation {
              company {
                name
              }
            }
          }
          amount
          status
          wiredAt
          statistics {
            netValue
            multipleOnInvestment
            ownershipPercentage
          }
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
      statistics {
        totalCommittedAmount
        totalInvestedAmount
        totalRealizedValue
        totalUnrealizedValue
        numberOfDeals
        numberOfExits
        numberOfCommitments
      }
    }
  }
`);

const InvestmentEntityInvestments: FC = () => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch, fetchMore } = useQuery(INVESTMENTS_QUERY, {
    variables: {
      limit: 50,
      id: investmentEntityId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const investments = data.investmentEntity.closings.nodes;
  const pageInfo = data.investmentEntity.closings.pageInfo;
  const statistics = data.investmentEntity.statistics;

  return (
    <>
      <PortfolioChart investmentEntityId={investmentEntityId} />
      <Statistics
        statistics={[
          {
            label: 'Total Amount Invested',
            value: statistics.totalCommittedAmount,
            type: 'CURRENCY',
          },
          {
            label: 'Total Realized Value',
            value: statistics.totalRealizedValue,
            type: 'CURRENCY',
          },
          {
            label: 'Total Unrealized Value',
            value: statistics.totalUnrealizedValue,
            type: 'CURRENCY',
          },
          { label: 'Number Of Deals', value: statistics.numberOfDeals },
          { label: 'Number Of Exits', value: statistics.numberOfExits },
          {
            label: 'Number Of Commitments',
            value: statistics.numberOfCommitments,
          },
        ]}
      />
      <DataTable
        data={investments}
        columns={[
          {
            label: 'Company',
            fieldName: 'deal.companyInformation.company.name',
          },
          {
            label: 'Type',
            fieldName: 'deal.type',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Invested',
            fieldName: 'amount',
            type: 'CURRENCY',
          },
          {
            label: 'Invest Date',
            fieldName: 'wiredAt',
            type: 'DATE',
          },
          {
            label: 'Net Value',
            fieldName: 'statistics.netValue',
            type: 'CURRENCY',
          },
          {
            label: 'Multiple',
            fieldName: 'statistics.multipleOnInvestment',
            type: 'MULTIPLE',
          },
          {
            label: 'Ownership Percentage (%)',
            fieldName: 'statistics.ownershipPercentage',
            type: 'PERCENTAGE',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default InvestmentEntityInvestments;
