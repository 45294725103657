import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import AddLeadToSyndicateDialogButton from './AddLeadToSyndicateDialogButton';
import SyndicateLeadDialog from './SyndicateLeadDialog';

const LEADS_QUERY = gql(`
  query fetchLeadsBySyndicate($syndicateId: ID!) {
    syndicate(id: $syndicateId) {
      id
      syndicateLeads {
        id
        role
        user {
          id
          name
          email
        }
        createdAt
      }
    }
  }
`);

const SyndicateLeads: FC = () => {
  const [syndicateLeadOnDisplay, setSyndicateLeadOnDisplay] = useState('');
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };

  const { loading, error, data, refetch } = useQuery(LEADS_QUERY, {
    variables: {
      syndicateId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const syndicate = data.syndicate;
  const syndicateLeads = syndicate.syndicateLeads;

  return (
    <>
      <AddLeadToSyndicateDialogButton syndicateId={syndicate.id} />
      {syndicateLeadOnDisplay && (
        <SyndicateLeadDialog
          syndicateLeadId={syndicateLeadOnDisplay}
          onClose={() => setSyndicateLeadOnDisplay('')}
        />
      )}
      <DataTable
        data={syndicateLeads}
        searchFields={['user.name']}
        onClick={user => setSyndicateLeadOnDisplay(user.id)}
        columns={[
          {
            label: 'Name',
            fieldName: 'user.name',
          },
          {
            label: 'Email',
            fieldName: 'user.email',
          },
          {
            label: 'Role',
            fieldName: 'role',
            type: 'SYNDICATE_LEAD_ROLE',
          },
          {
            label: 'Joined At',
            fieldName: 'createdAt',
            type: 'DATETIME',
          },
        ]}
      />
    </>
  );
};

export default SyndicateLeads;
