import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealSortByEnumType, DealStatusEnumType, SortTypeEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterPanel from 'components/FilterPanel';

import debounce from 'utils/debounce';
import dealTypeEnumToReadable from 'utils/enums/deal-type-to-readable';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

const DEALS_QUERY = gql(`
  query fetchDeals(
    $cursor: ID
    $limit: Int
    $filters: DealsFilterType
    $sortBy: DealSortByEnumType
    $sortType: SortTypeEnumType
  ) {
    deals(cursor: $cursor, limit: $limit, filters: $filters, sortBy: $sortBy, sortType: $sortType) {
      nodes {
        id
        companyInformation {
          company {
            name
          }
        }
        dealTerms {
          id
          hasPreviouslyRaisedMoney
        }
        type
        allocation
        closingDate
        status
        schemeName
        createdAt
        updatedAt
        statistics {
          totalWiredAmount
          multiple
        }
      }
      pageInfo {
        hasNextPage
        cursor
        totalCount
      }
      statistics {
        dealStatisticsBreakdownByDealStatus {
          status
          numberOfDeals
        }
        dealStatisticsBreakdownByDealType {
          type
          numberOfDeals
        }
      }
    }
  }
`);

const DealsPage: FC = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    DEALS_QUERY,
    {
      variables: {
        limit: 50,
        filters: {
          status: DealStatusEnumType.Raising,
          type: undefined,
        },
        sortBy: DealSortByEnumType.CreatedAt,
        sortType: SortTypeEnumType.Descending,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const renderContent = () => {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const deals = data.deals.nodes;
    const pageInfo = data.deals.pageInfo;
    const statistics = data.deals.statistics;

    const filters = [
      {
        type: 'status',
        label: 'Status',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.dealStatisticsBreakdownByDealStatus.map(({ status, numberOfDeals }) => ({
            label: `${statusEnumToReadable(status).label} (${numberOfDeals})`,
            value: status,
          })),
        ],
        readable: statusEnumToReadable,
      },
      {
        type: 'type',
        label: 'Type',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.dealStatisticsBreakdownByDealType.map(({ type, numberOfDeals }) => ({
            label: `${dealTypeEnumToReadable(type).label} (${numberOfDeals})`,
            value: type,
          })),
        ],
        readable: dealTypeEnumToReadable,
      },
    ];

    return (
      <>
        <FilterPanel activeFilters={variables?.filters} filters={filters} refetch={refetch} />
        <TextField
          placeholder="Search deals"
          onChange={debounce(e => {
            refetch({
              filters: {
                ...variables?.filters,
                text: e.target.value,
              },
            });
          }, 500)}
        />
        <DataTable
          data={deals}
          onClick={deal => navigate(`/deals/${deal.id}`)}
          columns={[
            {
              label: 'Company',
              fieldName: 'companyInformation.company.name',
            },
            {
              label: 'Scheme',
              fieldName: 'schemeName',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Type',
              fieldName: 'type',
            },
            {
              label: 'Allocation',
              fieldName: 'allocation',
              type: 'CURRENCY',
            },
            {
              label: 'Total Wired Amount',
              fieldName: 'statistics.totalWiredAmount',
              type: 'CURRENCY',
            },
            {
              label: 'Closing Date',
              fieldName: 'closingDate',
              type: 'DATETIME',
            },
            {
              label: 'Created At',
              fieldName: 'createdAt',
              type: 'DATETIME',
            },
            {
              label: 'Multiple',
              fieldName: 'statistics.multiple',
              type: 'MULTIPLE',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          filterLoading={networkStatus === NetworkStatus.setVariables}
          paginationLoading={loading}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation title="Deals" subTitle="View and manage all the deals" />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default DealsPage;
