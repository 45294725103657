import dayjs from 'dayjs';
import { FC } from 'react';

import Badge from 'primitives/Badge';

import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import { fixToTwoLocalPrice } from 'utils/format-helper';

type Data = {
  label?: string;
  value?: any;
  customValue?: React.ElementType;
  type?:
    | 'DATE'
    | 'DATETIME'
    | 'MONTH'
    | 'STRING'
    | 'CURRENCY'
    | 'PERCENTAGE'
    | 'IMAGE'
    | 'NUMBER'
    | 'STATUS'
    | 'FILE'
    | 'SELECT'
    | 'CUSTOM'
    | 'PRODUCTS'
    | 'LINK'
    | 'EXTERNAL_LINK'
    | 'BOOLEAN';
  helperText?: string;
  navigateTo?: string;
};

const getValue = (
  value: Data['value'],
  type: Data['type'],
  navigateTo?: Data['navigateTo'],
  customValue?: Data['customValue']
) => {
  if (value === undefined || value === null || value === '') return '-';

  switch (type) {
    case 'CURRENCY':
      return `₹ ${fixToTwoLocalPrice(value)}`;
    case 'PERCENTAGE':
      return `${value.toFixed(2)} %`;
    case 'DATE':
      return dayjs(value).format('DD MMMM YYYY');
    case 'DATETIME':
      return dayjs(value).format('h:mm A, DD MMMM YYYY');
    case 'MONTH':
      return dayjs(value).format('MMMM YYYY');
    case 'FILE':
      return value ? (
        typeof value === 'string' ? (
          <a
            href={value}
            rel="noreferrer"
            target="_blank"
            className="border p-2 cursor-pointer bg-indigo-600 hover:bg-indigo-500 rounded-md text-white"
          >
            Download
          </a>
        ) : (
          <img
            className="h-32 w-32 bg-contain bg-center bg-no-repeat border border-gray-200"
            alt={'File'}
            style={{
              backgroundImage: `url("${value}")`,
            }}
            src={value}
          />
        )
      ) : (
        'Not Available'
      );
    case 'IMAGE':
      return (
        <img
          className="h-32 w-32 bg-contain bg-center bg-no-repeat border border-gray-200"
          alt={'File'}
          style={{
            backgroundImage: `url("${value}")`,
          }}
          src={value}
        />
      );
    case 'NUMBER':
      return value;
    case 'STATUS':
      const { label, color } = statusEnumToReadable(value);
      return <Badge label={label} color={color} showIndicator={true} />;
    case 'LINK':
      return (
        <a
          className="text-blue-500 hover:underline"
          href={navigateTo || ''}
          target="_blank"
          rel="noreferrer"
        >
          {value}
        </a>
      );
    case 'EXTERNAL_LINK':
      return (
        <a href={navigateTo || ''} rel="noreferrer" target="_blank">
          {value}
        </a>
      );
    case 'BOOLEAN':
      return value ? 'Yes' : 'No';
    case 'CUSTOM':
      const CustomValue: React.ElementType = customValue ? customValue : () => <></>;
      return <CustomValue value={value} />;
    default:
      return value;
  }
};

const DetailsPanel: FC<{
  data: Data[];
  title?: string;
  subTitle?: string;
  displayHorizontally?: boolean;
}> = ({ data, title, subTitle, displayHorizontally }) => {
  if (displayHorizontally)
    return (
      <div className="shadow-sm ring-1 ring-gray-200 rounded-md sm:rounded-lg md:col-span-2">
        {title && (
          <div className="border-b border-gray-200 px-4 py-4 sm:px-4">
            <h2 className="text-lg font-semibold leading-7 text-gray-900 sm:truncate sm:text-lg sm:tracking-tight">
              {title}
            </h2>
            <div className="flex items-center text-sm text-gray-600">{subTitle}</div>
          </div>
        )}
        <div className="flex flex-col px-4 py-2 gap-y-2">
          {data.map(d => (
            <div key={d.label} className="flex flex-row justify-between">
              <div className="flex flex-col basis-1/3 self-start">
                <p className="text-sm font-medium leading-6 text-gray-900">{d.label}</p>
                {d.helperText && <p className="mb-2 text-sm text-gray-500">{d.helperText}</p>}
              </div>
              <div className="text-gray-900 sm:text-sm basis-2/3 self-start">
                {getValue(d.value, d.type, d.navigateTo, d.customValue)}
              </div>
            </div>
          ))}
        </div>
      </div>
    );

  return (
    <div className="shadow-sm ring-1 ring-gray-200 rounded-md sm:rounded-lg md:col-span-2">
      {title && (
        <div className="border-b border-gray-200 px-4 py-4 sm:px-4">
          <h2 className="text-lg font-semibold leading-7 text-gray-900 sm:truncate sm:text-lg sm:tracking-tight">
            {title}
          </h2>
          <div className="flex items-center text-sm text-gray-600">{subTitle}</div>
        </div>
      )}
      <div className="grid grid-cols-1 gap-x-6 gap-y-4 sm:grid-cols-6 px-4 py-3 sm:p-4">
        {data.map(d => (
          <div key={d.label} className="col-span-full">
            <p className="block text-sm font-medium leading-6 text-gray-900">{d.label}</p>
            {d.helperText && <p className="mt-2 text-sm text-gray-500">{d.helperText}</p>}
            <div className="block py-2.5 text-gray-900 sm:text-sm">
              {getValue(d.value, d.type, d.navigateTo, d.customValue)}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DetailsPanel;
