import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import AddUserToSyndicateDialogButton from './AddUserToSyndicateDialogButton';
import SyndicateUserDialog from './SyndicateUserDialog';

const USERS_QUERY = gql(`
  query fetchUsersBySyndicate($syndicateId: ID!, $cursor: ID, $limit: Int) {
    syndicate(id: $syndicateId) {
      id
      syndicateUsers(cursor: $cursor, limit: $limit) {
        nodes {
          id
          user {
            id
            name
            email
          }
          createdAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const SyndicateUsers: FC = () => {
  const [syndicateUserOnDisplay, setSyndicateUserOnDisplay] = useState('');
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };

  const { loading, error, data, refetch, fetchMore } = useQuery(USERS_QUERY, {
    variables: {
      limit: 50,
      syndicateId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const syndicate = data.syndicate;
  const syndicateUsers = syndicate.syndicateUsers.nodes;
  const pageInfo = syndicate.syndicateUsers.pageInfo;

  return (
    <>
      <AddUserToSyndicateDialogButton syndicateId={syndicate.id} />
      {syndicateUserOnDisplay && (
        <SyndicateUserDialog
          syndicateUserId={syndicateUserOnDisplay}
          onClose={() => setSyndicateUserOnDisplay('')}
        />
      )}
      <DataTable
        data={syndicateUsers}
        searchFields={['user.name']}
        onClick={user => setSyndicateUserOnDisplay(user.id)}
        columns={[
          {
            label: 'Name',
            fieldName: 'user.name',
          },
          {
            label: 'Email',
            fieldName: 'user.email',
          },
          {
            label: 'Joined At',
            fieldName: 'createdAt',
            type: 'DATETIME',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default SyndicateUsers;
