import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_DEAL_DOCUMENT_MUTATION = gql(`
  mutation RemoveDealDocument($id: ID!) {
    removeDealDocument(id: $id) {
      id
      dealId
    }
  }
`);

const RemoveDealDocumentButton: FC<{ dealDocumentId: string; onClose: () => void }> = ({
  dealDocumentId,
  onClose,
}) => {
  const navigate = useNavigate();

  const [removeDealDocument, { loading, error }] = useMutation(REMOVE_DEAL_DOCUMENT_MUTATION, {
    refetchQueries: ['fetchDealDocuments'],
    onCompleted(data) {
      navigate(`/deals/${data.removeDealDocument.dealId}/documents`);
    },
  });

  return (
    <ConfirmButton
      onConfirm={() => {
        removeDealDocument({ variables: { id: dealDocumentId } });
        onClose();
      }}
      loading={loading}
      error={error}
      description="This will delete all data concerning this Asset."
    >
      Remove
    </ConfirmButton>
  );
};

export default RemoveDealDocumentButton;
