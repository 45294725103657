import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const SEND_PAYMENT_INFORMATION_MUTATION = gql(`
  mutation SendPaymentInformationForClosing($id: ID!) {
    sendPaymentInformationForClosing(id: $id)
  }
`);

const SendPaymentInformationButton: FC<{ id: string }> = ({ id }) => {
  const [sendPaymentInformationForClosing, { loading, error }] = useMutation(
    SEND_PAYMENT_INFORMATION_MUTATION
  );

  return (
    <ConfirmButton
      onConfirm={() => sendPaymentInformationForClosing({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will send the user an email calling for them to make the payment"
    >
      Send Payment Information
    </ConfirmButton>
  );
};

export default SendPaymentInformationButton;
