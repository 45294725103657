import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';
import Statistics from 'components/Statistics';

const DEAL_VALUATION_QUERY = gql(`
  query GetDealValuationStatistics($id: ID!) {
    deal(id: $id) {
      id
      statistics {
        totalWiredAmount
        totalRealizedValue
        totalUnrealizedValue
        multiple
      }
    }
  }
`);

const ValuationStatistics: FC<{ dealId: string }> = ({ dealId }) => {
  const { loading, error, data, refetch } = useQuery(DEAL_VALUATION_QUERY, {
    variables: {
      id: dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const deal = data.deal;

  return (
    <Statistics
      statistics={[
        {
          label: 'Wired Amount',
          value: deal.statistics.totalWiredAmount,
          type: 'CURRENCY',
        },
        {
          label: 'Unrealized Value',
          value: deal.statistics.totalUnrealizedValue,
          type: 'CURRENCY',
        },
        {
          label: 'Multiple',
          value: deal.statistics.multiple,
          type: 'MULTIPLE',
        },
        {
          label: 'Realized Value',
          value: deal.statistics.totalRealizedValue,
          type: 'CURRENCY',
        },
      ]}
    />
  );
};

export default ValuationStatistics;
