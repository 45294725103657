import { gql } from '__generated__';

const getFieldNameForSyndicate = (field: string) => {
  const fieldToReadableNameMap = {
    oldPlatformId: 'Old Platform ID',
    name: 'Name',
    tagline: 'Tagline',
    description: 'Description',
    image: 'Image',
    isPrivate: 'Private',
    applicationInformation: 'Application Information',
    status: 'Status',
    managerId: 'Manager',
    type: 'Type',
    disclosure: 'Disclosure',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
  };

  return fieldToReadableNameMap[field] || field;
};

const SYNDICATE_AUDIT_QUERY = gql(`
    query SyndicateAudit($id: ID!, $page: Int, $limit: Int) {
      syndicate(id: $id) {
        audits(page: $page, limit: $limit) {
          nodes {
            operation
            changes {
              fieldName
              oldValue
              newValue
            }
            updatedAt
            updatedBy {
              name
            }
          }
          pageInfo {
            totalCount
            hasNextPage
          }
        }
      }
    }
  `);

export { getFieldNameForSyndicate, SYNDICATE_AUDIT_QUERY };
