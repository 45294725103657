import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import CompanyEventSlideOver from './CompanyEventSlideOver';
import CreateCompanyEventDialogButton from './CreateEventDialogButton';

const EVENTS_QUERY = gql(`
  query fetchEventsByCompany($id: ID!) {
    company(id: $id) {
      id
      events {
        id
        label
        type
        effectiveFrom
        createdAt
      }
    }
  }
`);

const CompanyEvents: FC = () => {
  const [eventOnDisplay, setEventOnDisplay] = useState('');
  const { companyId } = useParams<{ companyId: string }>() as { companyId: string };

  const { loading, error, data, refetch } = useQuery(EVENTS_QUERY, {
    variables: {
      id: companyId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const events = data.company.events;

  return (
    <>
      <CompanyEventSlideOver
        open={!!eventOnDisplay}
        companyEventId={eventOnDisplay}
        onClose={() => setEventOnDisplay('')}
      />
      <div className="flex justify-between items-center">
        <CreateCompanyEventDialogButton companyId={companyId} />
      </div>
      <DataTable
        data={events}
        onClick={event => setEventOnDisplay(event.id)}
        columns={[
          {
            label: 'Label',
            fieldName: 'label',
          },
          {
            label: 'Type',
            fieldName: 'type',
            type: 'COMPANY_EVENT_TYPE',
          },
          {
            label: 'Effective From',
            fieldName: 'effectiveFrom',
            type: 'DATETIME',
          },
          {
            label: 'Created At',
            fieldName: 'createdAt',
            type: 'DATETIME',
          },
        ]}
      />
    </>
  );
};

export default CompanyEvents;
