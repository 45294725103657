import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_FUND_STATUS_MUTATION = gql(`
  mutation UpdateFundStatus($id: ID!, $status: FundStatusEnumType!) {
    updateFundStatus(id: $id, status: $status) {
      id
      status
      updatedAt
    }
  }
`);

const UpdateFundStatus: FC<{ id: string; status: 'ACTIVE' | 'INACTIVE' | 'UNDER_REVIEW' }> = ({
  id,
  status,
}) => {
  const [updateFundStatus, { loading, error }] = useMutation(UPDATE_FUND_STATUS_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        fields: {
          funds(existingFundsRef = []) {
            if (!existingFundsRef.nodes.map(element => element.__ref).includes(`FundType:${id}`)) {
              const newFundNodeRef = cache.writeFragment({
                data: data.updateFundStatus,
                fragment: gql(`
                  fragment NewFund on FundType {
                    id
                    status
                    updatedAt
                  }
                `),
              });
              const updatedFundsRef = { ...existingFundsRef };
              updatedFundsRef.nodes = [newFundNodeRef, ...existingFundsRef.nodes];
              return updatedFundsRef;
            }
          },
        },
      });
    },
  });

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateFundStatus({
          variables: {
            id,
            status: data.status,
          },
        });
      }}
      title="Status"
    >
      <FormInput
        type="select"
        fieldName="status"
        label="Status"
        defaultValue={status}
        options={[
          { label: 'Active', value: 'ACTIVE', color: 'bg-green-500' },
          {
            label: 'Inactive',
            value: 'INACTIVE',
            color: 'bg-red-500',
          },
          { label: 'Under Review', value: 'UNDER_REVIEW', color: 'bg-yellow-500' },
        ]}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateFundStatus;
