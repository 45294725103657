import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import DealProgressChart from './DealProgressChart';
import DealStatusPanel from './DealStatusPanel';

const DEAL_QUERY = gql(`
  query getDealOverview($id: ID!) {
    deal(id: $id) {
      id
      status
    }
  }
`);

const DealOverview: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const { loading, error, data, refetch } = useQuery(DEAL_QUERY, {
    variables: {
      id: dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const deal = data.deal;

  return (
    <>
      <DealStatusPanel dealId={dealId} status={deal.status} />
      <DealProgressChart dealId={dealId} />
    </>
  );
};

export default DealOverview;
