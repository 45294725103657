import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';

import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';

import { logout } from 'utils/auth';

const SETTINGS_QUERY = gql(`
  query getUserSettings {
    dashboard {
      user {
        id
        name
        phoneNumber
        email
      }
    }
  }
`);

const SettingsPage: FC = () => {
  const { loading, error, data, refetch } = useQuery(SETTINGS_QUERY);

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const user = data.dashboard.user;

    return (
      <>
        <DetailsPanel
          title="User Details"
          data={[
            { label: 'Name', value: user.name },
            { label: 'Phone Number', value: user.phoneNumber },
            { label: 'Email', value: user.email },
          ]}
        />
        <Button onClick={logout}>Logout</Button>
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Settings"
        subTitle="Manage your account, security and notification preferences"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default SettingsPage;
