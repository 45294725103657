import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';
import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

const DEAL_DETAILS_QUERY = gql(`
  query getDealDetailsForSchemeInformation($id: ID!) {
    deal(id: $id) {
      id
      schemeName
      companyInformation {
        company {
          name
          description
        }
        industries
      }
      syndicate {
        syndicateLeads {
          user {
            name
          }
        }
      }
      dealTerms {
        financialInstrument
      }
      externalInvestors {
        name
        amount
      }
    }
  }
`);

const GENERATE_SCHEME_INFORMATION_DOCUMENT_MUTATION = gql(`
  mutation GenerateSchemeInformationDocument($dealId: ID!, $schemeName: String!, $companyName: String!, $companyDetails: String!, $companyIndustryDetails: String!, $investmentHighlights: String!, $securitiesType: String!, $numberOfSecurities: String!, $pricePerShareUnit: String!, $convertibleConversionPrice: String!, $coInvestmentDetails: String!, $valuationDetails: String!) {
    generateSchemeInformationDocument(dealId: $dealId, schemeName: $schemeName, companyName: $companyName, companyDetails: $companyDetails, companyIndustryDetails: $companyIndustryDetails, investmentHighlights: $investmentHighlights, securitiesType: $securitiesType, numberOfSecurities: $numberOfSecurities, pricePerShareUnit: $pricePerShareUnit, convertibleConversionPrice: $convertibleConversionPrice, coInvestmentDetails: $coInvestmentDetails, valuationDetails: $valuationDetails)
  }
`);

const GenerateSchemeInformationDocumentDialogButton: FC<{ dealId: string }> = ({ dealId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { loading, error, data, refetch } = useQuery(DEAL_DETAILS_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const [generateSchemeInformationDocument, { loading: mutationLoading, error: mutationError }] =
    useMutation(GENERATE_SCHEME_INFORMATION_DOCUMENT_MUTATION);

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const deal = data.deal;

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Generate Scheme Info Document</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Generate Scheme Info Document</DialogTitle>
          <FormPanel
            loading={loading || mutationLoading}
            error={error || mutationError}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              generateSchemeInformationDocument({
                variables: {
                  dealId,
                  schemeName: data.schemeName,
                  companyName: data.companyName,
                  companyDetails: data.companyDetails,
                  companyIndustryDetails: data.companyIndustryDetails,
                  investmentHighlights: data.investmentHighlights,
                  securitiesType: data.securitiesType,
                  numberOfSecurities: data.numberOfSecurities,
                  pricePerShareUnit: data.pricePerShareUnit,
                  convertibleConversionPrice: data.convertibleConversionPrice,
                  coInvestmentDetails: data.coInvestmentDetails,
                  valuationDetails: data.valuationDetails,
                },
              }).then(() => toggleDialog(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput
              type="text"
              fieldName="schemeName"
              label="Scheme Name"
              defaultValue={deal.schemeName}
            />
            <FormInput
              type="text"
              fieldName="companyName"
              label="Company Name"
              defaultValue={deal.companyInformation.company.name}
            />
            <FormInput
              type="text"
              fieldName="companyDetails"
              label="Company Details"
              defaultValue={deal.companyInformation.company.description}
            />
            <FormInput
              type="text"
              fieldName="companyIndustryDetails"
              label="Company Industry Details"
              defaultValue={deal.companyInformation.industries?.join(', ')}
            />
            <FormInput
              type="text"
              fieldName="investmentHighlights"
              label="Investment Highlights"
              defaultValue={deal.syndicate.syndicateLeads.map(lead => lead.user.name).join(', ')}
            />
            <FormInput
              type="text"
              fieldName="securitiesType"
              label="Securities Type"
              defaultValue={deal.dealTerms?.financialInstrument}
            />
            <FormInput
              type="text"
              fieldName="numberOfSecurities"
              label="Number of Securities"
              defaultValue=""
            />
            <FormInput
              type="text"
              fieldName="pricePerShareUnit"
              label="Price Per Share Unit"
              defaultValue=""
            />
            <FormInput
              type="text"
              fieldName="convertibleConversionPrice"
              label="Convertible Conversion Price"
              defaultValue=""
            />
            <FormInput
              type="text"
              fieldName="coInvestmentDetails"
              label="Co-Investment Details"
              defaultValue={deal.externalInvestors.map(investor => investor.name).join(', ')}
            />
            <FormInput
              type="text"
              fieldName="valuationDetails"
              label="Valuation Details"
              defaultValue=""
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
              loading={loading}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default GenerateSchemeInformationDocumentDialogButton;
