import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

import DealFileTypeOptions from './constants';

const UPLOAD_DEAL_DOCUMENT_MUTATION = gql(`
  mutation UploadDealDocuments($dealId: ID!, $files: [Upload!], $type: DealDocumentTypeEnumType, $name: String, $description: String) {
    uploadDealDocuments(dealId: $dealId, files: $files, type: $type, name: $name, description: $description) {
      id
      type
      name
      description
    }
  }
`);

const UploadDocumentDialogButton: FC<{ dealId: string }> = ({ dealId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [uploadDocuments, { loading, error }] = useMutation(UPLOAD_DEAL_DOCUMENT_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: `DealType:${dealId}`,
        fields: {
          dealDocuments(existingDocumentsRef = []) {
            let result = existingDocumentsRef;

            data.uploadDealDocuments?.forEach(dealDoc => {
              if (!dealDoc) return;

              const newDocumentRef = cache.writeFragment({
                data: dealDoc,
                fragment: gql(`
                  fragment NewDealDocument on DealDocumentType {
                    id
                    type
                    name
                    description
                  }
                `),
              });

              result = [...result, newDocumentRef];
            });

            return result;
          },
        },
      });
    },
    refetchQueries: ['fetchDealDocuments'],
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Upload Document</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Upload a document</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              uploadDocuments({
                variables: {
                  dealId,
                  files: data.documents,
                  type: data.type ? data.type : undefined,
                  name: data.name,
                  description: data.description,
                },
              }).then(() => toggleDialog(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput
              type="select"
              fieldName="type"
              label="Type"
              defaultValue=""
              options={DealFileTypeOptions}
            />
            <FormInput type="text" fieldName="name" label="Name" defaultValue="" />
            <FormInput type="text" fieldName="description" label="Description" defaultValue="" />
            <FormInput type="upload" fieldName="documents" label="Documents" defaultValue="" />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
              loading={loading}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default UploadDocumentDialogButton;
