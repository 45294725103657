import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ShareAssetStatusEnumType } from '__generated__/graphql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const CREATE_SHARE_ASSET_MUTATION = gql(`
  mutation CreateShareAsset(
    $shareClassId: ID!
    $dealId: ID!
    $issueNumberOfShares: Float!
    $numberOfShares: Float!
    $status: ShareAssetStatusEnumType!
  ) {
    createShareAsset(
      shareClassId: $shareClassId
      dealId: $dealId
      issueNumberOfShares: $issueNumberOfShares
      numberOfShares: $numberOfShares
      status: $status
    ) {
      id
      issueNumberOfShares
      numberOfShares
      status
      createdAt
    }
  }
`);

const CreateShareAssetDialogButton: FC<{
  shareClasses: {
    id: string;
    name: string;
  }[];
  dealId: string;
}> = ({ shareClasses, dealId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [createShareAsset, { loading, error }] = useMutation(CREATE_SHARE_ASSET_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          id: dealId,
          __typename: 'DealType',
        }),
        fields: {
          shareAssets(existingShareAssetsRef) {
            const newShareAssetNodeRef = cache.writeFragment({
              data: data.createShareAsset,
              fragment: gql(`
                fragment NewShareAsset on ShareAssetType {
                  id
                  issueNumberOfShares
                  numberOfShares
                  createdAt
                }
              `),
            });

            return [...existingShareAssetsRef, newShareAssetNodeRef];
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Create Share Asset</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Create Share Asset</DialogTitle>
          <DialogSubTitle>Fill in the details below to create a new share class</DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              createShareAsset({
                variables: {
                  dealId,
                  shareClassId: data.shareClassId,
                  issueNumberOfShares: data.issueNumberOfShares,
                  numberOfShares: data.numberOfShares,
                  status: data.status,
                },
              }).then(() => toggleDialog(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput
              type="select"
              fieldName="shareClassId"
              label="Share Class"
              defaultValue=""
              validators={{ required: true }}
              options={shareClasses.map(shareClass => ({
                value: shareClass.id,
                label: shareClass.name,
              }))}
              helperText='To create a new share class, go to the "Company" page'
            />
            <FormInput
              type="number"
              fieldName="issueNumberOfShares"
              label="Issue Number of Shares"
              defaultValue=""
              validators={{ required: true }}
            />
            <FormInput
              type="number"
              fieldName="numberOfShares"
              label="Number of Shares"
              defaultValue=""
              validators={{ required: true }}
            />
            <FormInput
              type="select"
              fieldName="status"
              label="Status"
              defaultValue={ShareAssetStatusEnumType.UnderReview}
              validators={{ required: true }}
              options={[
                { label: 'Active', value: ShareAssetStatusEnumType.Active },
                { label: 'Inactive', value: ShareAssetStatusEnumType.Inactive },
                { label: 'Exited', value: ShareAssetStatusEnumType.Exited },
                { label: 'Under Review', value: ShareAssetStatusEnumType.UnderReview },
              ]}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateShareAssetDialogButton;
