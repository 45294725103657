import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_USER_MUTATION = gql(`
  mutation UpdateUser(
    $id: ID!
    $name: String!
    $email: String!
    $phoneNumber: String
  ) {
    updateUser(
      id: $id
      name: $name
      email: $email
      phoneNumber: $phoneNumber
    ) {
      id
      name
      email
      phoneNumber
      slug
      updatedAt
    }
  }
`);

const UpdateUser: FC<{
  id: string;
  name: string;
  email: string;
  phoneNumber?: string | null;
}> = ({ id, name, email, phoneNumber }) => {
  const [updateUser, { loading, error }] = useMutation(UPDATE_USER_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateUser({
          variables: {
            id,
            name: data.name,
            email: data.email,
            phoneNumber: data.phoneNumber,
          },
        });
      }}
      title="Information"
    >
      <FormInput
        type="text"
        fieldName="name"
        label="Full Name *"
        defaultValue={name}
        validators={{
          required: true,
        }}
      />
      <FormInput
        type="text"
        fieldName="email"
        label="Email"
        defaultValue={email}
        validators={{
          required: true,
          isEmail: true,
        }}
      />
      <FormInput
        type="text"
        fieldName="phoneNumber"
        label="Phone Number"
        defaultValue={phoneNumber}
        validators={{
          isPhoneNumber: true,
        }}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateUser;
