import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_DEAL_MUTATION = gql(`
  mutation FinaliseDeal($id: ID!, $status: DealStatusEnumType!, $finalisedAt: String) {
    finaliseDeal(id: $id, status: $status, finalisedAt: $finalisedAt) {
      id
      status
      updatedAt
      finalisedAt
    }
  }
`);

const UpdateDealStatus: FC<{
  id: string;
  status: DealStatusEnumType;
  finalisedAt?: string | null;
}> = ({ id, status, finalisedAt }) => {
  const [updateDeal, { loading, error }] = useMutation(UPDATE_DEAL_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateDeal({
          variables: {
            id,
            status: data.status,
            finalisedAt: data.finalisedAt,
          },
        });
      }}
    >
      <FormInput
        type="select"
        fieldName="status"
        label="Status"
        defaultValue={status}
        options={[
          { value: 'CLOSED', label: 'Closed', color: 'bg-red-500' },
          { value: 'RAISING', label: 'Raising', color: 'bg-green-500' },
          { value: 'DELETED', label: 'Deleted', color: 'bg-red-500' },
          { value: 'DRAFT', label: 'Draft', color: 'bg-yellow-500' },
          { value: 'UNDER_OPS_REVIEW', label: 'Under Ops Review', color: 'bg-yellow-500' },
          { value: 'POOLING', label: 'Pooling', color: 'bg-yellow-500' },
          { value: 'FUNDED', label: 'Funded', color: 'bg-green-500' },
          {
            value: 'UNDER_LEGAL_REVIEW',
            label: 'Under Legal Review',
            color: 'bg-yellow-500',
          },
          { value: 'WIRED', label: 'Wired', color: 'bg-green-500' },
          { value: 'CANCELLED', label: 'Cancelled', color: 'bg-red-500' },
          { value: 'EXITED', label: 'Exited', color: 'bg-green-500' },
        ]}
      />
      <FormInput
        type="date"
        fieldName="finalisedAt"
        label="Finalisation Date"
        defaultValue={finalisedAt || new Date()}
        conditionsToShow={{
          matches: [
            {
              field: 'status',
              condition: '===',
              value: 'FINALISED',
            },
          ],
        }}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateDealStatus;
