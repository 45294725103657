import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import theme from './theme.module.scss';

const CLOSING_EMAIL_AUDIT_QUERY = gql(`
  query getClosingEmailAudit($id: ID!) {
    closing(id: $id) {
      id
      emailAudit {
        id
        type
        status
        recipient {
          id
          name
        }
        createdAt
      }
    }
  }
`);

const ClosingEmailAudit: FC<{
  closingId: string;
}> = ({ closingId }) => {
  const { loading, error, data, refetch } = useQuery(CLOSING_EMAIL_AUDIT_QUERY, {
    variables: {
      id: closingId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const closing = data.closing;
    const emailAudit = closing.emailAudit;

    if (emailAudit.length === 0) return <p>No email audit found.</p>;

    return (
      <ul>
        {emailAudit.map(audit => (
          <li key={audit.id}>
            <div>
              <strong>
                {audit.status} - {audit.type}
              </strong>
              <p>
                Sent to {audit.recipient.name} on {audit.createdAt}
              </p>
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={theme.container}>
      <h3>Email Audit</h3>
      {renderContent()}
    </div>
  );
};

export default ClosingEmailAudit;
