import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { SyndicateStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import debounce from 'utils/debounce';

const SYNDICATES_QUERY = gql(`
  query fetchSyndicatesForApproval($cursor: ID, $limit: Int, $filters: SyndicatesFilterType) {
    syndicates(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        name
        status
        isPrivate
        createdAt
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`);

const ApproveSyndicatesPage: FC = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    SYNDICATES_QUERY,
    {
      variables: {
        limit: 50,
        filters: {
          status: SyndicateStatusEnumType.UnderReview,
        },
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const syndicates = data.syndicates.nodes;
  const pageInfo = data.syndicates.pageInfo;

  return (
    <>
      <TextField
        placeholder="Search syndicates"
        onChange={debounce(e => {
          refetch({
            filters: {
              ...variables?.filters,
              text: e.target.value,
            },
          });
        }, 500)}
      />
      <DataTable
        data={syndicates}
        onClick={syndicate => navigate(`/syndicates/${syndicate.id}`)}
        columns={[
          {
            label: 'Name',
            fieldName: 'name',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Is Private?',
            fieldName: 'isPrivate',
            type: 'BOOLEAN',
          },
          {
            label: 'Created At',
            fieldName: 'createdAt',
            type: 'DATE',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        filterLoading={networkStatus === NetworkStatus.setVariables}
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default ApproveSyndicatesPage;
