import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import AuditsPreview from 'components/Audit/AuditsPreview';
import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';

import DeleteInvestmentEntityButton from './DeleteInvestmentEntityButton';
import UpdateInvestmentEntity from './UpdateInvestmentEntity';
import UpdateInvestmentEntityStatus from './UpdateInvestmentEntityStatus';

const INVESTMENT_ENTITY_QUERY = gql(`
  query getInvestmentEntityInformation($id: ID!) {
    investmentEntity(id: $id) {
      id
      oldPlatformId
      name
      type
      status
      createdAt
      updatedAt
      user {
        id
        name
        email
        phoneNumber
        linkedInProfileUrl
      }
      statistics {
        totalCommittedAmount
        totalInvestedAmount
        totalRealizedValue
        totalUnrealizedValue
        numberOfDeals
        numberOfExits
        numberOfCommitments
      }
    }
  }
`);

const InvestmentEntityInformation: FC = () => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch } = useQuery(INVESTMENT_ENTITY_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const investmentEntity = data.investmentEntity;

  return (
    <>
      <DetailsPanel
        data={[
          {
            label: 'Old Platform AL Entity ID',
            value: investmentEntity.oldPlatformId,
          },
        ]}
      />
      <UpdateInvestmentEntity
        id={investmentEntity.id}
        name={investmentEntity.name}
        type={investmentEntity.type}
      />
      <DetailsPanel
        title="User"
        data={[
          { label: 'Name', value: investmentEntity.user.name },
          { label: 'Email', value: investmentEntity.user.email },
          { label: 'Phone Number', value: investmentEntity.user.phoneNumber },
          { label: 'LinkedIn Profile Url', value: investmentEntity.user.linkedInProfileUrl },
        ]}
      />
      <DetailsPanel
        data={[
          {
            label: 'Total Amount Committed',
            value: investmentEntity.statistics.totalCommittedAmount,
            type: 'CURRENCY',
          },
          {
            label: 'Total Amount Invested',
            value: investmentEntity.statistics.totalInvestedAmount,
            type: 'CURRENCY',
          },
          {
            label: 'Total Realized Value',
            value: investmentEntity.statistics.totalRealizedValue,
            type: 'CURRENCY',
          },
          {
            label: 'Total Unrealized Value',
            value: investmentEntity.statistics.totalUnrealizedValue,
            type: 'CURRENCY',
          },
          { label: 'Number Of Deals', value: investmentEntity.statistics.numberOfDeals },
          { label: 'Number Of Exits', value: investmentEntity.statistics.numberOfExits },
          {
            label: 'Number Of Commitments',
            value: investmentEntity.statistics.numberOfCommitments,
          },
        ]}
      />
      <UpdateInvestmentEntityStatus id={investmentEntity.id} status={investmentEntity.status} />
      <AuditsPreview id={investmentEntity.id} type="INVESTMENT_ENTITY" />
      <DeleteInvestmentEntityButton id={investmentEntity.id} />
    </>
  );
};

export default InvestmentEntityInformation;
