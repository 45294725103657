import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_CONVERTIBLE_CLASS_MUTATION = gql(`
  mutation RemoveConvertibleClass($id: ID!) {
    removeConvertibleClass(id: $id) {
      id
      company {
        id
      }
    }
  }
`);

const RemoveConvertibleClassButton: FC<{ id: string; onRemove: () => void }> = ({
  id,
  onRemove,
}) => {
  const [removeConvertibleClass, { loading, error }] = useMutation(
    REMOVE_CONVERTIBLE_CLASS_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            id: data.removeConvertibleClass.company.id,
            __typename: 'CompanyType',
          }),
          fields: {
            convertibleClasses(existingConvertibleClassesRef, { readField }) {
              return existingConvertibleClassesRef.filter(
                convertibleClassRef => id !== readField('id', convertibleClassRef)
              );
            },
          },
        });
      },
      onCompleted() {
        onRemove();
      },
    }
  );

  return (
    <ConfirmButton
      onConfirm={() => removeConvertibleClass({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will delete all data concerning this Convertible Class."
    >
      Remove Convertible Class
    </ConfirmButton>
  );
};

export default RemoveConvertibleClassButton;
