import { gql } from '__generated__';

const getFieldNameForFund = (field: string) => {
  const fieldToReadableNameMap = {
    name: 'Name',
    schemeName: 'Scheme Name',
    description: 'Description',
    image: 'Image',
    status: 'Status',
    fundInvestmentUserId: 'Fund Investment User ID',
    closingDate: 'Closing Date',
    oldPlatformId: 'Old Platform ID',
    oldPlatformSyndicateId: 'Old Platform Syndicate ID',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
  };

  return fieldToReadableNameMap[field] || field;
};

const FUND_AUDIT_QUERY = gql(`
    query FundAudit($id: ID!, $page: Int, $limit: Int) {
      fund(id: $id) {
        audits(page: $page, limit: $limit) {
          nodes {
            operation
            changes {
              fieldName
              oldValue
              newValue
            }
            updatedAt
            updatedBy {
              name
            }
          }
          pageInfo {
            totalCount
            hasNextPage
          }
        }
      }
    }
  `);

export { getFieldNameForFund, FUND_AUDIT_QUERY };
