import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { InvestmentEntityStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import debounce from 'utils/debounce';

const INVESTMENT_ENTITIES_QUERY = gql(`
  query fetchApprovals(
    $cursor: ID
    $limit: Int
    $filters: InvestmentEntitiesFilterType
  ) {
    investmentEntities(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        name
        user {
          id
          name
        }
        status
        type
        createdAt
        statistics {
          totalCommittedAmount
          numberOfCommitments
        }
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`);

const ApproveInvestmentEntitiesPage: FC = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    INVESTMENT_ENTITIES_QUERY,
    {
      variables: {
        limit: 50,
        filters: {
          status: InvestmentEntityStatusEnumType.UnderReview,
        },
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const investmentEntities = data.investmentEntities.nodes;
  const pageInfo = data.investmentEntities.pageInfo;

  return (
    <>
      <TextField
        placeholder="Search entities"
        onChange={debounce(e => {
          refetch({
            filters: {
              ...variables?.filters,
              text: e.target.value,
            },
          });
        }, 500)}
      />
      <DataTable
        data={investmentEntities}
        onClick={investmentEntity => navigate(`/investment-entities/${investmentEntity.id}`)}
        columns={[
          {
            label: 'Name',
            fieldName: 'name',
          },
          {
            label: 'User',
            fieldName: 'user.name',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Type',
            fieldName: 'type',
            type: 'INVESTMENT_ENTITY_TYPE',
          },
          {
            label: 'Created At',
            fieldName: 'createdAt',
            type: 'DATE',
          },
          {
            label: 'Total Amount Committed',
            fieldName: 'statistics.totalCommittedAmount',
            type: 'NUMBER',
          },
          {
            label: 'Number of Commitments',
            fieldName: 'statistics.numberOfCommitments',
            type: 'NUMBER',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        filterLoading={networkStatus === NetworkStatus.setVariables}
        paginationLoading={loading}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default ApproveInvestmentEntitiesPage;
