import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';
import LoadingIndicator from 'primitives/LoadingIndicator';

import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import RemoveLeadFromSyndicateButton from './RemoveLeadFromSyndicateButton';

const SYNDICATE_USER_QUERY = gql(`
  query getSyndicateLead($id: ID!) {
    syndicateLead(id: $id) {
      id
      role
      user {
        id
        name
        email
      }
      createdAt
    }
  }
`);

const UPDATE_SYNDICATE_LEAD_ROLE_MUTATION = gql(`
  mutation updateSyndicateLeadRole($id: ID!, $role: SyndicateLeadRoleEnumType!) {
    updateSyndicateLeadRole(id: $id, role: $role) {
      id
      role
    }
  }
`);

const SyndicateLeadDialog: FC<{
  syndicateLeadId: string;
  onClose: () => void;
}> = ({ syndicateLeadId, onClose }) => {
  const { loading, error, data, refetch } = useQuery(SYNDICATE_USER_QUERY, {
    variables: {
      id: syndicateLeadId,
    },
  });

  const [updateSyndicateLeadRole, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_SYNDICATE_LEAD_ROLE_MUTATION
  );

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const syndicateLead = data.syndicateLead;

    return (
      <>
        <div>
          <RemoveLeadFromSyndicateButton syndicateLeadId={syndicateLeadId} onClose={onClose} />
        </div>
        <DetailsPanel
          title="Lead Details"
          data={[
            {
              label: 'Name',
              value: syndicateLead.user.name,
            },
            {
              label: 'Email',
              value: syndicateLead.user.email,
            },
            {
              label: 'Joined At',
              value: syndicateLead.createdAt,
              type: 'DATETIME',
            },
          ]}
        />
        <FormPanelWithReadMode
          loading={updateLoading}
          error={updateError}
          title="Update Role"
          onSubmit={values =>
            updateSyndicateLeadRole({ variables: { id: syndicateLeadId, role: values.role } })
          }
        >
          <FormInput
            type="select"
            fieldName="role"
            label="Role"
            defaultValue={'ADMIN'}
            options={[
              { label: 'Admin', value: 'ADMIN' },
              { label: 'Owner', value: 'OWNER' },
            ]}
          />
        </FormPanelWithReadMode>
      </>
    );
  }

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogPanel>
        <DialogTitle>Syndicate Lead</DialogTitle>
        {renderContent()}
      </DialogPanel>
    </Dialog>
  );
};

export default SyndicateLeadDialog;
