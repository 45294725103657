import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ShareAssetStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_SHARE_ASSET_MUTATION = gql(`
  mutation UpdateShareAsset(
    $id: ID!
    $issueNumberOfShares: Float!
    $numberOfShares: Float!
    $status: ShareAssetStatusEnumType!
  ) {
    updateShareAsset(
      id: $id
      issueNumberOfShares: $issueNumberOfShares
      numberOfShares: $numberOfShares
      status: $status
    ) {
      id
      issueNumberOfShares
      numberOfShares
      status
      updatedAt
    }
  }
`);

const UpdateShareAsset: FC<{
  id: string;
  issueNumberOfShares: number;
  numberOfShares: number;
  status: ShareAssetStatusEnumType;
}> = ({ id, issueNumberOfShares, numberOfShares, status }) => {
  const [updateShareAsset, { loading, error }] = useMutation(UPDATE_SHARE_ASSET_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data =>
        updateShareAsset({
          variables: {
            id,
            issueNumberOfShares: data.issueNumberOfShares,
            numberOfShares: data.numberOfShares,
            status: data.status,
          },
        })
      }
    >
      <FormInput
        type="number"
        fieldName="issueNumberOfShares"
        label="Issue Number of Shares"
        defaultValue={issueNumberOfShares}
        validators={{ required: true }}
      />
      <FormInput
        type="number"
        fieldName="numberOfShares"
        label="Number of Shares"
        defaultValue={numberOfShares}
        validators={{ required: true }}
      />
      <FormInput
        type="select"
        fieldName="status"
        label="Status"
        defaultValue={status}
        validators={{ required: true }}
        options={[
          { value: ShareAssetStatusEnumType.Active, label: 'Active' },
          { value: ShareAssetStatusEnumType.Inactive, label: 'Inactive' },
          { value: ShareAssetStatusEnumType.Exited, label: 'Exited' },
          { value: ShareAssetStatusEnumType.UnderReview, label: 'Under Review' },
        ]}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateShareAsset;
