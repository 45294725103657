import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

const UPDATE_POST_MUTATION = gql(`
  mutation UpdatePost($id: ID!, $title: String!, $message: String!) {
    updatePost(id: $id, title: $title, message: $message) {
      id
      title
      message
      status
      createdAt
      updatedAt
    }
  }
`);

const UpdatePost: FC<{
  id: string;
  title: string;
  message: string;
}> = ({ id, title, message }) => {
  const [updatePost, { loading, error }] = useMutation(UPDATE_POST_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updatePost({
          variables: {
            id,
            title: data.title,
            message: data.message,
          },
        });
      }}
    >
      <FormInput type="textarea" fieldName="title" label="Title" defaultValue={title} />
      <FormInput
        type="custom"
        fieldName="message"
        label="Message"
        defaultValue={message}
        customInput={EditorFormInput}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdatePost;
