import { useLazyQuery } from '@apollo/client';
import { ClickAwayListener, Popper, TextField } from '@mui/material';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import debounce from 'utils/debounce';

import theme from './theme.module.scss';

type User = {
  id: string;
  name: string;
  email: string;
};

const SEARCH_QUERY = gql(`
  query SearchUsersForSyndicate($text: String!, $limit: Int) {
    search(text: $text, limit: $limit) {
      users {
        nodes {
          id
          name
          email
        }
      }
    }
  }
`);

const SearchUsers: FC<{
  onChange: (user: User) => void;
  value?: User;
  label?: string;
}> = ({ value, onChange, label = '' }) => {
  const [currentValue, setCurrentValue] = useState<User | null>(value || null);
  const [getSearchResults, { loading, error, data, refetch, variables }] =
    useLazyQuery(SEARCH_QUERY);

  const ref = useRef<HTMLDivElement | null>(null);

  const performSearch = searchTerm =>
    getSearchResults({
      variables: {
        text: searchTerm.trim(),
        limit: 10,
      },
    });

  function results() {
    if (loading) return <LoadingIndicator />;

    if (error) return <ErrorMessage error={error} refetch={refetch} />;

    if (!data || !data.search || variables?.text === '') return null;

    const users = data.search.users.nodes;

    if (users.length === 0) return <p>No results found</p>;

    return (
      <>
        {users.map(user => (
          <div
            key={user.id}
            className={theme.user}
            onClick={() => {
              setCurrentValue(user);
              onChange(user);
            }}
          >
            <span className={theme.name}>{user.name}</span>
            <span className={theme.email}>{user.email}</span>
          </div>
        ))}
      </>
    );
  }

  if (currentValue) {
    return (
      <>
        <p>{currentValue.name}</p>
        <p>{currentValue.email}</p>
        <Button onClick={() => setCurrentValue(null)}>Clear</Button>
      </>
    );
  }

  return (
    <ClickAwayListener onClickAway={() => performSearch('')}>
      <div className={theme.container}>
        <TextField
          ref={ref}
          className={theme.input}
          size="small"
          label={label}
          onBlur={e => (e.target.value = '')}
          onChange={debounce(e => performSearch(e.target.value), 500)}
          placeholder="Find by name"
        />
        <Popper
          open={true}
          style={{ width: ref.current?.offsetWidth, zIndex: 10000 }}
          anchorEl={ref.current}
          placement="bottom-start"
          onClick={e => e.preventDefault()}
        >
          <div className={theme.resultsContainer}>{results()}</div>
        </Popper>
      </div>
    </ClickAwayListener>
  );
};

export default SearchUsers;
