import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';
import Statistics from 'components/Statistics';

const DASHBOARD_QUERY = gql(`
  query DashboardQuery {
    adminDashboard {
      user {
        name
      }
      statistics {
        numberOfCompanies
        numberOfSyndicatedDeals
        numberOfRUVDeals
        numberOfSyndicates
        numberOfInvestmentEntities
        numberOfUsers
        numberOfClosings
        numberOfExits
      }
    }
  }
`);

const Dashboard: FC = () => {
  const { loading, error, data, refetch } = useQuery(DASHBOARD_QUERY);

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const statistics = data.adminDashboard.statistics;

    return (
      <>
        {/* <Banner
          title={`Welcome back, ${data.adminDashboard.user.name}!`}
          description="This page will be a place for you to quickly see the high priority items that need your attention."
          listItems={[
            'View investor applications that need to be reviewed',
            'View deals that need to be attended to',
          ]}
        /> */}
        <Statistics
          statistics={[
            {
              label: 'Users',
              value: statistics.numberOfUsers,
              type: 'NUMBER',
            },
            {
              label: 'Investment Entities',
              value: statistics.numberOfInvestmentEntities,
              type: 'NUMBER',
            },
            {
              label: 'Syndicates',
              value: statistics.numberOfSyndicates,
              type: 'NUMBER',
            },
            {
              label: 'Companies',
              value: statistics.numberOfCompanies,
              type: 'NUMBER',
            },
            {
              label: 'Syndicated Deals',
              value: statistics.numberOfSyndicatedDeals,
              type: 'NUMBER',
            },
            {
              label: 'RUV Deals',
              value: statistics.numberOfRUVDeals,
              type: 'NUMBER',
            },
            {
              label: 'Investments Made',
              value: statistics.numberOfClosings,
              type: 'NUMBER',
            },
            {
              label: 'Deal Exits',
              value: statistics.numberOfExits,
              type: 'NUMBER',
            },
          ]}
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Dashboard"
        subTitle="Quick overview of things that need your attention"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default Dashboard;
