import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import ErrorMessage from 'components/ErrorMessage';

import RemoveConvertibleValuationChangeButton from './RemoveConvertibleValuationChangeButton';
import UpdateConvertibleValuationChange from './UpdateConvertibleValuationChange';

const CONVERTIBLE_CLASS_QUERY = gql(`
  query getConvertibleValuationChange($id: ID!, $companyId: ID!) {
    convertibleValuationChange(id: $id) {
      id
      valuationMultiplier
      effectiveFrom
      convertibleClasses {
        id
        name
      }
      createdAt
      updatedAt
    }
    company(id: $companyId) {
      id
      convertibleClasses {
        id
        name
      }
    }
  }
`);

const ConvertibleValuationChangeSlideOver: FC<{
  open: boolean;
  convertibleValuationChangeId: string;
  onClose: () => void;
}> = ({ open = false, convertibleValuationChangeId, onClose }) => {
  const { companyId } = useParams<{ companyId: string }>() as { companyId: string };
  const { loading, error, data, refetch } = useQuery(CONVERTIBLE_CLASS_QUERY, {
    variables: {
      id: convertibleValuationChangeId,
      companyId,
    },
    skip: !open,
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const convertibleValuationChange = data.convertibleValuationChange;

    return (
      <>
        <UpdateConvertibleValuationChange
          id={convertibleValuationChange.id}
          valuationMultiplier={convertibleValuationChange.valuationMultiplier}
          effectiveFrom={convertibleValuationChange.effectiveFrom}
          convertibleClasses={convertibleValuationChange.convertibleClasses}
          allConvertibleClasses={data.company.convertibleClasses}
        />
        <RemoveConvertibleValuationChangeButton
          id={convertibleValuationChange.id}
          onRemove={onClose}
        />
      </>
    );
  };

  return (
    <SlideOver
      open={open}
      onClose={onClose}
      title="Convertible Valuation Change"
      subTitle="Manage the convertible valuation change and the classes it affects"
    >
      {renderContent()}
    </SlideOver>
  );
};

export default ConvertibleValuationChangeSlideOver;
