import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

const CREATE_POST_MUTATION = gql(`
  mutation CreatePost($title: String!, $message: String!, $syndicateId: ID!) {
    createPost(title: $title, message: $message, syndicateId: $syndicateId) {
      id
      title
      message
      status
      createdAt
      updatedAt
    }
  }
`);

const CreatePostDialogButton: FC = () => {
  const [showDialog, toggleDialog] = useState(false);

  const [createPost, { loading, error }] = useMutation(CREATE_POST_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        fields: {
          posts(existingPostsRef) {
            const newPostNodeRef = cache.writeFragment({
              data: data.createPost,
              fragment: gql(`
                fragment NewPost on PostType {
                  id
                  title
                  message
                  status
                  createdAt
                  updatedAt
                }
              `),
            });
            const updatedPostsRef = { ...existingPostsRef };
            updatedPostsRef.nodes = [newPostNodeRef, ...updatedPostsRef.nodes];
            return updatedPostsRef;
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Create Post</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Create a Post</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              createPost({
                variables: {
                  title: data.title,
                  message: data.message,
                  syndicateId: 'TODO',
                },
              }).then(() => toggleDialog(false));
            }}
          >
            <FormInput type="text" fieldName="title" label="Title" defaultValue="" />
            <FormInput
              type="custom"
              fieldName="message"
              label="Message"
              defaultValue=""
              customInput={EditorFormInput}
            />
          </FormPanel>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreatePostDialogButton;
