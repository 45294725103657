const InvestmentEntityFileTypeOptions = [
  {
    label: 'Fully Executed and Stamped Transactional Documents',
    value: 'FULLY_EXECUTED_STAMPED_TRANSACTIONAL_DOCUMENTS',
  },
  { label: 'Copy of MGT-14', value: 'COPY_OF_MGT_14' },
  { label: 'Executed Copy of PAS-5', value: 'EXECUTED_COPY_OF_PAS_5' },
  {
    label: 'Executed Version of CP Completion Letter',
    value: 'EXECUTED_VERSION_OF_CP_COMPLETION_LETTER',
  },
  {
    label: 'CTC Board Resolution for Authorization',
    value: 'CTC_BOARD_RESOLUTION_FOR_AUTHORIZATION',
  },
  {
    label: 'Board and EGM Resolution for Issue of Shares',
    value: 'BOARD_AND_EGM_RESOLUTION_FOR_ISSUE_OF_SHARES',
  },
  {
    label: 'Valuation Report under Companies Act, 2013 and FEMA',
    value: 'VALUATION_REPORT_COMPANIES_ACT_2013_AND_FEMA',
  },
  { label: 'Executed Declarations', value: 'EXECUTED_DECLARATIONS' },
  {
    label: 'Executed PAS-4 and Word Version of Part B',
    value: 'EXECUTED_PAS_4_AND_WORD_VERSION_OF_PART_B',
  },
  { label: 'Date of Allotment of CCDs', value: 'DATE_ALLOTMENT_CCDs' },
  {
    label: 'Paid-up Capital of the Investee Company on a Fully Diluted Basis',
    value: 'PAID_UP_CAPITAL_FULLY_DILUTED',
  },
  {
    label: 'Board Resolution for Approval of the Allotment of Securities by the Investee Entity',
    value: 'BOARD_RES_APPROVAL_ALLOT_SECS',
  },
  { label: 'Stamped CCD Certificate(s)', value: 'STAMPED_CCD_CERTIFICATES' },
  { label: 'NIC Code of the Company', value: 'NIC_CODE_COMPANY' },
  {
    label:
      'Executed copy of the declaration by the investee company that the conversion price shall not be less than the fair value determined at the time of issuance of such subscription securities in accordance with FEMA',
    value: 'EXEC_DECLARATION_CONVERSION_PRICE',
  },
  {
    label:
      'Executed copy of the declaration by the investee company on the conversion ratio for the preference shares',
    value: 'EXEC_DECLARATION_CONVERSION_RATIO',
  },
  {
    label: 'Executed copy of the attached Authorized Representative declaration',
    value: 'EXEC_ATTACHED_AUTH_REP_DECLARATION',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export default InvestmentEntityFileTypeOptions;
