import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import ErrorMessage from 'components/ErrorMessage';

import DeleteDealInviteButton from './DeleteDealInviteButton';
import UpdateDealInvite from './UpdateDealInvite';

const DEAL_INVITE_QUERY = gql(`
  query getDealInvite($id: ID!) {
    dealInvite(id: $id) {
      id
      user {
        id
        name
      }
      note
      minimumInvestmentAmount
      maximumInvestmentAmount
      status
      createdAt
      updatedAt
    }
  }
`);

const DealInviteSlideOver: FC<{
  open: boolean;
  dealInviteId: string;
  onClose: () => void;
}> = ({ open = false, dealInviteId, onClose }) => {
  const { loading, error, data, refetch } = useQuery(DEAL_INVITE_QUERY, {
    variables: {
      id: dealInviteId,
    },
    skip: !open,
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const dealInvite = data.dealInvite;

    return (
      <>
        <UpdateDealInvite
          id={dealInvite.id}
          note={dealInvite.note}
          minimumInvestmentAmount={dealInvite.minimumInvestmentAmount}
          maximumInvestmentAmount={dealInvite.maximumInvestmentAmount}
          status={dealInvite.status}
        />
        <DeleteDealInviteButton id={dealInvite.id} />
      </>
    );
  };

  return (
    <SlideOver
      open={open}
      onClose={onClose}
      title="DealInvite"
      subTitle="Manage and audit dealInvite details"
    >
      {renderContent()}
    </SlideOver>
  );
};

export default DealInviteSlideOver;
