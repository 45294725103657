import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_SYNDICATE_MUTATION = gql(`
  mutation RemoveInvestmentEntity($id: ID!) {
    removeInvestmentEntity(id: $id) {
      id
    }
  }
`);

const RemoveInvestmentEntityButton: FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();

  const [removeInvestmentEntity, { loading, error }] = useMutation(REMOVE_SYNDICATE_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        fields: {
          investmentEntities(existingInvestmentEntitiesRef, { readField }) {
            const updatedInvestmentEntitiesRef = { ...existingInvestmentEntitiesRef };
            updatedInvestmentEntitiesRef.nodes = existingInvestmentEntitiesRef.nodes.filter(
              investmentEntityRef => id !== readField('id', investmentEntityRef)
            );
            return updatedInvestmentEntitiesRef;
          },
        },
      });
    },
    onCompleted() {
      navigate('/investment-Entities');
    },
  });

  return (
    <ConfirmButton
      onConfirm={() => removeInvestmentEntity({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will delete all data concerning this InvestmentEntity including any deals."
    >
      Remove
    </ConfirmButton>
  );
};

export default RemoveInvestmentEntityButton;
