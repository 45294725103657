import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const CREATE_COMPANY_EVENT_MUTATION = gql(`
  mutation CreateCompanyEvent(
    $companyId: ID!
    $label: String!
    $type: CompanyEventEnumType!
    $effectiveFrom: String!
  ) {
    createCompanyEvent(
      companyId: $companyId
      type: $type
      label: $label
      effectiveFrom: $effectiveFrom
    ) {
      id
      label
      type
      effectiveFrom
    }
  }
`);

const CreateCompanyEventDialogButton: FC<{ companyId: string }> = ({ companyId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [createCompanyEvent, { loading, error }] = useMutation(CREATE_COMPANY_EVENT_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        fields: {
          events(existingCompanyEvents) {
            const newCompanyEventRef = cache.writeFragment({
              data: data.createCompanyEvent,
              fragment: gql(`
                fragment NewCompanyEvent on CompanyEventType {
                  id
                  label
                  type
                  effectiveFrom
                }
              `),
            });
            const updatedCompanyEvents = { ...existingCompanyEvents };
            updatedCompanyEvents.nodes = [newCompanyEventRef, ...updatedCompanyEvents.nodes];
            return updatedCompanyEvents;
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Create Event</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Create an Event</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              createCompanyEvent({
                variables: {
                  companyId,
                  type: data.type,
                  effectiveFrom: data.effectiveFrom,
                  label: data.label,
                },
              }).then(() => toggleDialog(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput
              type="select"
              fieldName="type"
              label="Type"
              defaultValue={'OTHERS'}
              options={[
                { value: 'CORPORATE_ACTION', label: 'Corporate Action' },
                { value: 'VALUATION_UPDATE', label: 'Valuation Update' },
              ]}
            />
            <FormInput type="text" fieldName="label" label="Label" defaultValue="" />
            <FormInput
              type="date"
              fieldName="effectiveFrom"
              label="Effective From Date"
              minDate={dayjs()}
              defaultValue=""
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateCompanyEventDialogButton;
