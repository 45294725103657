import { FC } from 'react';

import Editor from '.';

const EditorFormInput: FC<{
  onChange: (data: any) => void;
  value?: string;
  readOnly?: boolean;
  label: string;
}> = ({ onChange, value, readOnly, label }) => {
  if (readOnly) {
    return (
      <>
        <span className="block text-sm font-medium leading-6 text-gray-900">{label}</span>
        <div
          className="prose prose-md text-sm mt-2 leading-3 p-4 border border-gray-100 rounded-lg"
          dangerouslySetInnerHTML={{ __html: value || '' }}
        />
      </>
    );
  }

  return (
    <>
      <span className="block text-sm font-medium leading-6 text-gray-900">{label}</span>
      <Editor
        onChange={data => {
          if (onChange) onChange(data);
        }}
        prevData={value}
        readOnly={readOnly}
      />
    </>
  );
};

export default EditorFormInput;
