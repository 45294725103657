import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import AuditsPreview from 'components/Audit/AuditsPreview';
import ErrorMessage from 'components/ErrorMessage';

import RemoveFundButton from './RemoveFund';
import UpdateFund from './UpdateFund';
import UpdateFundStatus from './UpdateFundStatus';

const FUND_QUERY = gql(`
  query GetFundInformation($id: ID!) {
    fund(id: $id) {
      id
      name
      description
      image
      status
      closingDate
      createdAt
      updatedAt
    }
  }
`);

const FundInformation: FC = () => {
  const { fundId } = useParams<{ fundId: string }>() as { fundId: string };

  const { loading, error, data, refetch } = useQuery(FUND_QUERY, {
    variables: {
      id: fundId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const fund = data.fund;

  return (
    <>
      <UpdateFundStatus id={fund.id} status={fund.status} />
      <UpdateFund
        id={fund.id}
        name={fund.name}
        description={fund.description}
        image={fund.image}
        closingDate={fund.closingDate}
      />
      <AuditsPreview id={fund.id} type="FUND" />
      <RemoveFundButton id={fund.id} />
    </>
  );
};

export default FundInformation;
