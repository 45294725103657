import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import CreateInvestmentEntityDialogButton from './CreateInvestmentEntityDialogButton';

const USER_QUERY = gql(`
  query getUserInvestmentEntities($userId: ID!) {
    user(id: $userId) {
      id
      investmentEntities {
        id
        name
        status
        type
        createdAt
        statistics {
          totalCommittedAmount
          totalInvestedAmount
        }
      }
    }
  }
`);

const UserInvestmentEntities: FC = () => {
  const navigate = useNavigate();
  const { userId } = useParams<{ userId: string }>() as { userId: string };

  const { loading, error, data, refetch } = useQuery(USER_QUERY, {
    variables: {
      userId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const user = data.user;

  const investmentEntities = user.investmentEntities;

  return (
    <>
      <div>
        <CreateInvestmentEntityDialogButton userId={user.id} />
      </div>
      <DataTable
        data={investmentEntities}
        onClick={investmentEntity => navigate(`/investment-entities/${investmentEntity.id}`)}
        columns={[
          {
            label: 'Name',
            fieldName: 'name',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Type',
            fieldName: 'type',
            type: 'INVESTMENT_ENTITY_TYPE',
          },
          {
            label: 'Created At',
            fieldName: 'createdAt',
            type: 'DATE',
          },
          {
            label: 'Total Amount Committed',
            fieldName: 'statistics.totalCommittedAmount',
            type: 'CURRENCY',
          },
          {
            label: 'Total Amount Invested',
            fieldName: 'statistics.totalInvestedAmount',
            type: 'CURRENCY',
          },
        ]}
      />
    </>
  );
};

export default UserInvestmentEntities;
