import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import theme from './theme.module.scss';

const CLOSING_AUDIT_QUERY = gql(`
  query getClosingAudit($id: ID!) {
    closing(id: $id) {
      id
      audit {
        id
        amount
        fundingSource
        investmentEntity {
          id
          name
        }
        status
        wiredAt
        executedAt
        finalisedAt
        createdAt
        updatedAt
        updatedBy {
          id
          name
        }
        auditCreatedAt
        auditUpdatedAt
      }
    }
  }
`);

const ClosingAudit: FC<{
  closingId: string;
}> = ({ closingId }) => {
  const { loading, error, data, refetch } = useQuery(CLOSING_AUDIT_QUERY, {
    variables: {
      id: closingId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const closing = data.closing;
    const audit = closing.audit;

    if (audit.length === 0) return <p>No audit found.</p>;

    return (
      <ul>
        {audit.map(a => (
          <li key={a.id}>
            <div>
              <strong>{a.status}</strong>
              <p>
                Updated by {a.updatedBy.name} on {a.auditCreatedAt}
              </p>
              <p>Amount: {a.amount}</p>
              <p>Funding Source: {a.fundingSource}</p>
              <p>Investment Entity: {a.investmentEntity.name}</p>
              <p>Created at: {a.createdAt}</p>
              <p>Updated at: {a.updatedAt}</p>
              {a.wiredAt && <p>Wired at: {a.wiredAt}</p>}
              {a.executedAt && <p>Executed at: {a.executedAt}</p>}
              {a.finalisedAt && <p>Finalised at: {a.finalisedAt}</p>}
            </div>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className={theme.container}>
      <h3>Audit</h3>
      {renderContent()}
    </div>
  );
};

export default ClosingAudit;
