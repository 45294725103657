import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import Badge from 'primitives/Badge';

import { formatNumber } from 'utils/format-helper';

import ConvertibleValuationChangeSlideOver from './ConvertibleValuationChangeSlideOver';

const ConvertibleValuationChangeCard: FC<{
  id: string;
  valuationMultiplier: number;
  effectiveFrom: string;
  convertibleClasses: {
    id: string;
    name: string;
  }[];
  createdAt: string;
}> = ({ id, valuationMultiplier, convertibleClasses, effectiveFrom, createdAt }) => {
  const [showSlideOver, toggleSlideOver] = useState(false);

  function lineItem(label: string, value: string, color: string = 'text-gray-800') {
    return (
      <div className="my-0.5 gap-x-2 grid grid-cols-2">
        <span className="text-sm leading-5 text-gray-600">{label}:</span>
        <span className={classNames('text-sm font-semibold leading-5', color)}>{value}</span>
      </div>
    );
  }

  return (
    <>
      <ConvertibleValuationChangeSlideOver
        open={showSlideOver}
        convertibleValuationChangeId={id}
        onClose={() => toggleSlideOver(false)}
      />
      <div
        className="p-4 ring-1 ring-indigo-100 sm:rounded-xl hover:bg-gray-50 hover:cursor-pointer"
        onClick={() => toggleSlideOver(true)}
      >
        <div>
          <Badge label={'Convertible Valuation Change'} color={'red'} />
          <p className="mt-1 font-semibold leading-6 text-gray-700">
            Valuation changed by {valuationMultiplier}x
          </p>
          <div className="grid grid-cols-2 gap-x-4 mt-2">
            {lineItem('Effective From', dayjs(effectiveFrom).format('DD MMMM YYYY'))}
            {lineItem(
              'Valuation Multiplier',
              formatNumber(valuationMultiplier),
              valuationMultiplier > 0
                ? 'text-green-500'
                : valuationMultiplier < 0
                ? 'text-red-500'
                : 'text-gray-800'
            )}
            {lineItem(
              'Affects Convertible Classes',
              convertibleClasses.map(c => c.name).join(', '),
              'text-gray-800'
            )}
            {lineItem('Created On', dayjs(createdAt).format('DD MMMM YYYY'))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ConvertibleValuationChangeCard;
