import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_CLOSING_MUTATION = gql(`
  mutation RemoveClosing($id: ID!) {
    removeClosing(id: $id) {
      id
      deal {
        id
        statistics {
          totalCommittedAmount
        }
      }
    }
  }
`);

const RemoveClosingButton: FC<{ id: string; onDelete: () => void }> = ({ id, onDelete }) => {
  const [removeClosing, { loading, error }] = useMutation(REMOVE_CLOSING_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          id: data.removeClosing.deal.id,
          __typename: 'DealType',
        }),
        fields: {
          closings(existingClosingsRef, { readField }) {
            const updatedClosingsRef = { ...existingClosingsRef };
            updatedClosingsRef.nodes = existingClosingsRef.nodes.filter(
              closingRef => id !== readField('id', closingRef)
            );
            return updatedClosingsRef;
          },
        },
      });
    },
    onCompleted() {
      onDelete();
    },
  });

  return (
    <ConfirmButton
      onConfirm={() => removeClosing({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will delete all data concerning this Closing."
    >
      Remove
    </ConfirmButton>
  );
};

export default RemoveClosingButton;
