import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_COMPANY_MUTATION = gql(`
  mutation RemoveCompany($id: ID!) {
    removeCompany(id: $id) {
      id
    }
  }
`);

const RemoveCompanyButton: FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();

  const [removeCompany, { loading, error }] = useMutation(REMOVE_COMPANY_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        fields: {
          companies(existingCompaniesRef, { readField }) {
            const updatedCompaniesRef = { ...existingCompaniesRef };
            updatedCompaniesRef.nodes = existingCompaniesRef.nodes.filter(
              companyRef => id !== readField('id', companyRef)
            );
            return updatedCompaniesRef;
          },
        },
      });
    },
    onCompleted() {
      navigate('/companies');
    },
  });

  return (
    <ConfirmButton
      onConfirm={() => removeCompany({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will delete all data concerning this Company including any deals."
    >
      Remove
    </ConfirmButton>
  );
};

export default RemoveCompanyButton;
