import { useQuery } from '@apollo/client';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

const FUND_QUERY = gql(`
  query GetFund($id: ID!) {
    fund(id: $id) {
      id
      name
    }
  }
`);

const FundPage: FC = () => {
  const navigate = useNavigate();
  const { fundId } = useParams<{ fundId: string }>() as { fundId: string };

  const { loading, error, data, refetch } = useQuery(FUND_QUERY, {
    variables: {
      id: fundId,
    },
  });

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const fund = data.fund;

    const tabs = [
      {
        key: 'information',
        label: 'Information',
        description: 'Basic details',
        route: `/funds/${fundId}`,
        icon: <InformationCircleIcon />,
        exact: true,
      },
      {
        key: 'commitments',
        label: 'Commitments',
        description: 'Commitments made',
        route: `/funds/${fundId}/commitments`,
        icon: <InformationCircleIcon />,
      },
      {
        key: 'investments',
        label: 'Investments',
        description: 'Investments made',
        route: `/funds/${fundId}/investments`,
        icon: <InformationCircleIcon />,
      },
    ];

    return (
      <>
        <SidebarLayoutNavigation
          title={fund.name}
          subTitle="fund"
          onBackButtonClick={() => navigate(-1)}
        />
        <SidebarLayoutContent secondaryTabs={tabs}>
          <Outlet />
        </SidebarLayoutContent>
      </>
    );
  }

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default FundPage;
