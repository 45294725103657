import classNames from 'classnames';
import dayjs from 'dayjs';

import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import { fixToTwoLocalPrice, formatCurrencyToShort, formatNumber } from 'utils/format-helper';

import Badge from '../primitives/Badge';

type Statistic = {
  label?: string;
  value?: any;
  customValue?: React.ElementType;
  type?:
    | 'DATE'
    | 'DATETIME'
    | 'MONTH'
    | 'STRING'
    | 'CURRENCY'
    | 'CURRENCY_SHORT'
    | 'PERCENTAGE'
    | 'IMAGE'
    | 'NUMBER'
    | 'STATUS'
    | 'FILE'
    | 'SELECT'
    | 'CUSTOM'
    | 'PRODUCTS'
    | 'LINK'
    | 'EXTERNAL_LINK'
    | 'BOOLEAN'
    | 'MULTIPLE'
    | string;
  helperText?: string;
  navigateTo?: string;
};

const getValue = (
  value: Statistic['value'],
  type: Statistic['type'],
  navigateTo?: Statistic['navigateTo'],
  customValue?: Statistic['customValue']
) => {
  if (value === undefined || value === null || value === '') return '-';

  switch (type) {
    case 'CURRENCY':
      return `₹ ${fixToTwoLocalPrice(value)}`;
    case 'CURRENCY_SHORT':
      return formatCurrencyToShort(value, 'en-IN', 'long');
    case 'PERCENTAGE':
      return `${value.toFixed(2)} %`;
    case 'DATE':
      return dayjs(value).format('DD MMMM YYYY');
    case 'DATETIME':
      return dayjs(value).format('h:mm A, DD MMMM YYYY');
    case 'MONTH':
      return dayjs(value).format('MMMM YYYY');
    case 'FILE':
      return value ? (
        <a href={value} rel="noreferrer" target="_blank">
          Download
        </a>
      ) : (
        'Not Available'
      );
    case 'IMAGE':
      return (
        <div
          className="h-10 w-10 bg-contain bg-center bg-no-repeat"
          style={{
            backgroundImage: `url("${value}")`,
          }}
        />
      );
    case 'NUMBER':
      return formatNumber(value);
    case 'STATUS':
      const { label, color } = statusEnumToReadable(value);
      return <Badge label={label} color={color} showIndicator={true} />;
    case 'LINK':
      return <a href={navigateTo || ''}>{value}</a>;
    case 'EXTERNAL_LINK':
      return (
        <a href={navigateTo || ''} rel="noreferrer" target="_blank">
          {value}
        </a>
      );
    case 'BOOLEAN':
      return value ? 'Yes' : 'No';
    case 'CUSTOM':
      const CustomValue: React.ElementType = customValue ? customValue : () => <></>;
      return <CustomValue value={value} />;
    case 'MULTIPLE':
      const multipleValue = Math.round(value * 100) / 100;
      return (
        <span
          className={classNames({
            'text-green-600': multipleValue > 1,
            'text-red-600': multipleValue < 1,
            'text-gray-900': multipleValue === 1,
          })}
        >
          {formatNumber(value)}x
        </span>
      );
    default:
      return value;
  }
};

export default function Statistics({ statistics }: { statistics: Statistic[] }) {
  let largeGridColumnsStyle = 'lg:grid-cols-4';

  if (statistics.length === 2) {
    largeGridColumnsStyle = 'lg:grid-cols-2';
  }

  if (statistics.length === 3) {
    largeGridColumnsStyle = 'lg:grid-cols-3';
  }

  return (
    <dl
      className={`grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 ${largeGridColumnsStyle}`}
    >
      {statistics.map(stat => (
        <div key={stat.label} className="flex flex-col bg-gray-400/5 p-8">
          <dt className="text-sm font-semibold leading-6 text-gray-600">{stat.label}</dt>
          <dd className="order-first text-2xl font-semibold tracking-tight text-gray-900">
            {getValue(stat.value, stat.type, stat.navigateTo, stat.customValue)}
          </dd>
        </div>
      ))}
    </dl>
  );
}
