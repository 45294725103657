import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { DealInviteStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_DEAL_INVITE_MUTATION = gql(`
  mutation UpdateDealInvite(
    $id: ID!
    $status: DealInviteStatusEnumType!
    $note: String
    $minimumInvestmentAmount: Int
    $maximumInvestmentAmount: Int
  ) {
    updateDealInvite(
      id: $id
      status: $status
      note: $note
      minimumInvestmentAmount: $minimumInvestmentAmount
      maximumInvestmentAmount: $maximumInvestmentAmount
    ) {
      id
      status
      note
      minimumInvestmentAmount
      maximumInvestmentAmount
    }
  }
`);

const UpdateDealInvite: FC<{
  id: string;
  note?: string | null;
  minimumInvestmentAmount?: number | null;
  maximumInvestmentAmount?: number | null;
  status: DealInviteStatusEnumType;
}> = ({ id, note, minimumInvestmentAmount, maximumInvestmentAmount, status }) => {
  const [updateDealInvite, { loading, error }] = useMutation(UPDATE_DEAL_INVITE_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateDealInvite({
          variables: {
            id,
            note: data.note,
            minimumInvestmentAmount: data.minimumInvestmentAmount,
            maximumInvestmentAmount: data.maximumInvestmentAmount,
            status: data.status,
          },
        });
      }}
    >
      <FormInput type="text" fieldName="note" label="Note" defaultValue={note} />
      <FormInput
        type="currency"
        fieldName="minimumInvestmentAmount"
        label="Minimum Investment Amount"
        defaultValue={minimumInvestmentAmount}
      />
      <FormInput
        type="currency"
        fieldName="maximumInvestmentAmount"
        label="Maximum Investment Amount"
        defaultValue={maximumInvestmentAmount}
      />
      <FormInput
        type="select"
        fieldName="status"
        label="Status"
        defaultValue={status}
        options={[
          { value: DealInviteStatusEnumType.Active, label: 'Active', color: 'bg-green-500' },
          { value: DealInviteStatusEnumType.Inactive, label: 'Inactive', color: 'bg-gray-500' },
          { value: DealInviteStatusEnumType.Committed, label: 'Committed', color: 'bg-yellow-500' },
          { value: DealInviteStatusEnumType.Expired, label: 'Expired', color: 'bg-red-500' },
          { value: DealInviteStatusEnumType.Passed, label: 'Passed', color: 'bg-orange-500' },
          { value: DealInviteStatusEnumType.Cancelled, label: 'Cancelled', color: 'bg-red-500' },
        ]}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateDealInvite;
