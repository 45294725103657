import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import UpdateEmailPreferences from './UpdateEmailPreferences';
import UpdateNotificationPreferences from './UpdateNotificationPreferences';

const USER_QUERY = gql(`
  query getUserNotificationPreferences($userId: ID!) {
    user(id: $userId) {
      id
      notificationPreferences {
        id
        whatsappEnabled
        emailEnabled
        smsEnabled
        platformEnabled
      }
      emailPreferences {
        id
        newSyndicateApplications
        syndicateSuggestions
        monthlyNewsDigest
        dealProgressUpdates
        dealInviteReminders
        dealInvites
        dealInviteUpdates
        portfolioCompanyUpdates
        messages
      }
    }
  }
`);

const UserNotificationPreferences: FC = () => {
  const { userId } = useParams<{ userId: string }>() as { userId: string };

  const { loading, error, data, refetch } = useQuery(USER_QUERY, {
    variables: {
      userId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const user = data.user;

  const {
    id,
    dealInviteReminders,
    dealInviteUpdates,
    dealInvites,
    dealProgressUpdates,
    messages,
    monthlyNewsDigest,
    newSyndicateApplications,
    portfolioCompanyUpdates,
    syndicateSuggestions,
  } = data.user.emailPreferences;

  return (
    <>
      <UpdateNotificationPreferences
        id={user.notificationPreferences.id}
        whatsappEnabled={user.notificationPreferences.whatsappEnabled}
        emailEnabled={user.notificationPreferences.emailEnabled}
        smsEnabled={user.notificationPreferences.smsEnabled}
        platformEnabled={user.notificationPreferences.platformEnabled}
      />
      <UpdateEmailPreferences
        id={id}
        dealInviteReminders={dealInviteReminders}
        dealInviteUpdates={dealInviteUpdates}
        dealInvites={dealInvites}
        dealProgressUpdates={dealProgressUpdates}
        messages={messages}
        monthlyNewsDigest={monthlyNewsDigest}
        newSyndicateApplications={newSyndicateApplications}
        portfolioCompanyUpdates={portfolioCompanyUpdates}
        syndicateSuggestions={syndicateSuggestions}
      />
    </>
  );
};

export default UserNotificationPreferences;
