import { ChevronRightIcon } from '@heroicons/react/24/outline';
import {
  ConvertibleAssetStatusEnumType,
  ConvertibleAssetTypeEnumType,
} from '__generated__/graphql';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC } from 'react';

import Badge from 'primitives/Badge';

import convertibleAssetTypeToReadable from 'utils/enums/convertible-asset-type-to-readable';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import { formatCurrency, formatNumber } from 'utils/format-helper';

const ConvertibleAssetCard: FC<{
  id: string;
  type: ConvertibleAssetTypeEnumType;
  status: ConvertibleAssetStatusEnumType;
  createdAt: string;
  value: number;
  maturityDate?: string | null;
  convertedAt?: string | null;
  convertibleClass: {
    id: string;
    name: string;
    valuationOrCap: number;
    latestValuationOrCap: number;
    issueDate: string;
    discount: number;
  };
  onClick: (id: string) => void;
}> = ({
  id,
  type,
  status,
  createdAt,
  value,
  maturityDate,
  convertedAt,
  convertibleClass,
  onClick,
}) => {
  const { label: statusLabel, color: statusColor } = statusEnumToReadable(status);
  const { label: typeLabel, color: typeColor } = convertibleAssetTypeToReadable(type);

  function lineItem(label: string, value: string, color: string = 'text-gray-800') {
    return (
      <div className="my-0.5 gap-x-2 grid grid-cols-2">
        <span className="text-sm leading-5 text-gray-600">{label}:</span>
        <span className={classNames('text-sm font-semibold leading-5', color)}>{value}</span>
      </div>
    );
  }

  const valuationMovement =
    Math.round((convertibleClass.latestValuationOrCap - convertibleClass.valuationOrCap) * 100) /
    100;

  return (
    <li
      className="relative flex justify-between items-start gap-x-6 px-4 py-3 hover:bg-gray-50 cursor-pointer"
      onClick={() => onClick(id)}
    >
      <div className="min-w-0 flex-auto">
        <Badge label={typeLabel} color={typeColor} />
        <p className="mt-1 font-semibold leading-6 text-indigo-700">
          <span className="text-sm font-medium text-gray-700">Convertible Class: </span>
          {convertibleClass.name}
        </p>
        <div className="grid grid-cols-2 gap-x-4 mt-2">
          {lineItem('Issue Date', dayjs(convertibleClass.issueDate).format('DD MMMM YYYY'))}
          {lineItem('Purchase Value', formatCurrency(value))}
          {lineItem('Issue Valuation Or Cap', formatCurrency(convertibleClass.valuationOrCap))}
          {lineItem('Discount', formatNumber(convertibleClass.discount))}
          {lineItem(
            'Latest Valuation or Cap',
            formatCurrency(convertibleClass.latestValuationOrCap),
            valuationMovement > 0
              ? 'text-green-500'
              : valuationMovement < 0
              ? 'text-red-500'
              : 'text-gray-800'
          )}
          {lineItem('Maturity Date', dayjs(maturityDate).format('DD MMMM YYYY'))}
          {status === ConvertibleAssetStatusEnumType.Converted
            ? lineItem('Converted At', dayjs(convertedAt).format('DD MMMM YYYY'))
            : null}
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-x-4">
        <div className="hidden sm:flex sm:flex-col sm:items-end">
          <Badge label={statusLabel} color={statusColor} />
          <p className="mt-1 text-xs leading-5 text-gray-500">
            Created on{' '}
            <time dateTime={dayjs(createdAt).format('DD MMMM YYYY')}>
              {dayjs(createdAt).format('DD MMMM YYYY')}
            </time>
          </p>
        </div>
        <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
      </div>
    </li>
  );
};

export default ConvertibleAssetCard;
