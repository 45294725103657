export default function CheckboxGroup({
  value,
  onChange,
  options,
  label,
  helperText,
  disabled = false,
}: {
  value: string[];
  onChange?: (value: string[]) => void;
  options: { label: string; value: string; description?: string }[];
  label: string;
  helperText?: string;
  disabled?: boolean;
}) {
  const handleChange = optionValue => {
    const newValue = value.includes(optionValue)
      ? value.filter(v => v !== optionValue)
      : [...value, optionValue];
    onChange!(newValue);
  };

  return (
    <fieldset>
      <legend className="sr-only">{label}</legend>
      <span className="text-base font-semibold text-gray-900">{label}</span>
      <span className="text-sm text-gray-500">{helperText}</span>
      <div className="space-y-5">
        {options.map(option => (
          <div key={option.value} className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id={option.value}
                aria-describedby={`${option.value}-description`}
                name={option.value}
                type="checkbox"
                checked={value.includes(option.value)}
                onChange={() => handleChange(option.value)}
                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600 disabled:cursor-not-allowed disabled:bg-gray-200 disabled:border-gray-300 disabled:text-gray-500 disabled:checked:bg-gray-300 disabled:checked:border-transparent disabled:checked:text-gray-900 disabled:checked:cursor-not-allowed"
                disabled={disabled}
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor={option.value} className="font-medium text-gray-900">
                {option.label}
              </label>
              <p id={`${option.value}-description`} className="text-gray-500">
                {option.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
}
