import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const CREATE_EXTERNAL_INVESTOR_MUTATION = gql(`
  mutation CreateDealExternalInvestor(
    $dealId: ID!
    $name: String!
    $description: String!
    $amount: Float!
    $isLead: Boolean!
  ) {
    createDealExternalInvestor(
      dealId: $dealId
      name: $name
      description: $description
      amount: $amount
      isLead: $isLead
    ) {
      id
      name
      description
      amount
      isLead
    }
  }
`);

const CreateDealExternalInvestorDialogButton: FC<{
  dealId: string;
}> = ({ dealId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [createDealExternalInvestor, { loading, error }] = useMutation(
    CREATE_EXTERNAL_INVESTOR_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;

        cache.modify({
          id: cache.identify({
            __typename: 'DealType',
            id: dealId,
          }),
          fields: {
            externalInvestors(existingExternalInvestors = []) {
              const newExternalInvestorRef = cache.writeFragment({
                data: data.createDealExternalInvestor,
                fragment: gql(`
                fragment NewExternalInvestor on DealExternalInvestorType {
                  id
                  name
                  description
                  amount
                  isLead
                }
              `),
              });

              return [...existingExternalInvestors, newExternalInvestorRef];
            },
          },
        });
      },
    }
  );

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Create Deal External Investor</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Create a Deal External Investor</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              createDealExternalInvestor({
                variables: {
                  dealId,
                  name: data.name,
                  description: data.description,
                  amount: data.amount,
                  isLead: data.isLead,
                },
              }).then(() => toggleDialog(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput type="text" fieldName="name" label="Name" defaultValue="" />
            <FormInput type="currency" fieldName="amount" label="Amount" defaultValue="" />
            <FormInput type="switch" fieldName="isLead" label="Is Lead" defaultValue={false} />
            <FormInput type="text" fieldName="description" label="Description" defaultValue="" />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateDealExternalInvestorDialogButton;
