import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_DEAL_MUTATION = gql(`
  mutation RemoveDeal($id: ID!) {
    removeDeal(id: $id) {
      id
    }
  }
`);

const RemoveDealButton: FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();

  const [removeDeal, { loading, error }] = useMutation(REMOVE_DEAL_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        fields: {
          deals(existingDealsRef, { readField }) {
            const updatedDealsRef = { ...existingDealsRef };
            updatedDealsRef.nodes = existingDealsRef.nodes.filter(
              dealRef => id !== readField('id', dealRef)
            );
            return updatedDealsRef;
          },
        },
      });
    },
    onCompleted() {
      navigate('/deals');
    },
  });

  return (
    <div className="bg-white border border-red-500 sm:rounded-lg">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-red-800">Delete Deal</h3>
        <div className="mt-1 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-base text-red-700">
            <p>Once you delete a deal, there is no going back. Please be certain.</p>
          </div>
          <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
            <ConfirmButton
              variant="secondary"
              onConfirm={() => removeDeal({ variables: { id } })}
              loading={loading}
              error={error}
              description="This will delete all data concerning this Deal."
            >
              Delete
            </ConfirmButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveDealButton;
