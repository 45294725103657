import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import EmptyState from 'primitives/EmptyState';
import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import ErrorMessage from 'components/ErrorMessage';

import RemoveShareClassButton from './RemoveShareClassButton';
import UpdateShareAsset from './UpdateShareAsset';
import UpdateShareClass from './UpdateShareClass';

const SHARE_CLASS_QUERY = gql(`
  query getShareClass($id: ID!) {
    shareClass(id: $id) {
      id
      name
      issuePricePerShare
      issueDate
      createdAt
      updatedAt
      shareAssets {
        id
        status
        numberOfShares
        issueNumberOfShares
        createdAt
        deal {
          id
          schemeName
        }
      }
    }
  }
`);

const ShareClassSlideOver: FC<{
  open: boolean;
  shareClassId: string;
  onClose: () => void;
}> = ({ open = false, shareClassId, onClose }) => {
  const { loading, error, data, refetch } = useQuery(SHARE_CLASS_QUERY, {
    variables: {
      id: shareClassId,
    },
    skip: !open,
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const shareClass = data.shareClass;

    return (
      <>
        <UpdateShareClass
          id={shareClass.id}
          name={shareClass.name}
          issuePricePerShare={shareClass.issuePricePerShare}
          issueDate={shareClass.issueDate}
        />

        <div className="bg-gray-50 p-4 rounded-lg">
          <p className="text-md font-semibold text-gray-800 mb-4">Share assets in this class</p>
          {shareClass.shareAssets.length > 0 ? (
            <div className="grid grid-cols-2 gap-x-2 gap-y-3">
              {shareClass.shareAssets.map(shareAsset => (
                <div key={shareClass.id} className="bg-white rounded-xl p-4">
                  <p className="text-sm font-semibold text-indigo-500 mb-4">
                    {shareAsset.deal.schemeName}
                  </p>
                  <UpdateShareAsset
                    key={shareAsset.id}
                    id={shareAsset.id}
                    issueNumberOfShares={shareAsset.issueNumberOfShares}
                    numberOfShares={shareAsset.numberOfShares}
                    status={shareAsset.status}
                  />
                </div>
              ))}
            </div>
          ) : (
            <EmptyState
              title="No share assets in this class"
              description="This share class does not have any share assets yet. Assets can only be created from within a deal."
            />
          )}
        </div>
        <RemoveShareClassButton id={shareClass.id} onRemove={onClose} />
      </>
    );
  };

  return (
    <SlideOver
      open={open}
      onClose={onClose}
      title="Share Class"
      subTitle="Manage the share class and it's underlying assets"
    >
      {renderContent()}
    </SlideOver>
  );
};

export default ShareClassSlideOver;
