import { gql } from '__generated__';

const getFieldNameForCompany = (field: string) => {
  const fieldToReadableNameMap = {
    oldPlatformId: 'Old Platform ID',
    name: 'Name',
    tagline: 'Tagline',
    legalName: 'Legal Name',
    description: 'Description',
    countryOfIncorporation: 'Country of Incorporation',
    image: 'Image',
    incorporationType: 'Incorporation Type',
    cin: 'CIN',
    pan: 'PAN',
    website: 'Website',
    status: 'Status',
    dateOfIncorporation: 'Date of Incorporation',
  };

  return fieldToReadableNameMap[field] || field;
};

const COMPANY_AUDIT_QUERY = gql(`
    query CompanyAudit($id: ID!, $page: Int, $limit: Int) {
      company(id: $id) {
        audits(page: $page, limit: $limit) {
          nodes {
            operation
            changes {
              fieldName
              oldValue
              newValue
            }
            updatedAt
            updatedBy {
              name
            }
          }
          pageInfo {
            totalCount
            hasNextPage
          }
        }
      }
    }
  `);

export { getFieldNameForCompany, COMPANY_AUDIT_QUERY };
