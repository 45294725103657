import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

import CarryRecipients from './CarryRecipients';

const GP_COMMITMENT_QUERY = gql(`
  query GpCommitment($id: ID!) {
    deal(id: $id) {
      id
      gpCommitment
      syndicate {
        id
      }
    }
  }
`);

const UPDATE_GP_COMMITMENT_MUTATION = gql(`
  mutation UpdateGpCommitment($id: ID!, $gpCommitment: Float!) {
    updateGpCommitment(id: $id, gpCommitment: $gpCommitment) {
      id
      gpCommitment
    }
  }
`);

const CarryGPCommitment: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const { loading, error, data, refetch } = useQuery(GP_COMMITMENT_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const [updateGpCommitment, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_GP_COMMITMENT_MUTATION
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data || !dealId) return <ErrorMessage error={error} refetch={refetch} />;

  return (
    <>
      <CarryRecipients syndicateId={data.deal.syndicate.id} dealId={dealId} />
      <FormPanelWithReadMode
        loading={updateLoading}
        error={updateError}
        onSubmit={data =>
          updateGpCommitment({
            variables: {
              id: dealId,
              gpCommitment: data.gpCommitment,
            },
          })
        }
        title="GP Commitment"
      >
        <FormInput
          fieldName="gpCommitment"
          type="currency"
          defaultValue={data.deal.gpCommitment || ''}
          label="GP Commitment"
        />
      </FormPanelWithReadMode>
    </>
  );
};

export default CarryGPCommitment;
