import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { Navigate } from 'react-router-dom';

import FocusLayout from 'layouts/FocusLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

const ADMIN_DASHBOARD_QUERY = gql(`
  query AdminDashboardAccess {
    adminDashboard {
      id
    }
  }
`);

const Unauthorized: FC = () => {
  const { loading, error, data, refetch } = useQuery(ADMIN_DASHBOARD_QUERY);

  if (loading) return <LoadingIndicator />;
  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  if (data.adminDashboard.id) {
    return <Navigate to="/" />;
  }

  return (
    <FocusLayout>
      <main className="grid min-h-full place-items-center py-32">
        <div className="text-center">
          <p className="text-base font-semibold text-indigo-600">Admin</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            You are not authorized to access this page
          </h1>
          <p className="mt-6 text-xl leading-7 text-gray-600">
            This page is not accessible to you. Please contact your administrator for more
            information.
          </p>
        </div>
      </main>
    </FocusLayout>
  );
};

export default Unauthorized;
