import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_SHARE_CLASS_MUTATION = gql(`
  mutation RemoveShareClass($id: ID!) {
    removeShareClass(id: $id) {
      id
      company {
        id
      }
    }
  }
`);

const RemoveShareClassButton: FC<{ id: string; onRemove: () => void }> = ({ id, onRemove }) => {
  const [removeShareClass, { loading, error }] = useMutation(REMOVE_SHARE_CLASS_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          id: data.removeShareClass.company.id,
          __typename: 'CompanyType',
        }),
        fields: {
          shareClasses(existingShareClassesRef, { readField }) {
            return existingShareClassesRef.filter(
              shareClassRef => id !== readField('id', shareClassRef)
            );
          },
        },
      });
    },
    onCompleted() {
      onRemove();
    },
  });

  return (
    <ConfirmButton
      onConfirm={() => removeShareClass({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will delete all data concerning this Share Class."
    >
      Remove Share Class
    </ConfirmButton>
  );
};

export default RemoveShareClassButton;
