import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { SyndicateStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_SYNDICATE_MUTATION = gql(`
  mutation UpdateSyndicateStatus($id: ID!, $status: SyndicateStatusEnumType!) {
    updateSyndicateStatus(id: $id, status: $status) {
      id
      status
      updatedAt
    }
  }
`);

const UpdateSyndicateStatus: FC<{ id: string; status: SyndicateStatusEnumType }> = ({
  id,
  status,
}) => {
  const [updateSyndicate, { loading, error }] = useMutation(UPDATE_SYNDICATE_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        fields: {
          syndicates(existingSyndicatesRef = []) {
            if (
              !existingSyndicatesRef.nodes
                .map(element => element.__ref)
                .includes(`SyndicateType:${id}`)
            ) {
              const newSyndicateNodeRef = cache.writeFragment({
                data: data.updateSyndicateStatus,
                fragment: gql(`
                  fragment UpdatedSyndicate on SyndicateType {
                    id
                    status
                    updatedAt
                  }
                `),
              });
              const updatedSyndicatesRef = { ...existingSyndicatesRef };
              updatedSyndicatesRef.nodes = [newSyndicateNodeRef, ...existingSyndicatesRef.nodes];
              return updatedSyndicatesRef;
            }
          },
        },
      });
    },
  });

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateSyndicate({
          variables: {
            id,
            status: data.status,
          },
        });
      }}
      title="Status"
    >
      <FormInput
        type="select"
        fieldName="status"
        label="Status"
        defaultValue={status}
        options={[
          {
            value: SyndicateStatusEnumType.Active,
            label: 'Active',
            color: 'bg-green-500',
          },
          {
            value: SyndicateStatusEnumType.Inactive,
            label: 'Inactive',
            color: 'bg-red-500',
          },
          {
            value: SyndicateStatusEnumType.UnderReview,
            label: 'Under Review',
            color: 'bg-yellow-500',
          },
          {
            value: SyndicateStatusEnumType.Deleted,
            label: 'Deleted',
            color: 'bg-red-500',
          },
        ]}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateSyndicateStatus;
