import { ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import Badge from 'primitives/Badge';

import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import { formatCurrency, formatNumber } from 'utils/format-helper';

import ShareClassSlideOver from './ShareClassSlideOver';

type ShareAsset = {
  id: string;
  status: string;
  createdAt: string;
  numberOfShares: number;
  deal: {
    id: string;
    schemeName?: string | null;
    statistics: {
      totalWiredAmount: number;
      multiple?: number | null;
    };
  };
};

const ShareAssetCard: FC<{
  asset: ShareAsset;
  multiple: number;
  onClick: (id: string) => void;
}> = ({ asset, multiple, onClick }) => {
  const { label: statusLabel, color: statusColor } = statusEnumToReadable(asset.status);

  function lineItem(label: string, value: string, color: string = 'text-gray-800') {
    return (
      <div className="my-0.5 gap-x-2 grid grid-cols-2">
        <span className="text-sm leading-5 text-gray-600">{label}:</span>
        <span className={classNames('text-sm font-semibold leading-5', color)}>{value}</span>
      </div>
    );
  }

  return (
    <>
      <li
        className="relative flex justify-between items-start gap-x-6 px-4 py-3 bg-gray-50 hover:bg-indigo-50 cursor-pointer"
        onClick={() => onClick(asset.id)}
      >
        <div className="min-w-0 flex-auto">
          <Badge label="Share Purchase" color="indigo" />
          <div className="grid grid-cols-2 gap-x-4 mt-2">
            {lineItem('Number of Shares', formatNumber(asset.numberOfShares))}
            {lineItem('Scheme', asset.deal.schemeName || 'Unknown Scheme')}
          </div>
          {multiple !== asset.deal.statistics.multiple && (
            <div className="mt-2 bg-gray-100 p-2 rounded-md">
              <p className="text-xs leading-5 text-gray-800">
                <strong>Note: </strong>The multiple shown to the investor might be different because
                the wired amount of the deal does not match the aggregate of{' '}
                <span className="font-mono">(numberOfShares * issuePricePerShare)</span>
              </p>
              <div className="grid grid-cols-2 gap-x-4 mt-2">
                {lineItem(
                  'Wired Amount On Deal',
                  formatNumber(asset.deal.statistics.totalWiredAmount)
                )}
                {lineItem(
                  'Multiple',
                  asset.deal.statistics.multiple ? `${asset.deal.statistics.multiple}x` : 'N/A'
                )}
              </div>
            </div>
          )}
        </div>
        <div className="flex shrink-0 items-center gap-x-4">
          <div className="hidden sm:flex sm:flex-col sm:items-end">
            <Badge label={statusLabel} color={statusColor} />
            <p className="mt-1 text-xs leading-5 text-gray-500">
              Created on{' '}
              <time dateTime={dayjs(asset.createdAt).format('DD MMMM YYYY')}>
                {dayjs(asset.createdAt).format('DD MMMM YYYY')}
              </time>
            </p>
          </div>
          <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
        </div>
      </li>
    </>
  );
};

const ShareClassCard: FC<{
  id: string;
  name: string;
  issueDate: string;
  issuePricePerShare: number;
  latestPricePerShare: number;
  assets: ShareAsset[];
}> = ({ id, name, issueDate, issuePricePerShare, latestPricePerShare, assets }) => {
  const [showSlideOver, toggleSlideOver] = useState(false);

  const priceMovement = Math.round((latestPricePerShare - issuePricePerShare) * 100) / 100;

  function lineItem(label: string, value: string, color: string = 'text-gray-800') {
    return (
      <div className="my-0.5 gap-x-2 grid grid-cols-2">
        <span className="text-sm leading-5 text-gray-600">{label}:</span>
        <span className={classNames('text-sm font-semibold leading-5', color)}>{value}</span>
      </div>
    );
  }

  return (
    <>
      <ShareClassSlideOver
        open={showSlideOver}
        shareClassId={id}
        onClose={() => toggleSlideOver(false)}
      />
      <div
        className="p-4 ring-1 ring-indigo-100 sm:rounded-xl hover:bg-gray-50 hover:cursor-pointer"
        onClick={() => toggleSlideOver(true)}
      >
        <div className="min-w-0 flex-auto">
          <Badge label={'Share Class'} color={'indigo'} />
          <p className="mt-1 font-semibold leading-6 text-gray-700">{name}</p>
          <div className="grid grid-cols-2 gap-x-4 mt-2">
            {lineItem('Issue Date', dayjs(issueDate).format('DD MMMM YYYY'))}
            {lineItem('Issue Price Per Share', formatCurrency(issuePricePerShare))}
            {lineItem(
              'Latest Value Per Share',
              formatCurrency(latestPricePerShare),
              priceMovement > 0
                ? 'text-green-500'
                : priceMovement < 0
                ? 'text-red-500'
                : 'text-gray-800'
            )}
            {lineItem(
              'Multiple',
              `${formatNumber(latestPricePerShare / issuePricePerShare)}x`,
              priceMovement > 0
                ? 'text-green-500'
                : priceMovement < 0
                ? 'text-red-500'
                : 'text-gray-800'
            )}
          </div>
        </div>
        {assets.length > 0 && (
          <ul className="mt-4 divide-y divide-gray-100 overflow-hidden bg-white sm:rounded-xl">
            {assets.map(asset => (
              <ShareAssetCard
                key={asset.id}
                asset={asset}
                multiple={latestPricePerShare / issuePricePerShare}
                onClick={() => {}}
              />
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default ShareClassCard;
