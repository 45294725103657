import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const CREATE_CONVERTIBLE_VALUATION_CHANGE_MUTATION = gql(`
  mutation CreateConvertibleValuationChange(
    $companyId: ID!
    $convertibleClassIds: [ID!]!
    $valuationMultiplier: Float!
    $effectiveFrom: String!
  ) {
    createConvertibleValuationChange(
      companyId: $companyId
      convertibleClassIds: $convertibleClassIds
      valuationMultiplier: $valuationMultiplier
      effectiveFrom: $effectiveFrom
    ) {
      id
      valuationMultiplier
      effectiveFrom
      createdAt
    }
  }
`);

const CreateConvertibleValuationChangeDialogButton: FC<{
  companyId: string;
  convertibleClasses: { id: string; name: string }[];
}> = ({ companyId, convertibleClasses }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [createConvertibleValuationChange, { loading, error }] = useMutation(
    CREATE_CONVERTIBLE_VALUATION_CHANGE_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            id: companyId,
            __typename: 'CompanyType',
          }),
          fields: {
            convertibleValuationChanges(existingConvertibleValuationChangesRef) {
              const newConvertibleValuationChangeNodeRef = cache.writeFragment({
                data: data.createConvertibleValuationChange,
                fragment: gql(`
                fragment NewConvertibleValuationChange on ConvertibleValuationChangeType {
                  id
                  valuationMultiplier
                  effectiveFrom                 
                  createdAt
                }
              `),
              });

              return [
                ...existingConvertibleValuationChangesRef,
                newConvertibleValuationChangeNodeRef,
              ];
            },
          },
        });
      },
    }
  );

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Create Convertible Valuation Change</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Create Convertible Valuation Change</DialogTitle>
          <DialogSubTitle>
            Fill in the details below to create a new convertible valuation change
          </DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              createConvertibleValuationChange({
                variables: {
                  companyId,
                  convertibleClassIds: data.convertibleClassIds,
                  valuationMultiplier: data.valuationMultiplier,
                  effectiveFrom: data.effectiveFrom,
                },
              }).then(() => toggleDialog(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput
              type="checkbox-group"
              fieldName="convertibleClassIds"
              label="Convertible Classes"
              defaultValue={''}
              options={convertibleClasses.map(({ id, name }) => ({ value: id, label: name }))}
              validators={{ required: true }}
            />
            <FormInput
              type="number"
              fieldName="valuationMultiplier"
              label="Valuation Multiplier"
              defaultValue={''}
              validators={{ required: true }}
            />
            <FormInput
              type="date"
              fieldName="effectiveFrom"
              label="Effective From"
              defaultValue={''}
              validators={{ required: true }}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateConvertibleValuationChangeDialogButton;
