import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

import SearchInvestmentEntities from './SearchInvestmentEntities';

const CREATE_CLOSING_MUTATION = gql(`
  mutation CreateClosing(
    $amount: Float!
    $fundingSource: FundingSourceEnumType!
    $investmentEntityId: ID!
    $dealId: ID!
  ) {
    createClosing(
      amount: $amount
      fundingSource: $fundingSource
      investmentEntityId: $investmentEntityId
      dealId: $dealId
    ) {
      id
      amount
      fundingSource
      investmentEntity {
        id
        name
      }
      status
      createdAt
      updatedAt
    }
  }
`);

const CreateClosingDialogButton: FC<{ dealId: string }> = ({ dealId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [investmentEntityId, setInvestmentEntityId] = useState<string | null>();

  const [createClosing, { loading, error }] = useMutation(CREATE_CLOSING_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          id: dealId,
          __typename: 'DealType',
        }),
        fields: {
          closings(existingClosingsRef) {
            const newClosingNodeRef = cache.writeFragment({
              data: data.createClosing,
              fragment: gql(`
                fragment NewClosing on ClosingType {
                  id
                  amount
                  fundingSource
                  investmentEntity {
                    id
                    name
                  }
                  status
                  createdAt
                  updatedAt
                }
              `),
            });
            const updatedClosingsRef = { ...existingClosingsRef };
            updatedClosingsRef.nodes = [newClosingNodeRef, ...updatedClosingsRef.nodes];
            return updatedClosingsRef;
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Create Holding</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Create a Holding</DialogTitle>
          <SearchInvestmentEntities onChange={ie => setInvestmentEntityId(ie.id)} />
          {investmentEntityId && (
            <FormPanel
              loading={loading}
              error={error}
              onCancel={() => toggleDialog(false)}
              onSubmit={data => {
                createClosing({
                  variables: {
                    amount: data.amount,
                    fundingSource: data.fundingSource,
                    investmentEntityId,
                    dealId,
                  },
                }).then(() => toggleDialog(false));
              }}
              buttonRef={buttonRef}
            >
              <FormInput type="currency" fieldName="amount" label="Amount" defaultValue="" />
              <FormInput
                type="select"
                fieldName="fundingSource"
                label="Funding Source"
                defaultValue="INDIAN_ACCOUNT"
                options={[
                  { value: 'INDIAN_ACCOUNT', label: 'Indian Account' },
                  { value: 'FOREIGN_ACCOUNT', label: 'Foreign Account' },
                  {
                    value: 'NON_RESIDENT_ORDINARY_ACCOUNT',
                    label: 'Non Resident Ordinary Account',
                  },
                  {
                    value: 'NON_RESIDENT_EXTERNAL_ACCOUNT',
                    label: 'Non Resident External Account',
                  },
                ]}
              />
            </FormPanel>
          )}
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateClosingDialogButton;
