import { useMutation, useQuery } from '@apollo/client';
import { XCircleIcon } from '@heroicons/react/20/solid';
import { ArrowRightIcon, CheckIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import { DealStatusEnumType } from '__generated__/graphql';
import classNames from 'classnames';
import { FC, useRef } from 'react';

import Badge from 'primitives/Badge';
import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';
import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

const VERIFY_DEAL_STATUS_CHANGE_QUERY = gql(`
  query VerifyDealStatusChangeQuery(
    $dealId: ID!
    $targetStatus: DealStatusEnumType!
  ) {
    deal(id: $dealId) {
      verifyDealStatusChange(
        targetStatus: $targetStatus
      ) {
        label
        taskStatus
      }
    }
  }
`);

const UPDATE_DEAL_MUTATION = gql(`
  mutation FinaliseDealWithStatusButton(
    $id: ID!
    $status: DealStatusEnumType!
    $finalisedAt: String
  ) {
    finaliseDeal(
      id: $id
      status: $status
      finalisedAt: $finalisedAt
    ) {
      id
      status
      updatedAt
      finalisedAt
    }
  }
`);

const UpdateDealStatusDialog: FC<{
  open: boolean;
  dealId: string;
  status: DealStatusEnumType;
  onClose: () => void;
}> = ({ open = false, dealId, status, onClose }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const { data, loading, error, refetch } = useQuery(VERIFY_DEAL_STATUS_CHANGE_QUERY, {
    variables: {
      dealId,
      targetStatus: status,
    },
    nextFetchPolicy: 'network-only',
  });

  const [updateDeal, { loading: updateLoading, error: updateError }] =
    useMutation(UPDATE_DEAL_MUTATION);

  const { label: statusLabel, color: statusColor } = statusEnumToReadable(status);

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const { verifyDealStatusChange } = data.deal;

    const statusChecklist = verifyDealStatusChange.map((task, eventIdx) => ({
      label: task.label,
      taskStatus: task.taskStatus as 'COMPLETED' | 'PENDING' | 'OPTIONAL',
      isLast: eventIdx === verifyDealStatusChange.length - 1,
    }));

    function lineItem(
      label: string,
      taskStatus: 'COMPLETED' | 'PENDING' | 'OPTIONAL',
      isLast: boolean
    ) {
      return (
        <li key={label}>
          <div className="relative pb-8">
            {!isLast && (
              <span
                className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                aria-hidden="true"
              />
            )}
            <div className="relative flex space-x-3">
              <div>
                <span
                  className={classNames(
                    taskStatus === 'COMPLETED'
                      ? 'bg-green-500'
                      : taskStatus === 'PENDING'
                      ? 'bg-red-500'
                      : 'bg-gray-500',
                    'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                  )}
                >
                  {taskStatus === 'COMPLETED' ? (
                    <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  ) : taskStatus === 'PENDING' ? (
                    <XCircleIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  ) : (
                    <ArrowRightIcon className="h-5 w-5 text-white" aria-hidden="true" />
                  )}
                </span>
              </div>
              <p className="text-md text-gray-500 pt-1">{label}</p>
            </div>
          </div>
        </li>
      );
    }

    return (
      <>
        <div className="flow-root mb-8">
          <ul className="-mb-8">
            {statusChecklist.map((task, idx) => lineItem(task.label, task.taskStatus, task.isLast))}
          </ul>
        </div>
        {statusChecklist.every(task => task.taskStatus !== 'PENDING') ? (
          <FormPanel
            loading={updateLoading}
            error={updateError}
            onSubmit={data => {
              updateDeal({
                variables: {
                  id: dealId,
                  status: data.resumeStatusTo || status,
                  finalisedAt: data.finalisedAt,
                },
              }).then(onClose);
            }}
            onCancel={onClose}
            buttonRef={buttonRef}
          >
            {status === 'WIRED' ? (
              <FormInput
                type="date"
                fieldName="finalisedAt"
                label="Wiring Date"
                defaultValue={new Date()}
              />
            ) : (
              // TODO: Find an alternative to this div here. It causes a warning in the console.
              <div />
            )}
          </FormPanel>
        ) : null}
        <DialogActions>
          {/* @ts-ignore */}
          <Button onClick={() => buttonRef.current?.cancel() || onClose()}>Cancel</Button>
          <Button
            loading={updateLoading}
            // @ts-ignore
            onClick={() => buttonRef.current?.submit()}
            disabled={statusChecklist.some(task => task.taskStatus === 'PENDING')}
          >
            Confirm
          </Button>
        </DialogActions>
      </>
    );
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogPanel>
        <DialogTitle>
          Move deal to <Badge size="lg" label={statusLabel} color={statusColor} showIndicator />
        </DialogTitle>
        <DialogSubTitle>Please ensure all the items in the checklist are complete:</DialogSubTitle>
        {renderContent()}
      </DialogPanel>
    </Dialog>
  );
};

export default UpdateDealStatusDialog;
