import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import InvestmentEntityDocumentSlideOver from './InvestmentEntityDocumentSlideOver';

const INVESTMENT_ENTITY_QUERY = gql(`
  query InvestmentEntityDocuments($id: ID!) {
    investmentEntity(id: $id) {
      investmentEntityDocuments {
        id
        type
        name
        description
      }
    }
  }
`);

const InvestmentEntityDocuments: FC = () => {
  const [documentOnDisplay, setDocumentOnDisplay] = useState('');
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch } = useQuery(INVESTMENT_ENTITY_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const documents = data.investmentEntity.investmentEntityDocuments;

  return (
    <>
      <InvestmentEntityDocumentSlideOver
        open={!!documentOnDisplay}
        documentId={documentOnDisplay}
        onClose={() => setDocumentOnDisplay('')}
      />
      <DataTable
        data={documents}
        onClick={document => setDocumentOnDisplay(document.id)}
        columns={[
          {
            label: 'Name',
            fieldName: 'name',
          },
          {
            label: 'Type',
            fieldName: 'type',
          },
          {
            label: 'Description',
            fieldName: 'description',
          },
        ]}
      />
    </>
  );
};

export default InvestmentEntityDocuments;
