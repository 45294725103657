import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import DealDocumentSlideOver from './DealDocumentSlideOver';
import GenerateSchemeInformationDocumentDialogButton from './GenerateSchemeInformationDocumentDialogButton';
import UploadDocumentDialogButton from './UploadDocumentDialogButton';

const DEAL_DOCUMENTS_QUERY = gql(`
  query fetchDealDocuments($id: ID!) {
    deal(id: $id) {
      dealDocuments {
        id
        type
        name
        description
      }
    }
  }
`);

const DealDocuments: FC = () => {
  const [documentOnDisplay, setDocumentOnDisplay] = useState('');
  const { dealId } = useParams<{ dealId: string }>() as {
    dealId: string;
  };

  const { loading, error, data, refetch } = useQuery(DEAL_DOCUMENTS_QUERY, {
    variables: {
      id: dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const documents = data.deal.dealDocuments;

  return (
    <>
      <DealDocumentSlideOver
        open={!!documentOnDisplay}
        documentId={documentOnDisplay}
        onClose={() => setDocumentOnDisplay('')}
      />
      <div className="flex justify-between items-center">
        <UploadDocumentDialogButton dealId={dealId} />
      </div>
      <DataTable
        data={documents}
        onClick={document => setDocumentOnDisplay(document.id)}
        columns={[
          {
            label: 'Name',
            fieldName: 'name',
          },
          {
            label: 'Type',
            fieldName: 'type',
          },
          {
            label: 'Description',
            fieldName: 'description',
          },
        ]}
      />
      <GenerateSchemeInformationDocumentDialogButton dealId={dealId} />
    </>
  );
};

export default DealDocuments;
