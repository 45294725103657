import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_NOTIFICATION_PREFERENCES_MUTATION = gql(`
  mutation UpdateUserNotificationPreferences(
    $id: ID!
    $whatsappEnabled: Boolean!
    $emailEnabled: Boolean!
    $smsEnabled: Boolean!
    $platformEnabled: Boolean!
  ) {
    updateUserNotificationPreferences(
      id: $id
      whatsappEnabled: $whatsappEnabled
      emailEnabled: $emailEnabled
      smsEnabled: $smsEnabled
      platformEnabled: $platformEnabled
    ) {
      id
      whatsappEnabled
      emailEnabled
      smsEnabled
      platformEnabled
    }
  }
`);

const UpdateNotificationPreferences: FC<{
  id: string;
  whatsappEnabled: boolean;
  emailEnabled: boolean;
  smsEnabled: boolean;
  platformEnabled: boolean;
}> = ({ id, whatsappEnabled, emailEnabled, smsEnabled, platformEnabled }) => {
  const [updateUserNotificationPreferences, { loading, error }] = useMutation(
    UPDATE_NOTIFICATION_PREFERENCES_MUTATION
  );

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateUserNotificationPreferences({
          variables: {
            id,
            whatsappEnabled: data.whatsappEnabled,
            emailEnabled: data.emailEnabled,
            smsEnabled: data.smsEnabled,
            platformEnabled: data.platformEnabled,
          },
        });
      }}
      title="Notification Preferences"
    >
      <FormInput
        type="switch"
        fieldName="whatsappEnabled"
        label="Whatsapp Enabled"
        defaultValue={whatsappEnabled}
      />
      <FormInput
        type="switch"
        fieldName="emailEnabled"
        label="Email Enabled"
        defaultValue={emailEnabled}
      />
      <FormInput
        type="switch"
        fieldName="smsEnabled"
        label="SMS Enabled"
        defaultValue={smsEnabled}
      />
      <FormInput
        type="switch"
        fieldName="platformEnabled"
        label="Platform Enabled"
        defaultValue={platformEnabled}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateNotificationPreferences;
