import { useQuery } from '@apollo/client';
import { Autocomplete, TextField } from '@mui/material';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

const INVESTMENT_ENTITIES_QUERY = gql(`
  query SearchInvestmentEntitiesForFunds($cursor: ID, $limit: Int) {
    investmentEntities(cursor: $cursor, limit: $limit) {
      nodes {
        id
        name
      }
      pageInfo {
        cursor
        hasNextPage
      }
    }
  }
`);

const SearchInvestmentEntities: FC<{ onSelect: (investmentEntityId: string) => void }> = ({
  onSelect,
}) => {
  const { loading, error, data, refetch } = useQuery(INVESTMENT_ENTITIES_QUERY, {
    variables: {
      limit: 500,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const investmentEntities = data.investmentEntities.nodes.map(i => ({
    id: i.id,
    label: `${i.name}`,
  }));

  return (
    <>
      <br />
      <Autocomplete
        size="small"
        disablePortal
        options={investmentEntities}
        renderInput={params => (
          <TextField variant="outlined" {...params} label="InvestmentEntity" />
        )}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        onChange={(event: any, newValue: { id: string; label: string } | null) => {
          if (newValue) onSelect(newValue.id);
        }}
      />
    </>
  );
};

export default SearchInvestmentEntities;
