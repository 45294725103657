import { ChevronRightIcon } from '@heroicons/react/24/outline';
import {
  ConvertibleAssetStatusEnumType,
  ConvertibleAssetTypeEnumType,
} from '__generated__/graphql';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import Badge from 'primitives/Badge';

import convertibleAssetTypeToReadable from 'utils/enums/convertible-asset-type-to-readable';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import { formatCurrency, formatNumber } from 'utils/format-helper';

import ConvertibleClassSlideOver from './ConvertibleClassSlideOver';

type ConvertibleAsset = {
  id: string;
  type: ConvertibleAssetTypeEnumType;
  status: ConvertibleAssetStatusEnumType;
  createdAt: string;
  value: number;
  maturityDate?: string | null;
  convertedAt?: string | null;
  deal: {
    id: string;
    schemeName?: string | null;
    statistics: {
      totalWiredAmount: number;
      multiple?: number | null;
    };
  };
};

const ConvertibleAssetCard: FC<{
  asset: ConvertibleAsset;
  onClick: (id: string) => void;
}> = ({ asset, onClick }) => {
  const { label: statusLabel, color: statusColor } = statusEnumToReadable(asset.status);
  const { label: typeLabel, color: typeColor } = convertibleAssetTypeToReadable(asset.type);

  function lineItem(label: string, value: string, color: string = 'text-gray-800') {
    return (
      <div className="my-0.5 gap-x-2 grid grid-cols-2">
        <span className="text-sm leading-5 text-gray-600">{label}:</span>
        <span className={classNames('text-sm font-semibold leading-5', color)}>{value}</span>
      </div>
    );
  }

  return (
    <li
      className="relative flex justify-between items-start gap-x-6 px-4 py-3 hover:bg-gray-50 cursor-pointer"
      onClick={() => onClick(asset.id)}
    >
      <div className="min-w-0 flex-auto">
        <Badge label={typeLabel} color={typeColor} />
        <div className="grid grid-cols-2 gap-x-4 mt-2">
          {lineItem('Purchase Value', formatCurrency(asset.value))}
          {lineItem('Maturity Date', dayjs(asset.maturityDate).format('DD MMMM YYYY'))}
          {asset.status === ConvertibleAssetStatusEnumType.Converted
            ? lineItem('Converted At', dayjs(asset.convertedAt).format('DD MMMM YYYY'))
            : null}
          {lineItem('Scheme', asset.deal.schemeName || 'Unknown Scheme')}
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-x-4">
        <div className="hidden sm:flex sm:flex-col sm:items-end">
          <Badge label={statusLabel} color={statusColor} />
          <p className="mt-1 text-xs leading-5 text-gray-500">
            Created on{' '}
            <time dateTime={dayjs(asset.createdAt).format('DD MMMM YYYY')}>
              {dayjs(asset.createdAt).format('DD MMMM YYYY')}
            </time>
          </p>
        </div>
        <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
      </div>
    </li>
  );
};

const ConvertibleClassCard: FC<{
  id: string;
  name: string;
  valuationOrCap: number;
  latestValuationOrCap: number;
  discount: number;
  issueDate: string;
  assets: ConvertibleAsset[];
}> = ({ id, name, valuationOrCap, latestValuationOrCap, discount, issueDate, assets }) => {
  const [showSlideOver, toggleSlideOver] = useState(false);

  const valuationMovement = Math.round((latestValuationOrCap - valuationOrCap) * 100) / 100;

  function lineItem(label: string, value: string, color: string = 'text-gray-800') {
    return (
      <div className="my-0.5 gap-x-2 grid grid-cols-2">
        <span className="text-sm leading-5 text-gray-600">{label}:</span>
        <span className={classNames('text-sm font-semibold leading-5', color)}>{value}</span>
      </div>
    );
  }

  return (
    <>
      <ConvertibleClassSlideOver
        open={showSlideOver}
        convertibleClassId={id}
        onClose={() => toggleSlideOver(false)}
      />
      <div
        className="p-4 ring-1 ring-indigo-100 sm:rounded-xl hover:bg-gray-50 hover:cursor-pointer"
        onClick={() => toggleSlideOver(true)}
      >
        <div>
          <Badge label={'Convertible Class'} color={'amber'} />
          <p className="mt-1 font-semibold leading-6 text-gray-700">{name}</p>
          <div className="grid grid-cols-2 gap-x-4 mt-2">
            {lineItem('Issue Date', dayjs(issueDate).format('DD MMMM YYYY'))}
            {lineItem('Issue Valuation Or Cap', formatCurrency(valuationOrCap))}
            {lineItem('Discount', formatNumber(discount))}
            {lineItem(
              'Latest Valuation or Cap',
              formatCurrency(latestValuationOrCap),
              valuationMovement > 0
                ? 'text-green-500'
                : valuationMovement < 0
                ? 'text-red-500'
                : 'text-gray-800'
            )}
            {lineItem(
              'Multiple',
              `${formatNumber(latestValuationOrCap / valuationOrCap)}x`,
              valuationMovement > 0
                ? 'text-green-500'
                : valuationMovement < 0
                ? 'text-red-500'
                : 'text-gray-800'
            )}
          </div>
        </div>
        {assets.length > 0 && (
          <ul className="mt-4 divide-y divide-gray-100 overflow-hidden bg-white shadow-sm ring-1 ring-gray-200 sm:rounded-xl">
            {assets.map(asset => (
              <ConvertibleAssetCard key={asset.id} asset={asset} onClick={() => {}} />
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default ConvertibleClassCard;
