import bankIcon from 'media/icons/bank.svg';
import companyIcon from 'media/icons/company.svg';
import dealIcon from 'media/icons/deal.svg';
import handIcon from 'media/icons/hand-select.svg';
import homeOutline from 'media/icons/home-outline.svg';
import postIcon from 'media/icons/post.svg';
import settingsIcon from 'media/icons/settings.svg';
import syndicateIcon from 'media/icons/syndicate.svg';
import teamIcon from 'media/icons/team.svg';

const adminTabs = [
  {
    key: 'home',
    label: 'Home',
    icon: homeOutline,
    route: '/',
    exact: false,
  },
  {
    key: 'deals',
    label: 'Deals',
    icon: dealIcon,
    route: '/deals',
  },
  {
    key: 'companies',
    label: 'Companies',
    icon: companyIcon,
    route: '/companies',
  },
  {
    key: 'syndicates',
    label: 'Syndicates',
    icon: syndicateIcon,
    route: '/syndicates',
  },
  {
    key: 'funds',
    label: 'Funds',
    icon: syndicateIcon,
    route: '/funds',
  },
  {
    key: 'users',
    label: 'Users',
    icon: teamIcon,
    route: '/users',
  },
  {
    key: 'investment-entities',
    label: 'Investment Entities',
    icon: bankIcon,
    route: '/investment-entities',
  },
  {
    key: 'deal-exits',
    label: 'Deal Exits',
    icon: bankIcon,
    route: '/deal-exits',
  },
  {
    key: 'approvals',
    label: 'Approvals',
    icon: handIcon,
    route: '/approvals',
  },
  {
    key: 'posts',
    label: 'Posts',
    icon: postIcon,
    route: '/posts',
  },
  {
    key: 'settings',
    label: 'Settings',
    icon: settingsIcon,
    route: '/settings',
  },
  {
    key: 'control-panel',
    label: 'Control Panel',
    icon: settingsIcon,
    route: '/control-panel',
  },
  {
    key: 'deal-digest',
    label: 'Deal Digest',
    icon: postIcon,
    route: '/deal-digest',
  },
];

const getSidebarTabs = () => {
  return adminTabs;
};

export default getSidebarTabs;
