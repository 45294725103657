import { FC, useEffect, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import Audit from './Audit';

const AuditsDialogButton: FC<{
  type: 'DEAL' | 'COMPANY' | 'SYNDICATE' | 'FUND' | 'INVESTMENT_ENTITY';
  audits: {
    nodes: {
      operation: 'CREATE' | 'UPDATE' | 'DELETE';
      changes: {
        fieldName: string;
        oldValue: string;
        newValue: string;
      }[];
      updatedAt: string;
      updatedBy: { name: string };
    }[];
    pageInfo: { totalCount: number; hasNextPage: boolean };
  };
  loading: boolean;
  refetch: any;
  fetchMore: any;
}> = ({ type, audits, loading, refetch, fetchMore }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    if (showDialog)
      refetch({
        page: 1,
        limit: 20,
      });
  }, [showDialog, refetch]);

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>View more</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Audit log</DialogTitle>
          <div className="flex flex-col space-y-4 overflow-y-scroll max-h-[70vh] my-6">
            {audits.nodes.map((audit, index) => (
              <Audit key={index} audit={audit} type={type} />
            ))}
            {audits.pageInfo.hasNextPage ? (
              <Button
                onClick={() => {
                  fetchMore({ variables: { page: page + 1, limit: 20 } });
                  setPage(page + 1);
                }}
              >
                Load more
              </Button>
            ) : null}
          </div>
          <DialogActions>
            <Button
              variant="secondary"
              loading={loading}
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
                toggleDialog(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default AuditsDialogButton;
