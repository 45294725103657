import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_USER_FROM_SYNDICATE_MUTATION = gql(`
  mutation RemoveUserFromSyndicate($syndicateUserId: ID!) {
    removeUserFromSyndicate(syndicateUserId: $syndicateUserId) {
      id
      syndicate {
        id
      }
    }
  }
`);

const RemoveUserFromSyndicateButton: FC<{
  syndicateUserId: string;
  onClose: () => void;
}> = ({ syndicateUserId, onClose }) => {
  const [removeUserFromSyndicate, { loading, error }] = useMutation(
    REMOVE_USER_FROM_SYNDICATE_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;
        const { removeUserFromSyndicate } = data;
        cache.modify({
          id: `SyndicateType:${removeUserFromSyndicate.syndicate.id}`,
          fields: {
            syndicateUsers(existingUsersRef, { readField }) {
              const updatedUsersRef = { ...existingUsersRef };
              updatedUsersRef.nodes = existingUsersRef.nodes.filter(
                userRef => syndicateUserId !== readField('id', userRef)
              );
              return updatedUsersRef;
            },
          },
        });
      },
      onCompleted() {
        onClose();
      },
    }
  );

  return (
    <ConfirmButton
      onConfirm={() => removeUserFromSyndicate({ variables: { syndicateUserId } })}
      loading={loading}
      error={error}
      description="This will delete this user from this syndicate."
    >
      Remove from Syndicate
    </ConfirmButton>
  );
};

export default RemoveUserFromSyndicateButton;
