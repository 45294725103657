import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterPanel from 'components/FilterPanel';

import debounce from 'utils/debounce';
import countryCodeToReadable from 'utils/enums/country-code-to-readable';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

import CreateCompanyDialogButton from './CreateCompanyDialogButton';

const COMPANIES_QUERY = gql(`
  query fetchCompanies($cursor: ID, $limit: Int, $filters: CompaniesFilterType) {
    companies(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        name
        legalName
        countryOfIncorporation
        status
        createdAt
      }
      pageInfo {
        hasNextPage
        cursor
        totalCount
      }
      statistics {
        companyStatisticsBreakdownByCompanyStatus {
          status
          numberOfCompanies
        }
        companyStatisticsBreakdownByCountryOfIncorporation {
          countryOfIncorporation
          numberOfCompanies
        }
      }
    }
  }
`);

const CompaniesPage: FC = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    COMPANIES_QUERY,
    {
      variables: {
        limit: 50,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const renderContent = () => {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const companies = data.companies.nodes;
    const pageInfo = data.companies.pageInfo;
    const statistics = data.companies.statistics;

    const filters = [
      {
        type: 'status',
        label: 'Status',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.companyStatisticsBreakdownByCompanyStatus.map(
            ({ status, numberOfCompanies }) => ({
              label: `${statusEnumToReadable(status).label} (${numberOfCompanies})`,
              value: status,
            })
          ),
        ],
        readable: statusEnumToReadable,
      },
      {
        type: 'countryOfIncorporation',
        label: 'Country of Incorporation',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.companyStatisticsBreakdownByCountryOfIncorporation.map(
            ({ countryOfIncorporation, numberOfCompanies }) => ({
              label: `${
                countryCodeToReadable(countryOfIncorporation).label
              } (${numberOfCompanies})`,
              value: countryOfIncorporation,
            })
          ),
        ],
        readable: countryCodeToReadable,
      },
    ];

    return (
      <>
        <FilterPanel activeFilters={variables?.filters} filters={filters} refetch={refetch} />
        <div className="flex items-center justify-between">
          <TextField
            placeholder="Search companies"
            onChange={debounce(e => {
              refetch({
                filters: {
                  ...variables?.filters,
                  text: e.target.value,
                },
              });
            }, 500)}
          />
          <CreateCompanyDialogButton />
        </div>
        <DataTable
          data={companies}
          onClick={company => navigate(`/companies/${company.id}`)}
          columns={[
            {
              label: 'Name',
              fieldName: 'name',
            },
            {
              label: 'Legal Name',
              fieldName: 'legalName',
            },
            {
              label: 'Country of Incorporation',
              fieldName: 'countryOfIncorporation',
              type: 'COUNTRY',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Created At',
              fieldName: 'createdAt',
              type: 'DATE',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          filterLoading={networkStatus === NetworkStatus.setVariables}
          paginationLoading={loading}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation title="Companies" subTitle="View and manage all the companies" />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default CompaniesPage;
