import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_CONVERTIBLE_VALUATION_CHANGE_MUTATION = gql(`
  mutation UpdateConvertibleValuationChange(
    $id: ID!
    $valuationMultiplier: Float!
    $effectiveFrom: String!
    $convertibleClassIds: [ID!]!
  ) {
    updateConvertibleValuationChange(
      id: $id
      valuationMultiplier: $valuationMultiplier
      effectiveFrom: $effectiveFrom
      convertibleClassIds: $convertibleClassIds
    ) {
      id
      valuationMultiplier
      effectiveFrom
      convertibleClasses {
        id
        name
      }
      updatedAt
    }
  }
`);

const UpdateConvertibleValuationChange: FC<{
  id: string;
  valuationMultiplier: number;
  effectiveFrom: string;
  convertibleClasses: { id: string; name: string }[];
  allConvertibleClasses: { id: string; name: string }[];
}> = ({ id, valuationMultiplier, effectiveFrom, convertibleClasses, allConvertibleClasses }) => {
  const [updateConvertibleValuationChange, { loading, error }] = useMutation(
    UPDATE_CONVERTIBLE_VALUATION_CHANGE_MUTATION
  );

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data =>
        updateConvertibleValuationChange({
          variables: {
            id,
            valuationMultiplier: data.valuationMultiplier,
            effectiveFrom: dayjs(data.effectiveFrom).format('YYYY-MM-DD'),
            convertibleClassIds: data.convertibleClassIds,
          },
        })
      }
    >
      <FormInput
        type="checkbox-group"
        fieldName="convertibleClassIds"
        label="Convertible Classes"
        defaultValue={convertibleClasses.map(({ id }) => id)}
        options={allConvertibleClasses.map(({ id, name }) => ({ value: id, label: name }))}
        validators={{ required: true }}
      />
      <FormInput
        type="number"
        fieldName="valuationMultiplier"
        label="Valuation Multiplier"
        defaultValue={valuationMultiplier}
        validators={{ required: true }}
      />
      <FormInput
        type="date"
        fieldName="effectiveFrom"
        label="Effective From"
        defaultValue={effectiveFrom}
        validators={{ required: true }}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateConvertibleValuationChange;
