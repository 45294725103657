import { useQuery } from '@apollo/client';
import { BuildingOffice2Icon, EyeIcon } from '@heroicons/react/20/solid';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { NavLink } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import assetIcon from 'media/icons/asset.svg';
import documentsIcon from 'media/icons/documents.svg';
import informationIcon from 'media/icons/information.svg';
import investmentIcon from 'media/icons/investment.svg';
import settingsIcon from 'media/icons/settings.svg';
import starIcon from 'media/icons/star.svg';

const DEAL_QUERY = gql(`
  query getDeal($id: ID!) {
    deal(id: $id) {
      id
      companyInformation {
        company {
          id
          name
        }
      }
    }
  }
`);

const DealPage: FC = () => {
  const navigate = useNavigate();
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const { loading, error, data, refetch } = useQuery(DEAL_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const deal = data.deal;
    const company = deal.companyInformation.company;

    const tabs = [
      {
        key: 'overview',
        label: 'Overview',
        description: 'Deal overview',
        icon: starIcon,
        route: `/deals/${dealId}`,
        exact: true,
      },
      {
        key: 'closings',
        label: 'Holdings',
        description: 'Deal closings',
        icon: investmentIcon,
        route: `/deals/${dealId}/closings`,
      },
      {
        key: 'invites',
        label: 'Invites',
        description: 'Deal invites',
        icon: investmentIcon,
        route: `/deals/${dealId}/invites`,
      },
      {
        key: 'information',
        label: 'Information',
        description: 'Deal information',
        icon: informationIcon,
        route: `/deals/${dealId}/information`,
      },
      {
        key: 'assets',
        label: 'Assets',
        description: 'Deal assets',
        icon: assetIcon,
        route: `/deals/${dealId}/assets`,
      },
      {
        key: 'documents',
        label: 'Documents',
        description: 'Deal documents',
        icon: documentsIcon,
        route: `/deals/${dealId}/documents`,
      },
      {
        key: 'admin',
        label: 'Admin',
        description: 'Deal admin',
        icon: settingsIcon,
        route: `/deals/${dealId}/admin`,
      },
    ];

    return (
      <>
        <SidebarLayoutNavigation
          title={company.name}
          subTitle="Deal"
          onBackButtonClick={() => navigate(-1)}
          actionButtons={[
            <NavLink
              type="button"
              className="relative inline-flex items-center rounded-l-md bg-white px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              title="Go to Company Page"
              to={`/companies/${company.id}`}
              target="_blank"
            >
              <span className="sr-only">Company</span>
              <BuildingOffice2Icon className="h-5 w-5" aria-hidden="true" />
            </NavLink>,
            <NavLink
              type="button"
              className="relative -ml-px inline-flex items-center rounded-r-md bg-white px-2 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-10"
              title="Show public deal page"
              target="_blank"
              to={`https://app.angellistindia.com/invest/deal/${dealId}`}
            >
              <span className="sr-only">Open Public Page</span>
              <EyeIcon className="h-5 w-5" aria-hidden="true" />
            </NavLink>,
          ]}
        />
        <SidebarLayoutContent secondaryTabs={tabs}>
          <Outlet />
        </SidebarLayoutContent>
      </>
    );
  };

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default DealPage;
