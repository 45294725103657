import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_FUND_MUTATION = gql(`
  mutation RemoveFund($id: ID!) {
    removeFund(id: $id) {
      id
    }
  }
`);

const RemoveFundButton: FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();

  const [removeFund, { loading, error }] = useMutation(REMOVE_FUND_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        fields: {
          funds(existingFundsRef, { readField }) {
            const updatedFundsRef = { ...existingFundsRef };
            updatedFundsRef.nodes = existingFundsRef.nodes.filter(
              fundRef => id !== readField('id', fundRef)
            );
            return updatedFundsRef;
          },
        },
      });
    },
    onCompleted() {
      navigate('/funds');
    },
  });

  return (
    <ConfirmButton
      onConfirm={() => removeFund({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will delete all data concerning this fund including any closings & investments."
    >
      Remove
    </ConfirmButton>
  );
};

export default RemoveFundButton;
