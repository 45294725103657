import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import EmptyState from 'primitives/EmptyState';
import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import UpdateKyc from './UpdateKyc';
import UpdateKycAddress from './UpdateKycAddress';
import UpsertKyc from './UpsertKyc';

const INVESTMENT_ENTITY_QUERY = gql(`
  query getInvestmentEntity($id: ID!) {
    investmentEntity(id: $id) {
      id
      kyc {
        id
        pan
        identityProofDocument {
          url
          name
        }
        addressProofDocument {
          url
          name
        }
        dateOfBirth
        address {
          lineOne
          lineTwo
          city
          state
          country
          pincode
        }
      }
    }
  }
`);

const InvestmentEntityKyc: FC = () => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch } = useQuery(INVESTMENT_ENTITY_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });
  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const investmentEntity = data.investmentEntity;
  const kyc = investmentEntity.kyc;
  const address = kyc?.address;

  if (!kyc || !address) {
    return (
      <EmptyState
        title="No KYC Information"
        description="This investment entity does not have KYC information. This could be because it pre-dates the new platform launch."
        actionButton={<UpsertKyc investmentEntityId={investmentEntity.id} />}
      />
    );
  }

  return (
    <>
      <UpdateKyc
        id={kyc.id}
        pan={kyc.pan}
        identityProofDocument={kyc.identityProofDocument}
        addressProofDocument={kyc.addressProofDocument}
        dateOfBirth={kyc.dateOfBirth}
      />
      <UpdateKycAddress
        id={kyc.id}
        lineOne={address.lineOne}
        lineTwo={address.lineTwo}
        city={address.city}
        state={address.state}
        country={address.country}
        pincode={address.pincode}
      />
    </>
  );
};

export default InvestmentEntityKyc;
