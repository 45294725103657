import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { IncorporationTypeEnumType } from '__generated__/graphql';
import dayjs from 'dayjs';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const CREATE_COMPANY_MUTATION = gql(`
  mutation CreateCompany(
    $name: String!
    $legalName: String!
    $countryOfIncorporation: CountryEnumType!
    $dateOfIncorporation: String!
    $description: String
    $incorporationType: IncorporationTypeEnumType!
    $cin: String
    $pan: String
    $website: String
    $image: Upload
  ) {
    createCompany(
      name: $name
      legalName: $legalName
      countryOfIncorporation: $countryOfIncorporation
      dateOfIncorporation: $dateOfIncorporation
      description: $description
      incorporationType: $incorporationType
      cin: $cin
      pan: $pan
      website: $website
      image: $image
    ) {
      id
      name
      legalName
      countryOfIncorporation
      dateOfIncorporation
      status
      description
      incorporationType
      cin
      pan
      website
      image
      createdAt
    }
  }
`);

const CreateCompanyDialogButton: FC = () => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [createCompany, { loading, error }] = useMutation(CREATE_COMPANY_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        fields: {
          companies(existingCompaniesRef) {
            const newCompanyNodeRef = cache.writeFragment({
              data: data.createCompany,
              fragment: gql(`
                fragment NewCompany on CompanyType {
                  id
                  name
                  image
                  legalName
                  countryOfIncorporation
                  status
                  createdAt
                }
              `),
            });
            const updatedCompaniesRef = { ...existingCompaniesRef };
            updatedCompaniesRef.nodes = [newCompanyNodeRef, ...updatedCompaniesRef.nodes];
            return updatedCompaniesRef;
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Create Company</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Create a Company</DialogTitle>
          <DialogSubTitle>Fill in the details below to create a new company</DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              createCompany({
                variables: {
                  name: data.name,
                  legalName: data.legalName,
                  countryOfIncorporation: data.countryOfIncorporation,
                  dateOfIncorporation: dayjs(data.dateOfIncorporation).format('YYYY-MM-DD'),
                  description: data.description,
                  incorporationType: data.incorporationType,
                  cin: data.cin,
                  pan: data.pan,
                  website: data.website,
                  image: data.image,
                },
              }).then(() => toggleDialog(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput
              type="text"
              fieldName="name"
              label="Name"
              defaultValue=""
              validators={{ required: true }}
            />
            <FormInput type="text" fieldName="legalName" label="Legal Name" defaultValue="" />
            <FormInput type="text" fieldName="description" label="Description" defaultValue="" />
            <FormInput
              type="select"
              fieldName="countryOfIncorporation"
              label="Country of Incorporation"
              defaultValue="IN"
              options={[
                { value: 'IN', label: 'India' },
                { value: 'US', label: 'United States' },
                { value: 'UK', label: 'United Kingdom' },
              ]}
              span="3"
            />
            <FormInput
              type="select"
              fieldName="incorporationType"
              label="Incorporation Type"
              options={[
                { value: IncorporationTypeEnumType.PrivateLimited, label: 'Private Limited' },
                { value: IncorporationTypeEnumType.Llc, label: 'LLC' },
                { value: IncorporationTypeEnumType.Other, label: 'Other' },
                { value: IncorporationTypeEnumType.NotSure, label: 'Not Sure' },
              ]}
              defaultValue={IncorporationTypeEnumType.PrivateLimited}
              span="3"
            />
            <FormInput
              type="date"
              fieldName="dateOfIncorporation"
              label="Date of Incorporation"
              defaultValue=""
            />
            <FormInput type="text" fieldName="cin" label="CIN" defaultValue="" span="3" />
            <FormInput type="text" fieldName="pan" label="PAN" defaultValue="" span="3" />
            <FormInput type="text" fieldName="website" label="Website" defaultValue="" />
            <FormInput
              fullWidth
              type="upload"
              fieldName="image"
              label="logo"
              defaultValue=""
              validators={{
                required: true,
              }}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateCompanyDialogButton;
