import { useQuery } from '@apollo/client';
import { FC } from 'react';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import Audit from './Audit';
import AuditsDialogButton from './AuditsDialogButton';
import { getQuery, getQueryPath } from './utils/getQueries';

const AuditsPreview: FC<{
  id: string;
  type: 'DEAL' | 'COMPANY' | 'SYNDICATE' | 'FUND' | 'INVESTMENT_ENTITY';
}> = ({ id, type }) => {
  const { data, error, loading, refetch, fetchMore } = useQuery(getQuery(type), {
    variables: { id, page: 1, limit: 2 },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const audits = data[getQueryPath(type)].audits;

  return (
    <div className="border border-gray-300 rounded-md p-4">
      <h2 className="font-bold">Recent updates</h2>
      <div className="flex flex-col space-y-4 overflow-y-scroll max-h-[70vh] my-6">
        {audits.nodes.map((audit, index) => (
          <Audit key={index} audit={audit} type={type} />
        ))}
      </div>
      <AuditsDialogButton
        type={type}
        audits={audits}
        loading={loading}
        refetch={refetch}
        fetchMore={fetchMore}
      />
    </div>
  );
};

export default AuditsPreview;
