import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { Dialog, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';
import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import FundClosingAudit from './FundClosingAudit';
import FundClosingEmailAudit from './FundClosingEmailAudit';
import RemoveFundClosingButton from './RemoveFundClosingButton';
import UpdateFundClosing from './UpdateFundClosing';
import UpdateFundClosingStatus from './UpdateFundClosingStatus';

const FUND_CLOSING_QUERY = gql(`
  query getFundClosing($id: ID!) {
    fundClosing(id: $id) {
      id
      amount
      fundingSource
      investmentEntity {
        id
        name
      }
      status
      wiredAt
      executedAt
      finalisedAt
      createdAt
      updatedAt
    }
  }
`);

const FundClosingDialog: FC<{
  fundClosingId: string;
  onClose: () => void;
}> = ({ fundClosingId, onClose }) => {
  const { loading, error, data, refetch } = useQuery(FUND_CLOSING_QUERY, {
    variables: {
      id: fundClosingId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const fundClosing = data.fundClosing;

    return (
      <>
        <UpdateFundClosing
          id={fundClosing.id}
          amount={fundClosing.amount}
          fundingSource={fundClosing.fundingSource}
          investmentEntity={fundClosing.investmentEntity}
        />

        <UpdateFundClosingStatus
          id={fundClosing.id}
          status={fundClosing.status}
          wiredAt={fundClosing.wiredAt}
          executedAt={fundClosing.executedAt}
          finalisedAt={fundClosing.finalisedAt}
        />

        {fundClosing.status !== 'ACTIVE' ? (
          <>
            <RemoveFundClosingButton id={fundClosing.id} onClose={onClose} />
          </>
        ) : null}

        <FundClosingEmailAudit fundClosingId={fundClosing.id} />

        <FundClosingAudit fundClosingId={fundClosing.id} />
      </>
    );
  };

  return (
    <Dialog open={true} onClose={onClose}>
      <DialogPanel>
        <DialogTitle>Fund Commitment Details</DialogTitle>
        <DialogSubTitle>View and manage the details of this fund commitment</DialogSubTitle>
        {renderContent()}
      </DialogPanel>
    </Dialog>
  );
};

export default FundClosingDialog;
