import { CheckIcon } from '@heroicons/react/20/solid';
import { DealStatusEnumType } from '__generated__/graphql';
import classNames from 'classnames';
import { FC, useState } from 'react';

import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

import UpdateDealStatusDialog from './UpdateDealStatusDialog';

const DealStatusPanel: FC<{
  dealId: string;
  status: DealStatusEnumType;
  numberOfShares?: number | null;
}> = ({ dealId, status }) => {
  const [statusToEnable, setStatusToEnable] = useState<DealStatusEnumType | null>(null);

  const statuses = [
    DealStatusEnumType.Draft,
    DealStatusEnumType.UnderOpsReview,
    DealStatusEnumType.Raising,
    DealStatusEnumType.Pooling,
    DealStatusEnumType.Funded,
    DealStatusEnumType.Wired,
    DealStatusEnumType.Exited,
  ];

  const stepsFromStatus = statuses.map(s => ({
    key: s,
    name: statusEnumToReadable(s).label,
    status:
      s === status
        ? 'current'
        : statuses.indexOf(s) < statuses.indexOf(status)
        ? 'complete'
        : 'upcoming',
  }));

  if ([DealStatusEnumType.Deleted, DealStatusEnumType.Cancelled].includes(status)) {
    return null;
  }
  if (status === DealStatusEnumType.Paused) {
    return (
      <>
        {statusToEnable && (
          <UpdateDealStatusDialog
            open={true}
            dealId={dealId}
            status={statusToEnable}
            onClose={() => setStatusToEnable(null)}
          />
        )}
        <div className="flex justify-center border border-gray-200 p-4 pb-14 rounded-lg relative">
          <button
            className="absolute left-10 text-xs p-2 rounded-lg text-gray-600 underline"
            onClick={() => setStatusToEnable(DealStatusEnumType.Raising)}
          >
            Resume To Raising
          </button>
          <button
            className="absolute left-40 text-xs p-2 rounded-lg text-gray-600 underline"
            onClick={() => setStatusToEnable(DealStatusEnumType.Pooling)}
          >
            Resume To Pooling
          </button>
        </div>
      </>
    );
  }

  return (
    <>
      {statusToEnable && (
        <UpdateDealStatusDialog
          open={true}
          dealId={dealId}
          status={statusToEnable}
          onClose={() => setStatusToEnable(null)}
        />
      )}
      <div className="flex justify-center border border-gray-200 p-4 pb-14 rounded-lg relative">
        <nav aria-label="Progress">
          <ol className="flex items-center">
            {stepsFromStatus.map((step, stepIdx) => (
              <li
                key={step.name}
                className={classNames(
                  stepIdx !== stepsFromStatus.length - 1 ? 'pr-8 sm:pr-20' : '',
                  'relative'
                )}
              >
                <div className="absolute inset-0 flex items-center">
                  <div
                    className={classNames(
                      'h-0.5 w-full',
                      step.status === 'complete' ? 'bg-indigo-600' : 'bg-gray-200'
                    )}
                  />
                </div>
                <span className="absolute text-sm top-9 -left-4 w-16 text-center">{step.name}</span>
                <button
                  onClick={() => setStatusToEnable(step.key as DealStatusEnumType)}
                  className={classNames(
                    'relative flex h-8 w-8 items-center justify-center rounded-full',
                    {
                      'bg-indigo-600 hover:bg-indigo-900 pointer-events-none':
                        step.status === 'complete' ||
                        step.status === 'current' ||
                        step.key === DealStatusEnumType.Exited,
                      'group border-2 border-gray-300 bg-white hover:border-gray-400':
                        step.status === 'upcoming',
                    }
                  )}
                >
                  {step.status === 'complete' || step.status === 'current' ? (
                    <CheckIcon className="h-5 w-5 text-white" />
                  ) : (
                    <span
                      className={classNames(
                        'h-2.5 w-2.5 rounded-full',
                        step.status === 'current'
                          ? 'bg-indigo-600'
                          : 'bg-transparent group-hover:bg-gray-300'
                      )}
                    />
                  )}
                </button>
              </li>
            ))}
          </ol>
        </nav>
        <button
          className="absolute bottom-0 right-0 text-xs p-2 rounded-lg text-gray-600 underline"
          onClick={() => setStatusToEnable(DealStatusEnumType.Cancelled)}
        >
          Cancel Deal
        </button>
        <button
          className="absolute bottom-0 right-20 text-xs p-2 rounded-lg text-gray-600 underline"
          onClick={() => setStatusToEnable(DealStatusEnumType.Paused)}
        >
          Pause Deal
        </button>
      </div>
    </>
  );
};

export default DealStatusPanel;
