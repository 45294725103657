import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

import SearchUsers from './SearchUsers';

const ADD_USER_TO_SYNDICATE_MUTATION = gql(`
  mutation AddLeadToSyndicate(
    $syndicateId: String!
    $userId: String!
    $role: SyndicateLeadRoleEnumType!
  ) {
    addLeadToSyndicate(syndicateId: $syndicateId, userId: $userId, role: $role) {
      id
      user {
        id
        name
        email
      }
      role
      createdAt
    }
  }
`);

const AddLeadToSyndicateDialogButton: FC<{ syndicateId: string }> = ({ syndicateId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [addLeadToSyndicate, { loading, error }] = useMutation(ADD_USER_TO_SYNDICATE_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      const { addLeadToSyndicate } = data;
      cache.modify({
        id: `SyndicateType:${syndicateId}`,
        fields: {
          syndicateLeads(existingLeadsRef = []) {
            const newLeadRef = cache.writeFragment({
              data: { ...addLeadToSyndicate },
              fragment: gql(`
                fragment NewLeadToSyndicate on SyndicateLeadType {
                  id
                  user {
                    id
                    name
                    email
                  }
                  createdAt
                }
              `),
            });

            return [...existingLeadsRef, newLeadRef];
          },
        },
      });
    },
    onCompleted() {
      toggleDialog(false);
    },
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Add Lead</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Add a lead</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              addLeadToSyndicate({
                variables: {
                  syndicateId,
                  userId: data.user.id,
                  role: data.role,
                },
              });
            }}
            buttonRef={buttonRef}
          >
            <FormInput
              type="select"
              fieldName="role"
              label="Role"
              defaultValue={'ADMIN'}
              options={[
                { label: 'Admin', value: 'ADMIN' },
                { label: 'Owner', value: 'OWNER' },
              ]}
            />
            <FormInput
              type="custom"
              fieldName="user"
              label="User"
              defaultValue={''}
              customInput={SearchUsers}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default AddLeadToSyndicateDialogButton;
