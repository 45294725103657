import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import UpdateCompany from 'routes/Company/Information/UpdateCompany';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';
import MultiSelectFormInput from 'components/MultiSelect/MultiSelectFormInput';

import { industryOptions } from './constants';

const COMPANY_INFORMATION_QUERY = gql(`
  query CompanyInformation($id: ID!) {
    deal(id: $id) {
      id
      companyInformation {
        id
        company {
          id
          name
          legalName
          countryOfIncorporation
          dateOfIncorporation
          incorporationType
          description
          cin
          pan
          website
        }
        stage
        monthsOfRunway
        industries
      }
    }
  }
`);

const UPDATE_COMPANY_INFORMATION_MUTATION = gql(`
  mutation UpdateCompanyInformation(
    $dealId: ID!
    $stage: DealCompanyStageEnumType
    $monthsOfRunway: MonthsOfRunwayEnumType
    $industries: [IndustryTypeEnumType]
  ) {
    updateCompanyInformation(
      id: $dealId
      stage: $stage
      monthsOfRunway: $monthsOfRunway
      industries: $industries
    ) {
      id
      companyInformation {
        id
        stage
        monthsOfRunway
        industries
      }
    }
  }
`);

const DealCompanyInformation: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const { loading, error, data, refetch } = useQuery(COMPANY_INFORMATION_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const [updateCompanyInformation, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_COMPANY_INFORMATION_MUTATION
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data || !dealId) return <ErrorMessage error={error} refetch={refetch} />;

  const company = data.deal.companyInformation.company;

  return (
    <>
      <FormPanelWithReadMode
        loading={updateLoading}
        error={updateError}
        onSubmit={data =>
          updateCompanyInformation({
            variables: {
              dealId,
              stage: data.stage,
              monthsOfRunway: data.monthsOfRunway,
              industries: data.industries,
            },
          })
        }
        title="Specific to this deal"
      >
        <FormInput
          type="select"
          fieldName="stage"
          label="Company Stage"
          defaultValue={data.deal.companyInformation.stage || ''}
          options={[
            { label: 'Pre Launch', value: 'PRE_LAUNCH' },
            { label: 'Pre Revenue', value: 'PRE_REVENUE' },
            { label: 'Post Revenue', value: 'POST_REVENUE' },
            { label: 'Profitable', value: 'PROFITABLE' },
          ]}
        />
        <FormInput
          type="select"
          fieldName="monthsOfRunway"
          label="Months of runway after this round"
          defaultValue={data.deal.companyInformation.monthsOfRunway || ''}
          options={[
            { label: 'Fewer than 12 months', value: 'FEWER_THAN_12' },
            { label: 'More than 12 months', value: 'MORE_THAN_12' },
            { label: 'Not specified', value: 'NOT_SPECIFIED' },
          ]}
        />
        <FormInput
          fullWidth
          type="custom"
          fieldName="industries"
          customInput={MultiSelectFormInput}
          label="Select the investment industry of the deal"
          helperText="You can select multiple investment industries if necessary"
          defaultValue={data.deal.companyInformation.industries || []}
          options={industryOptions}
        />
      </FormPanelWithReadMode>
      <UpdateCompany
        id={company.id}
        name={company.name}
        legalName={company.legalName}
        countryOfIncorporation={company.countryOfIncorporation}
        dateOfIncorporation={company.dateOfIncorporation}
        description={company.description}
        cin={company.cin}
        pan={company.pan}
        website={company.website}
      />
    </>
  );
};

export default DealCompanyInformation;
