import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { UserTypeEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';
import TextField from 'primitives/TextField';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterPanel from 'components/FilterPanel';

import debounce from 'utils/debounce';
import statusEnumToReadable from 'utils/enums/status-enum-to-readable';
import userRoleToReadable from 'utils/enums/user-role-to-readable';

const USERS_QUERY = gql(`
  query getUsers($cursor: ID, $limit: Int, $filters: UsersFilterType) {
    users(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        role
        name
        email
        createdAt
        status
      }
      pageInfo {
        hasNextPage
        cursor
      }
      statistics {
        userStatisticsBreakdownByUserStatus {
          status
          numberOfUsers
        }
        userStatisticsBreakdownByUserRole {
          role
          numberOfUsers
        }
      }
    }
  }
`);

const UsersPage: FC = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    USERS_QUERY,
    {
      variables: {
        limit: 50,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const renderContent = () => {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const users = data.users.nodes;
    const pageInfo = data.users.pageInfo;
    const statistics = data.users.statistics;

    const filters = [
      {
        type: 'status',
        label: 'Status',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.userStatisticsBreakdownByUserStatus.map(({ status, numberOfUsers }) => ({
            label: `${statusEnumToReadable(status).label} (${numberOfUsers})`,
            value: status,
          })),
        ],
        readable: statusEnumToReadable,
      },
      {
        type: 'role',
        label: 'Role',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.userStatisticsBreakdownByUserRole.map(({ role, numberOfUsers }) => ({
            label: `${userRoleToReadable(role).label} (${numberOfUsers})`,
            value: role,
          })),
        ],
        readable: userRoleToReadable,
      },
      {
        type: 'type',
        label: 'Type',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          {
            label: 'User',
            value: UserTypeEnumType.User,
          },
          {
            label: 'Created for Fund',
            value: UserTypeEnumType.Fund,
          },
        ],
        readable: (type: UserTypeEnumType) => ({
          label: type === UserTypeEnumType.User ? 'User' : 'Created for Fund',
          color: '#000',
        }),
      },
    ];

    return (
      <>
        <FilterPanel activeFilters={variables?.filters} filters={filters} refetch={refetch} />
        <div className="flex items-center justify-between">
          <TextField
            placeholder="Search users"
            onChange={debounce(e => {
              refetch({
                filters: {
                  ...variables?.filters,
                  text: e.target.value,
                },
              });
            }, 500)}
          />
        </div>
        <DataTable
          data={users}
          onClick={user => navigate(`/users/${user.id}`)}
          columns={[
            {
              label: 'Name',
              fieldName: 'name',
            },
            {
              label: 'Email',
              fieldName: 'email',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Role',
              fieldName: 'role',
            },
            {
              label: 'Created At',
              fieldName: 'createdAt',
              type: 'DATETIME',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          filterLoading={networkStatus === NetworkStatus.setVariables}
          paginationLoading={loading}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation title="Users" subTitle="View and manage all the users" />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default UsersPage;
