import { DealFlowTypeEnumType } from '__generated__/graphql';

export default function dealFlowToReadable(dealFlow: DealFlowTypeEnumType) {
  switch (dealFlow) {
    case DealFlowTypeEnumType.High:
      return {
        label: 'High',
        color: 'green',
      };
    case DealFlowTypeEnumType.Medium:
      return {
        label: 'Medium',
        color: 'indigo',
      };
    case DealFlowTypeEnumType.Low:
      return {
        label: 'Low',
        color: 'amber',
      };
    default:
      return {
        label: dealFlow,
        color: 'gray',
      };
  }
}
