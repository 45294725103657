import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  ConvertibleAssetStatusEnumType,
  ConvertibleAssetTypeEnumType,
} from '__generated__/graphql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const CREATE_SHARE_ASSET_MUTATION = gql(`
  mutation CreateConvertibleAsset(
    $convertibleClassId: ID!
    $dealId: ID!
    $type: ConvertibleAssetTypeEnumType!
    $value: Float!
    $maturityDate: String
    $interestRate: Float
    $status: ConvertibleAssetStatusEnumType!
  ) {
    createConvertibleAsset(
      convertibleClassId: $convertibleClassId
      dealId: $dealId
      type: $type
      value: $value
      maturityDate: $maturityDate
      interestRate: $interestRate
      status: $status
    ) {
      id
      type
      value
      maturityDate
      interestRate
      status
      createdAt
    }
  }
`);

const CreateConvertibleAssetDialogButton: FC<{
  convertibleClasses: {
    id: string;
    name: string;
  }[];
  dealId: string;
}> = ({ convertibleClasses, dealId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [createConvertibleAsset, { loading, error }] = useMutation(CREATE_SHARE_ASSET_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          id: dealId,
          __typename: 'DealType',
        }),
        fields: {
          convertibleAssets(existingConvertibleAssetsRef) {
            const newConvertibleAssetNodeRef = cache.writeFragment({
              data: data.createConvertibleAsset,
              fragment: gql(`
                fragment NewConvertibleAsset on ConvertibleAssetType {
                  id
                  type
                  value
                  maturityDate
                  interestRate
                  status
                  createdAt
                }
              `),
            });

            return [...existingConvertibleAssetsRef, newConvertibleAssetNodeRef];
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Create Convertible Asset</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Create Convertible Asset</DialogTitle>
          <DialogSubTitle>
            Fill in the details below to create a new convertible class
          </DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              createConvertibleAsset({
                variables: {
                  dealId,
                  convertibleClassId: data.convertibleClassId,
                  type: data.type,
                  value: data.value,
                  maturityDate: data.maturityDate,
                  interestRate: data.interestRate,
                  status: data.status,
                },
              }).then(() => toggleDialog(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput
              type="select"
              fieldName="convertibleClassId"
              label="Convertible Class"
              defaultValue=""
              validators={{ required: true }}
              options={convertibleClasses.map(convertibleClass => ({
                value: convertibleClass.id,
                label: convertibleClass.name,
              }))}
              helperText='To create a new convertible class, go to the "Company" page'
            />
            <FormInput
              type="select"
              fieldName="type"
              label="Type"
              defaultValue=""
              validators={{ required: true }}
              options={[
                {
                  label: 'Convertible Equity',
                  value: ConvertibleAssetTypeEnumType.ConvertibleEquity,
                },
                { label: 'Convertible Note', value: ConvertibleAssetTypeEnumType.ConvertibleNote },
              ]}
            />
            <FormInput
              type="currency"
              fieldName="value"
              label="Value"
              defaultValue=""
              validators={{ required: true }}
            />
            <FormInput
              type="date"
              fieldName="maturityDate"
              label="Maturity Date"
              defaultValue=""
              validators={{ required: true }}
            />
            <FormInput
              type="number"
              fieldName="interestRate"
              label="Interest Rate"
              defaultValue=""
              validators={{ required: true }}
            />
            <FormInput
              type="select"
              fieldName="status"
              label="Status"
              defaultValue={ConvertibleAssetStatusEnumType.UnderReview}
              validators={{ required: true }}
              options={[
                { label: 'Active', value: ConvertibleAssetStatusEnumType.Active },
                { label: 'Inactive', value: ConvertibleAssetStatusEnumType.Inactive },
                { label: 'Converted', value: ConvertibleAssetStatusEnumType.Converted },
                { label: 'Under Review', value: ConvertibleAssetStatusEnumType.UnderReview },
              ]}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateConvertibleAssetDialogButton;
