import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterPanel from 'components/FilterPanel';

import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

const FUNDS_QUERY = gql(`
  query GetFunds($cursor: ID, $limit: Int, $filters: FundsFilterType) {
    funds(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        name
        createdAt
        status
      }
      pageInfo {
        hasNextPage
        cursor
      }
      statistics {
        fundStatisticsBreakdownByFundStatus {
          status
          numberOfFunds
        }
      }
    }
  }
`);

const FundsPage: FC = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore, variables, networkStatus } = useQuery(
    FUNDS_QUERY,
    {
      variables: {
        limit: 50,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const renderContent = () => {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const funds = data.funds.nodes;
    const pageInfo = data.funds.pageInfo;
    const statistics = data.funds.statistics;

    const filters = [
      {
        type: 'status',
        label: 'Status',
        options: [
          {
            label: 'All',
            value: undefined,
          },
          ...statistics.fundStatisticsBreakdownByFundStatus.map(({ status, numberOfFunds }) => ({
            label: `${statusEnumToReadable(status).label} (${numberOfFunds})`,
            value: status,
          })),
        ],
        readable: statusEnumToReadable,
      },
    ];

    return (
      <>
        <FilterPanel activeFilters={variables?.filters} filters={filters} refetch={refetch} />
        <DataTable
          data={funds}
          searchFields={['name']}
          onClick={fund => navigate(`/funds/${fund.id}`)}
          columns={[
            {
              label: 'Name',
              fieldName: 'name',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Created At',
              fieldName: 'createdAt',
              type: 'DATETIME',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          filterLoading={networkStatus === NetworkStatus.setVariables}
          paginationLoading={loading}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation title="Funds" subTitle="View and manage all the funds" />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default FundsPage;
