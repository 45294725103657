import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_CONVERTIBLE_ASSET_MUTATION = gql(`
  mutation RemoveConvertibleAsset($id: ID!) {
    removeConvertibleAsset(id: $id) {
      id
    }
  }
`);

const RemoveConvertibleAssetButton: FC<{ id: string; dealId: string; onRemove?: () => void }> = ({
  id,
  dealId,
  onRemove,
}) => {
  const [removeConvertibleAsset, { loading, error }] = useMutation(
    REMOVE_CONVERTIBLE_ASSET_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          id: cache.identify({
            id: dealId,
            __typename: 'DealType',
          }),
          fields: {
            convertibleAssets(existingConvertibleAssetsRef, { readField }) {
              return existingConvertibleAssetsRef.filter(
                convertibleAssetRef => id !== readField('id', convertibleAssetRef)
              );
            },
          },
        });
      },
      onCompleted() {
        if (onRemove) onRemove();
      },
    }
  );

  return (
    <ConfirmButton
      variant="secondary"
      onConfirm={() => removeConvertibleAsset({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will delete all data concerning this Convertible Asset."
    >
      Remove
    </ConfirmButton>
  );
};

export default RemoveConvertibleAssetButton;
