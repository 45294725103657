import { CountryEnumType } from '__generated__/graphql';

export default function countryCodeToReadable(code: CountryEnumType): {
  label: string;
  color: string;
} {
  switch (code) {
    case CountryEnumType.In:
      return {
        label: 'India',
        color: 'gray',
      };
    case CountryEnumType.Us:
      return {
        label: 'United States',
        color: 'gray',
      };
    case CountryEnumType.Sg:
      return {
        label: 'Singapore',
        color: 'gray',
      };
    default:
      return {
        label: code,
        color: 'gray',
      };
  }
}
