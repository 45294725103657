import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { UserRoleEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_DEAL_MANAGER_MUTATION = gql(`
  mutation UpdateDealManager($id: ID!, $userId: ID!) {
    updateDealManager(id: $id, userId: $userId) {
      id
      manager {
        id
        name
      }
    }
  }
`);

const GET_OPS_USERS_QUERY = gql(`
  query OpsUsers($cursor: ID, $limit: Int, $filters: UsersFilterType) {
    users(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        name
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`);

const UpdateDealManager: FC<{
  id: string;
  manager: { name?: string; id?: string };
}> = ({ id, manager }) => {
  const { data, loading, error } = useQuery(GET_OPS_USERS_QUERY, {
    variables: {
      filters: {
        role: UserRoleEnumType.OperationsHandler,
      },
    },
  });

  const [updateDealManager] = useMutation(UPDATE_DEAL_MANAGER_MUTATION);

  if (!data || !data.users || !data.users.nodes) return <p>No handlers found</p>;

  const users = data?.users?.nodes;

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateDealManager({
          variables: {
            id,
            userId: data.adminOwner,
          },
        });
      }}
      title="Admin Owner"
    >
      <FormInput
        type="select"
        fieldName="adminOwner"
        label="Admin Owner"
        defaultValue={manager.id || ''}
        options={users?.map(user => {
          return { label: user.name, value: user.id };
        })}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateDealManager;
