import { NetworkStatus, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import CreatePostDialogButton from './CreatePostDialogButton';

const POSTS_QUERY = gql(`
  query fetchPosts($cursor: ID, $limit: Int) {
    posts(cursor: $cursor, limit: $limit) {
      nodes {
        id
        title
        status
        message
        createdAt
        updatedAt
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`);

const PostsPage: FC = () => {
  const navigate = useNavigate();
  const { loading, error, data, refetch, fetchMore, networkStatus } = useQuery(POSTS_QUERY, {
    variables: {
      limit: 50,
    },
    notifyOnNetworkStatusChange: true,
  });

  const renderContent = () => {
    if (loading && !data) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const posts = data.posts.nodes;
    const modifiedPosts = posts.map(post => ({
      ...post,
      message: post.message.length > 100 ? post.message.substring(0, 100) + '...' : post.message,
    }));

    const pageInfo = data.posts.pageInfo;

    return (
      <>
        <div>
          <CreatePostDialogButton />
        </div>
        <DataTable
          data={modifiedPosts}
          searchFields={['title']}
          onClick={post => navigate(`/posts/${post.id}`)}
          columns={[
            {
              label: 'Title',
              fieldName: 'title',
            },
            {
              label: 'Message',
              fieldName: 'message',
              type: 'HTML',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Created At',
              fieldName: 'createdAt',
              type: 'DATETIME',
            },
            {
              label: 'Updated At',
              fieldName: 'updatedAt',
              type: 'DATETIME',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          filterLoading={networkStatus === NetworkStatus.setVariables}
          paginationLoading={loading}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation title="Posts" subTitle="Manage the various posts being sent" />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default PostsPage;
