import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

const DEALS_QUERY = gql(`
  query fetchDealsBySyndicate($syndicateId: ID!, $cursor: ID, $limit: Int) {
    syndicate(id: $syndicateId) {
      id
      deals(cursor: $cursor, limit: $limit) {
        nodes {
          id
          companyInformation {
            company {
              name
            }
          }
          type
          allocation
          closingDate
          status
          createdAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
    }
  }
`);

const SyndicateDeals: FC = () => {
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore } = useQuery(DEALS_QUERY, {
    variables: {
      limit: 50,
      syndicateId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const syndicate = data.syndicate;
  const deals = syndicate.deals.nodes;
  const pageInfo = syndicate.deals.pageInfo;

  return (
    <DataTable
      data={deals}
      searchFields={['companyInformation.company.name']}
      onClick={deal => navigate(`/deals/${deal.id}`)}
      columns={[
        {
          label: 'Company',
          fieldName: 'companyInformation.company.name',
        },
        {
          label: 'Status',
          fieldName: 'status',
          type: 'STATUS',
        },
        {
          label: 'Type',
          fieldName: 'type',
        },
        {
          label: 'Allocation',
          fieldName: 'allocation',
        },
        {
          label: 'Closing Date',
          fieldName: 'closingDate',
          type: 'DATETIME',
        },
        {
          label: 'Created At',
          fieldName: 'createdAt',
          type: 'DATETIME',
        },
      ]}
      hasNextPage={pageInfo.hasNextPage}
      onLoadMore={() =>
        fetchMore({
          variables: {
            cursor: pageInfo.cursor,
          },
        })
      }
    />
  );
};

export default SyndicateDeals;
