import { useQuery } from '@apollo/client';
import { gql } from '__generated__';
import { Navigate, useLocation } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import { isAuthenticated } from 'utils/auth';

import ErrorMessage from './ErrorMessage';

const ADMIN_DASHBOARD_QUERY = gql(`
  query AdminDashboard {
    adminDashboard {
      id
    }
  }
`);

export default function ProtectedRoute({ children }: { children: JSX.Element }) {
  let location = useLocation();
  const { data, loading, error, refetch } = useQuery(ADMIN_DASHBOARD_QUERY);

  if (!isAuthenticated()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  if (!data.adminDashboard.id && window.location.pathname !== '/unauthorized') {
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  return children;
}
