import { FC } from 'react';
import { Outlet, useParams } from 'react-router-dom';

import VerticalTabs from 'components/VerticalTabs';

import companyIcon from 'media/icons/company.svg';
import informationIcon from 'media/icons/information.svg';
import networkIcon from 'media/icons/network.svg';
import risksIcon from 'media/icons/risks.svg';

const DealInformation: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const tabs = [
    {
      key: 'information',
      label: 'Basic Information',
      description: 'Deal information',
      icon: informationIcon,
      route: `/deals/${dealId}/information`,
      exact: true,
    },
    {
      key: 'other-investors',
      label: 'Co-Investors',
      description: 'Other investors in the deal',
      icon: networkIcon,
      route: `/deals/${dealId}/information/other-investors`,
    },
    {
      key: 'company-information',
      label: 'Company Information',
      description: 'Company information',
      icon: companyIcon,
      route: `/deals/${dealId}/information/company-information`,
    },
    {
      key: 'memo',
      label: 'Memo',
      description: 'Deal memo',
      icon: networkIcon,
      route: `/deals/${dealId}/information/memo`,
    },
    {
      key: 'risks-and-disclosures',
      label: 'Risks and Disclosures',
      description: 'Deal risks and disclosures',
      icon: risksIcon,
      route: `/deals/${dealId}/information/risks-and-disclosures`,
    },
    {
      key: 'carry-gp-commitment',
      label: 'Carry GP Commitment',
      description: 'Carry GP commitment',
      icon: companyIcon,
      route: `/deals/${dealId}/information/carry-gp-commitment`,
    },
  ];

  return (
    <div className="relative grid grid-cols-8 gap-x-3">
      <div className="col-span-2 sticky top-4 left-0">
        <VerticalTabs tabs={tabs} />
      </div>
      <div className="col-span-6 flex flex-col gap-y-3">
        <Outlet />
      </div>
    </div>
  );
};

export default DealInformation;
