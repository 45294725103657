import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FundStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

const FUNDS_QUERY = gql(`
  query fetchFundsForApproval($cursor: ID, $limit: Int, $filters: FundsFilterType) {
    funds(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        name
        status
        createdAt
        updatedAt
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`);

const ApproveFundsPage: FC = () => {
  const navigate = useNavigate();

  const { loading, error, data, refetch, fetchMore } = useQuery(FUNDS_QUERY, {
    variables: {
      limit: 50,
      filters: {
        status: FundStatusEnumType.UnderReview,
      },
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
  });

  if (loading && !data) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const funds = data.funds.nodes;
  const pageInfo = data.funds.pageInfo;

  return (
    <DataTable
      data={funds}
      searchFields={['name']}
      onClick={fund => navigate(`/funds/${fund.id}`)}
      columns={[
        {
          label: 'Name',
          fieldName: 'name',
        },
        {
          label: 'Status',
          fieldName: 'status',
          type: 'STATUS',
        },
        {
          label: 'Created At',
          fieldName: 'createdAt',
          type: 'DATETIME',
        },
        {
          label: 'Updated At',
          fieldName: 'updatedAt',
          type: 'DATETIME',
        },
      ]}
      hasNextPage={pageInfo.hasNextPage}
      paginationLoading={loading}
      onLoadMore={() =>
        fetchMore({
          variables: {
            cursor: pageInfo.cursor,
          },
        })
      }
    />
  );
};

export default ApproveFundsPage;
