import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';
import FilterPanel from 'components/FilterPanel';

import statusEnumToReadable from 'utils/enums/status-enum-to-readable';

import DealInviteSlideOver from './DealInviteSlideOver';

const DEAL_INVITES_QUERY = gql(`
  query fetchDealInvitesByDeal($dealId: ID!, $cursor: ID, $limit: Int, $filters: DealInvitesFilterType) {
    deal(id: $dealId) {
      id
      invites(cursor: $cursor, limit: $limit, filters: $filters) {
        nodes {
          id
          user {
            id
            name
          }
          status
          note
          minimumInvestmentAmount
          maximumInvestmentAmount
          createdAt
          updatedAt
        }
        pageInfo {
          hasNextPage
          cursor
        }
      }
      statistics {
        dealInviteStatisticsBreakdownByDealInviteStatus {
          status
          numberOfInvites
        }
      }
    }
  }
`);

const DealInvites: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };
  const [dealInviteOnDisplay, setDealInviteOnDisplay] = useState('');

  const { loading, error, data, refetch, fetchMore, variables } = useQuery(DEAL_INVITES_QUERY, {
    variables: {
      limit: 50,
      dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const deal = data.deal;
  const dealInvites = deal.invites.nodes;
  const pageInfo = deal.invites.pageInfo;
  const statistics = deal.statistics;

  const filters = [
    {
      type: 'type',
      label: 'Status',
      options: [
        {
          label: 'All',
          value: undefined,
        },
        ...statistics.dealInviteStatisticsBreakdownByDealInviteStatus.map(
          ({ status, numberOfInvites }) => ({
            label: `${statusEnumToReadable(status).label} (${numberOfInvites})`,
            value: status,
          })
        ),
      ],
      readable: statusEnumToReadable,
    },
  ];

  return (
    <>
      <DealInviteSlideOver
        open={!!dealInviteOnDisplay}
        dealInviteId={dealInviteOnDisplay}
        onClose={() => setDealInviteOnDisplay('')}
      />
      <FilterPanel activeFilters={variables?.filters} filters={filters} refetch={refetch} />
      <DataTable
        data={dealInvites}
        onClick={dealInvite => setDealInviteOnDisplay(dealInvite.id)}
        columns={[
          {
            label: 'Name',
            fieldName: 'user.name',
          },
          {
            label: 'Note',
            fieldName: 'note',
          },
          {
            label: 'Minimum Investment Amount',
            fieldName: 'minimumInvestmentAmount',
            type: 'CURRENCY',
          },
          {
            label: 'Maximum Investment Amount',
            fieldName: 'maximumInvestmentAmount',
            type: 'CURRENCY',
          },
          {
            label: 'Status',
            fieldName: 'status',
            type: 'STATUS',
          },
          {
            label: 'Created At',
            fieldName: 'createdAt',
            type: 'DATETIME',
          },
        ]}
        hasNextPage={pageInfo.hasNextPage}
        onLoadMore={() =>
          fetchMore({
            variables: {
              cursor: pageInfo.cursor,
            },
          })
        }
      />
    </>
  );
};

export default DealInvites;
