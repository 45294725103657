import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { InvestmentEntityStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_SYNDICATE_MUTATION = gql(`
  mutation UpdateInvestmentEntityStatus($id: ID!, $status: InvestmentEntityStatusEnumType!) {
    updateInvestmentEntityStatus(id: $id, status: $status) {
      id
      status
      updatedAt
    }
  }
`);

const UpdateInvestmentEntityStatus: FC<{
  id: string;
  status: InvestmentEntityStatusEnumType;
}> = ({ id, status }) => {
  const [updateInvestmentEntity, { loading, error }] = useMutation(UPDATE_SYNDICATE_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateInvestmentEntity({
          variables: {
            id,
            status: data.status,
          },
        });
      }}
      title="Status"
    >
      <FormInput
        type="select"
        fieldName="status"
        label="Status"
        defaultValue={status}
        options={[
          {
            value: 'ACTIVE',
            label: 'Active',
            color: 'bg-green-500',
          },
          {
            value: 'INACTIVE',
            label: 'Inactive',
            color: 'bg-red-500',
          },
          {
            value: 'UNDER_REVIEW',
            label: 'Under Review',
            color: 'bg-yellow-500',
          },
        ]}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateInvestmentEntityStatus;
