import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const CREATE_SHARE_CLASS_MUTATION = gql(`
  mutation CreateShareClass(
    $name: String!
    $companyId: ID!
    $issuePricePerShare: Float!
    $issueDate: String!
  ) {
    createShareClass(
      name: $name
      companyId: $companyId
      issuePricePerShare: $issuePricePerShare
      issueDate: $issueDate
    ) {
      id
      name
      issuePricePerShare
      issueDate
      createdAt
    }
  }
`);

const CreateShareClassDialogButton: FC<{ companyId: string }> = ({ companyId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [createShareClass, { loading, error }] = useMutation(CREATE_SHARE_CLASS_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          id: companyId,
          __typename: 'CompanyType',
        }),
        fields: {
          shareClasses(existingShareClassesRef) {
            const newShareClassNodeRef = cache.writeFragment({
              data: data.createShareClass,
              fragment: gql(`
                fragment NewShareClass on ShareClassType {
                  id
                  name
                  issuePricePerShare
                  issueDate                   
                  createdAt
                }
              `),
            });

            return [...existingShareClassesRef, newShareClassNodeRef];
          },
        },
      });
    },
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Create Share Class</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Create Share Class</DialogTitle>
          <DialogSubTitle>Fill in the details below to create a new share class</DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              createShareClass({
                variables: {
                  companyId,
                  name: data.name,
                  issuePricePerShare: data.issuePricePerShare,
                  issueDate: data.issueDate,
                },
              }).then(() => toggleDialog(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput
              type="text"
              fieldName="name"
              label="Name"
              defaultValue=""
              validators={{ required: true }}
            />
            <FormInput
              type="currency"
              fieldName="issuePricePerShare"
              label="Issue Price Per Share"
              defaultValue=""
              validators={{ required: true }}
            />
            <FormInput
              type="date"
              fieldName="issueDate"
              label="Issue Date"
              defaultValue=""
              validators={{ required: true }}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateShareClassDialogButton;
