import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const SEND_PAYMENT_REMINDER_MUTATION = gql(`
  mutation SendPaymentReminderForClosing($id: ID!) {
    sendPaymentReminderForClosing(id: $id)
  }
`);

const SendPaymentReminderButton: FC<{ id: string }> = ({ id }) => {
  const [sendPaymentReminderForClosing, { loading, error }] = useMutation(
    SEND_PAYMENT_REMINDER_MUTATION
  );

  return (
    <ConfirmButton
      onConfirm={() => sendPaymentReminderForClosing({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will send the user an email reminding them to make the payment."
    >
      Send Payment Reminder
    </ConfirmButton>
  );
};

export default SendPaymentReminderButton;
