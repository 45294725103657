import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { CompanyStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_COMPANY_MUTATION = gql(`
  mutation UpdateCompanyStatus($id: ID!, $status: CompanyStatusEnumType!) {
    updateCompanyStatus(id: $id, status: $status) {
      id
      status
      updatedAt
    }
  }
`);

const UpdateCompanyStatus: FC<{ id: string; status: CompanyStatusEnumType }> = ({ id, status }) => {
  const [updateCompany, { loading, error }] = useMutation(UPDATE_COMPANY_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateCompany({
          variables: {
            id,
            status: data.status,
          },
        });
      }}
      title="Status"
    >
      <FormInput
        type="select"
        fieldName="status"
        label="Status"
        defaultValue={status}
        options={[
          { value: 'ACTIVE', label: 'Active', color: 'bg-green-500' },
          { value: 'INACTIVE', label: 'Inactive', color: 'bg-red-500' },
        ]}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateCompanyStatus;
