import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

import SendDealDigestDialogButton from './SendDealDigestDialogButton';

const DEALS_QUERY = gql(`
  query fetchDealsForDealDigest($cursor: ID, $limit: Int, $filters: DealsFilterType) {
    deals(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        companyInformation {
          company {
            name
          }
        }
        type
        allocation
        closingDate
        status
        createdAt
        updatedAt
      }
      pageInfo {
        hasNextPage
        cursor
        totalCount
      }
    }
  }
`);

const DealDigest: FC = () => {
  const [selectedDealIds, setSelectedDealIds] = useState<string[]>([]);

  const { loading, error, data, refetch, fetchMore } = useQuery(DEALS_QUERY, {
    variables: {
      limit: 50,
      filters: {
        showInDealDigest: true,
      },
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const deals = data.deals.nodes;
    const pageInfo = data.deals.pageInfo;

    return (
      <>
        <div>
          <SendDealDigestDialogButton selectedDealIds={selectedDealIds} />
        </div>
        <DataTable
          data={deals}
          searchFields={['type']}
          onSelect={(ids: string[]) => {
            const dealIdsData = deals
              .filter(deal => ids.find(str => str === deal.id))
              .map(deal => deal.id);
            setSelectedDealIds(dealIdsData);
          }}
          columns={[
            {
              label: 'Select',
              fieldName: 'id',
              type: 'SELECT',
            },
            {
              label: 'Company',
              fieldName: 'companyInformation.company.name',
            },
            {
              label: 'Status',
              fieldName: 'status',
              type: 'STATUS',
            },
            {
              label: 'Type',
              fieldName: 'type',
            },
            {
              label: 'Allocation',
              fieldName: 'allocation',
            },
            {
              label: 'Closing Date',
              fieldName: 'closingDate',
              type: 'DATETIME',
            },
            {
              label: 'Created At',
              fieldName: 'createdAt',
              type: 'DATETIME',
            },
            {
              label: 'Updated At',
              fieldName: 'updatedAt',
              type: 'DATETIME',
            },
          ]}
          hasNextPage={pageInfo.hasNextPage}
          onLoadMore={() =>
            fetchMore({
              variables: {
                cursor: pageInfo.cursor,
              },
            })
          }
        />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Deal Digest"
        subTitle="Choose deals to include in the AL Deal Digest"
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default DealDigest;
