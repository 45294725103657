import { useQuery } from '@apollo/client';
import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const DISCLOSURES_QUERY = gql(`
  query DealDisclosures($id: ID!) {
    deal(id: $id) {
      id
      dealDisclosures {
        id
        risksNote
        hasLeadInvestedInCompanyBefore
        doesLeadHaveAdvisoryShares
        isLeadEmployeeAtCompany
        doesLeadHaveRelativesInCompany
        hasSameTermsForCoInvestors
        isLeadInvestingOnSameTerms
        isLeadInvestingFromFund
        otherDisclosuresNote
      }
    }
  }
`);

const UPSERT_DEAL_DISCLOSURES_MUTATION = gql(`
  mutation UpsertDealDisclosures(
    $dealId: ID!
    $risksNote: String
    $hasLeadInvestedInCompanyBefore: Boolean!
    $doesLeadHaveAdvisoryShares: Boolean!
    $isLeadEmployeeAtCompany: Boolean!
    $doesLeadHaveRelativesInCompany: Boolean!
    $hasSameTermsForCoInvestors: Boolean!
    $isLeadInvestingOnSameTerms: Boolean!
    $isLeadInvestingFromFund: Boolean!
    $otherDisclosuresNote: String
  ) {
    upsertDealDisclosures(
      dealId: $dealId
      risksNote: $risksNote
      hasLeadInvestedInCompanyBefore: $hasLeadInvestedInCompanyBefore
      doesLeadHaveAdvisoryShares: $doesLeadHaveAdvisoryShares
      isLeadEmployeeAtCompany: $isLeadEmployeeAtCompany
      doesLeadHaveRelativesInCompany: $doesLeadHaveRelativesInCompany
      hasSameTermsForCoInvestors: $hasSameTermsForCoInvestors
      isLeadInvestingOnSameTerms: $isLeadInvestingOnSameTerms
      isLeadInvestingFromFund: $isLeadInvestingFromFund
      otherDisclosuresNote: $otherDisclosuresNote
    ) {
      id
      dealDisclosures {
        id
        risksNote
        hasLeadInvestedInCompanyBefore
        doesLeadHaveAdvisoryShares
        isLeadEmployeeAtCompany
        doesLeadHaveRelativesInCompany
        hasSameTermsForCoInvestors
        isLeadInvestingOnSameTerms
        isLeadInvestingFromFund
        otherDisclosuresNote
      }
    }
  }
`);

const DealRisksDisclosures: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const { loading, error, data, refetch } = useQuery(DISCLOSURES_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const [upsertDealDisclosures, { loading: updateLoading, error: updateError }] = useMutation(
    UPSERT_DEAL_DISCLOSURES_MUTATION
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data || !dealId) return <ErrorMessage error={error} refetch={refetch} />;

  return (
    <FormPanelWithReadMode
      loading={updateLoading}
      error={updateError}
      onSubmit={data =>
        upsertDealDisclosures({
          variables: {
            dealId,
            risksNote: data.risksNote,
            hasLeadInvestedInCompanyBefore: data.hasLeadInvestedInCompanyBefore,
            doesLeadHaveAdvisoryShares: data.doesLeadHaveAdvisoryShares,
            isLeadEmployeeAtCompany: data.isLeadEmployeeAtCompany,
            doesLeadHaveRelativesInCompany: data.doesLeadHaveRelativesInCompany,
            hasSameTermsForCoInvestors: data.hasSameTermsForCoInvestors,
            isLeadInvestingOnSameTerms: data.isLeadInvestingOnSameTerms,
            isLeadInvestingFromFund: data.isLeadInvestingFromFund,
            otherDisclosuresNote: data.otherDisclosures,
          },
        })
      }
      title="Risks and Disclosures"
    >
      <FormInput
        fieldName="risksNote"
        type="textarea"
        defaultValue={data.deal.dealDisclosures?.risksNote || 'Risks and Disclosures'}
        helperText="Disclosing specific risk factors is required for compliance"
        label=""
      />
      <FormInput
        type="switch"
        fieldName="hasLeadInvestedInCompanyBefore"
        label="I invested in a previous round"
        defaultValue={data.deal.dealDisclosures?.hasLeadInvestedInCompanyBefore || false}
      />
      <FormInput
        type="switch"
        fieldName="doesLeadHaveAdvisoryShares"
        label="I have advisory shares, warrants, or other shares"
        defaultValue={data.deal.dealDisclosures?.doesLeadHaveAdvisoryShares || false}
      />
      <FormInput
        type="switch"
        fieldName="isLeadEmployeeAtCompany"
        label="I am an officer or employee of the company"
        defaultValue={data.deal.dealDisclosures?.isLeadEmployeeAtCompany || false}
      />
      <FormInput
        type="switch"
        fieldName="doesLeadHaveRelativesInCompany"
        label="I have a relative or significant other working at the company"
        defaultValue={data.deal.dealDisclosures?.doesLeadHaveRelativesInCompany || false}
      />
      <FormInput
        type="switch"
        fieldName="hasSameTermsForCoInvestors"
        label="I have the same terms as other co-investors"
        defaultValue={data.deal.dealDisclosures?.hasSameTermsForCoInvestors || false}
      />
      <FormInput
        type="switch"
        fieldName="isLeadInvestingOnSameTerms"
        label="I am investing on the same terms as other investors"
        defaultValue={data.deal.dealDisclosures?.isLeadInvestingOnSameTerms || false}
      />
      <FormInput
        type="switch"
        fieldName="isLeadInvestingFromFund"
        label="I am investing from a fund"
        defaultValue={data.deal.dealDisclosures?.isLeadInvestingFromFund || false}
      />
      <FormInput
        type="switch"
        fieldName="otherPotentialConflicts"
        label="I have other potential conflicts to disclose"
        defaultValue={data.deal.dealDisclosures?.otherDisclosuresNote ? true : false}
      />
      <FormInput
        type="textarea"
        fieldName="otherDisclosures"
        label="Other Disclosures"
        defaultValue={data.deal.dealDisclosures?.otherDisclosuresNote || ''}
        conditionsToShow={{
          matches: [
            {
              field: 'otherPotentialConflicts',
              condition: '===',
              value: 'true',
            },
          ],
        }}
      />
    </FormPanelWithReadMode>
  );
};

export default DealRisksDisclosures;
