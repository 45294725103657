import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_USER_FROM_SYNDICATE_MUTATION = gql(`
  mutation RemoveLeadFromSyndicate($syndicateLeadId: ID!) {
    removeLeadFromSyndicate(syndicateLeadId: $syndicateLeadId) {
      id
      syndicate {
        id
      }
    }
  }
`);

const RemoveLeadFromSyndicateButton: FC<{
  syndicateLeadId: string;
  onClose: () => void;
}> = ({ syndicateLeadId, onClose }) => {
  const [removeLeadFromSyndicate, { loading, error }] = useMutation(
    REMOVE_USER_FROM_SYNDICATE_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;
        const { removeLeadFromSyndicate } = data;
        cache.modify({
          id: `SyndicateType:${removeLeadFromSyndicate.syndicate.id}`,
          fields: {
            syndicateLeads(existingLeadsRef, { readField }) {
              return existingLeadsRef.filter(
                leadRef => syndicateLeadId !== readField('id', leadRef)
              );
            },
          },
        });
      },
      onCompleted() {
        onClose();
      },
    }
  );

  return (
    <ConfirmButton
      onConfirm={() => removeLeadFromSyndicate({ variables: { syndicateLeadId } })}
      loading={loading}
      error={error}
      description="This will delete this user from this syndicate."
    >
      Remove from Syndicate
    </ConfirmButton>
  );
};

export default RemoveLeadFromSyndicateButton;
