import { UserRoleEnumType } from '__generated__/graphql';

export default function userRoleToReadable(role: UserRoleEnumType): {
  label: string;
  color: string;
} {
  switch (role) {
    case UserRoleEnumType.Investor:
      return {
        label: 'Investor',
        color: 'green',
      };
    case UserRoleEnumType.OperationsHandler:
      return {
        label: 'Operations Handler',
        color: 'amber',
      };
    case UserRoleEnumType.LegalHandler:
      return {
        label: 'Legal Handler',
        color: 'blue',
      };
    case UserRoleEnumType.Admin:
      return {
        label: 'Admin',
        color: 'purple',
      };
    case UserRoleEnumType.SuperAdmin:
      return {
        label: 'Super Admin',
        color: 'yellow',
      };
    default:
      return {
        label: role,
        color: 'gray',
      };
  }
}
