import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const SEND_DRAWDOWN_NOTICE_MUTATION = gql(`
  mutation SendDrawdownNoticeForClosing($id: ID!) {
    sendDrawdownNoticeForClosing(id: $id)
  }
`);

const SendDrawdownNoticeButton: FC<{ id: string }> = ({ id }) => {
  const [sendDrawdownNoticeForClosing, { loading, error }] = useMutation(
    SEND_DRAWDOWN_NOTICE_MUTATION
  );

  return (
    <ConfirmButton
      onConfirm={() => sendDrawdownNoticeForClosing({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will send the user an email calling for them to sign the drawdown notice."
    >
      Send Drawdown Notice
    </ConfirmButton>
  );
};

export default SendDrawdownNoticeButton;
