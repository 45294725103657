import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

const INITIATE_COMPANY_REDIS_SYNC = gql(`
  mutation AddCompaniesToIndex {
    addCompaniesToIndex
    {
      count
      status
    }
  }
`);

const InitiateCompanySyncDialogButton: FC<{}> = () => {
  const [showDialog, toggleDialog] = useState(false);
  const [, toggleConfirmDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [initiateCompanyRedisSync] = useMutation(INITIATE_COMPANY_REDIS_SYNC);

  function renderContent() {
    return (
      <DialogPanel>
        <DialogTitle>Add Companies To Index</DialogTitle>

        <DialogActions>
          <Button
            variant="secondary"
            onClick={() => {
              // @ts-ignore
              buttonRef.current ? buttonRef.current.cancel() : toggleDialog(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              initiateCompanyRedisSync()
                .then(() => {
                  toggleConfirmDialog(true);
                  toggleDialog(true);
                })
                .catch(error => {
                  toggleDialog(false);
                });
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </DialogPanel>
    );
  }

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Initiate Company Sync on Redis</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        {renderContent()}
      </Dialog>
    </>
  );
};

export default InitiateCompanySyncDialogButton;
