import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment } from 'react';

function Dialog({ open, onClose, children }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <HeadlessDialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div>{children}</div>
            </Transition.Child>
          </div>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  );
}

function DialogTitle({ children }) {
  return (
    <HeadlessDialog.Title as="h2" className="text-xl font-semibold leading-6 text-gray-700 mb-1">
      {children}
    </HeadlessDialog.Title>
  );
}

function DialogSubTitle({ children }) {
  return (
    <HeadlessDialog.Title as="h3" className="text-lg leading-6 text-gray-600 mb-5">
      {children}
    </HeadlessDialog.Title>
  );
}

function DialogPanel({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <HeadlessDialog.Panel
      className={classNames(
        'relative transform overflow-hidden rounded-lg bg-white px-4 pb-24 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg',
        className
      )}
    >
      {children}
    </HeadlessDialog.Panel>
  );
}

function DialogActions({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <div
      className={classNames(
        'absolute bottom-0 left-0 w-full px-4 pb-6 grid gap-3 grid-flow-row-dense grid-cols-2',
        className
      )}
    >
      {children}
    </div>
  );
}

export { Dialog, DialogTitle, DialogSubTitle, DialogPanel, DialogActions };
