import { FC } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';

import alIndiaLogo from 'media/alIndiaLogo.png';
import closeIcon from 'media/icons/close.svg';

import theme from './theme.module.scss';

const FocusLayout: FC<{ title?: string; children: any; onClose?: () => void }> = props => {
  const navigate = useNavigate();

  const renderContent = () => {
    return (
      <div className={theme.container}>
        <div className={theme.sidebar}>
          <NavLink to="/">
            <img
              className={theme.logo}
              src={alIndiaLogo}
              alt="AL India logo"
              height={'256px'}
              width={'256px'}
            />
          </NavLink>
          <div
            className={theme.closeButton}
            onClick={() => (props.onClose ? props.onClose() : navigate(-1))}
          >
            <img src={closeIcon} alt="Close" height="28px" />
          </div>
        </div>
        <div className={theme.main}>{props.children}</div>
      </div>
    );
  };

  return <>{renderContent()}</>;
};

export default FocusLayout;
