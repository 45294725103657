import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import ErrorMessage from 'components/ErrorMessage';

const REMOVE_EXTERNAL_INVESTOR_MUTATION = gql(`
  mutation RemoveDealExternalInvestor($id: ID!) {
    removeDealExternalInvestor(id: $id) {
      id
      deal {
        id
      }
    }
  }
`);

const RemoveExternalInvestorDialogButton: FC<{
  id: string;
}> = ({ id }) => {
  const [showConfirmDialog, toggleConfirmDialog] = useState(false);

  const [removeDealExternalInvestor, { loading, error }] = useMutation(
    REMOVE_EXTERNAL_INVESTOR_MUTATION,
    {
      variables: {
        id,
      },
      update(cache, { data }) {
        if (!data) return;
        const { removeDealExternalInvestor } = data;
        cache.modify({
          id: `DealType:${removeDealExternalInvestor.deal.id}`,
          fields: {
            externalInvestors(existingInvestorsRefs, { readField }) {
              return existingInvestorsRefs.filter(
                investorRef => removeDealExternalInvestor.id !== readField('id', investorRef)
              );
            },
          },
        });
      },
    }
  );

  return (
    <>
      <Button
        variant="text"
        className="text-red-900 hover:text-red-700"
        onClick={() => toggleConfirmDialog(true)}
      >
        Remove
      </Button>
      <Dialog open={showConfirmDialog} onClose={() => toggleConfirmDialog(false)}>
        <DialogPanel>
          <DialogTitle>Are you sure?</DialogTitle>
          You can add this investor again if needed
          {error && <ErrorMessage error={error} type="alert" />}
          <DialogActions>
            <Button variant="secondary" onClick={() => toggleConfirmDialog(false)}>
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() =>
                removeDealExternalInvestor().then(() => {
                  toggleConfirmDialog(false);
                })
              }
            >
              Remove
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default RemoveExternalInvestorDialogButton;
