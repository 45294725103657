import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_FUND_CLOSING_MUTATION = gql(`
  mutation RemoveFundClosing($id: ID!) {
    removeFundClosing(id: $id) {
      id
      fund {
        id
        statistics {
          totalRaisedAmount
        }
      }
    }
  }
`);

const RemoveFundClosingButton: FC<{ id: string; onClose: () => void }> = ({ id, onClose }) => {
  const [removeFundClosing, { loading, error }] = useMutation(REMOVE_FUND_CLOSING_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          id: data.removeFundClosing.fund.id,
          __typename: 'FundType',
        }),
        fields: {
          closings(existingClosingsRef, { readField }) {
            const updatedClosingsRef = { ...existingClosingsRef };
            updatedClosingsRef.nodes = existingClosingsRef.nodes.filter(
              closingRef => id !== readField('id', closingRef)
            );
            return updatedClosingsRef;
          },
        },
      });
    },
    onCompleted() {
      onClose();
    },
  });

  return (
    <ConfirmButton
      onConfirm={() => removeFundClosing({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will delete all data concerning this fund closing."
    >
      Remove
    </ConfirmButton>
  );
};

export default RemoveFundClosingButton;
