import { ChevronLeftIcon } from '@heroicons/react/20/solid';

export default function Navbar({
  title,
  subTitle,
  onBackButtonClick,
  actionButtons,
}: {
  title: string;
  subTitle: string;
  onBackButtonClick?: () => void;
  actionButtons?: React.ReactNode[];
}) {
  return (
    <div className="lg:flex lg:items-center lg:justify-between">
      <div className="min-w-0 flex-1">
        <div className="flex items-center gap-2">
          {onBackButtonClick && (
            <button
              type="button"
              className="rounded-full p-1 text-indigo-900 shadow-sm hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-50"
              onClick={onBackButtonClick}
            >
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          )}
          <div>
            <h2 className="text-xl font-bold leading-7 text-gray-700 sm:truncate sm:text-xl sm:tracking-tight">
              {title}
            </h2>
            <div className="flex items-center text-md text-gray-600">{subTitle}</div>
          </div>
        </div>
      </div>
      <div className="mt-5 flex lg:ml-4 lg:mt-0">
        <span className="isolate inline-flex rounded-md shadow-sm">
          {actionButtons?.map((button, index) => (
            <span key={`action-button-${index}`}>{button}</span>
          ))}
        </span>
      </div>
    </div>
  );
}
