import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import AuditsPreview from 'components/Audit/AuditsPreview';
import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';

import DeleteSyndicateButton from './DeleteSyndicateButton';
import UpdateSyndicate from './UpdateSyndicate';
import UpdateSyndicateAccountManager from './UpdateSyndicateManager';
import UpdateSyndicateStatus from './UpdateSyndicateStatus';

const SYNDICATE_QUERY = gql(`
  query getSyndicateInformation($id: ID!) {
    syndicate(id: $id) {
      id
      oldPlatformId
      name
      tagline
      description
      isPrivate
      status
      manager {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`);

const SyndicateInformation: FC = () => {
  const { syndicateId } = useParams<{ syndicateId: string }>() as { syndicateId: string };

  const { loading, error, data, refetch } = useQuery(SYNDICATE_QUERY, {
    variables: {
      id: syndicateId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const syndicate = data.syndicate;

  return (
    <div className="flex flex-col space-y-8">
      <UpdateSyndicateAccountManager
        id={syndicate.id}
        manager={{
          id: syndicate.manager?.id,
          name: syndicate.manager?.name,
        }}
      />
      <DetailsPanel
        data={[
          {
            label: 'Old Platform ID',
            value: syndicate.oldPlatformId,
          },
        ]}
      />
      <UpdateSyndicate
        id={syndicate.id}
        name={syndicate.name}
        tagline={syndicate.tagline}
        description={syndicate.description}
        isPrivate={syndicate.isPrivate}
      />
      <UpdateSyndicateStatus id={syndicate.id} status={syndicate.status} />
      <AuditsPreview id={syndicate.id} type="SYNDICATE" />
      <DeleteSyndicateButton id={syndicate.id} />
    </div>
  );
};

export default SyndicateInformation;
