import { FC, useState } from 'react';
import QuillEditor from 'react-quill';
import 'react-quill/dist/quill.snow.css';

type EditorProps = {
  prevData?: string;
  readOnly?: boolean;
  onChange?: (any) => void;
};

const Editor: FC<EditorProps> = ({ prevData, readOnly, onChange }) => {
  const [value, setValue] = useState(prevData);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ align: ['right', 'center', 'justify'] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
  ];

  return (
    <div className="mt-1">
      <QuillEditor
        theme="snow"
        modules={modules}
        formats={formats}
        style={{
          minHeight: '200px',
          marginBottom: '2.5rem',
        }}
        value={value}
        onChange={value => {
          setValue(value);
          if (onChange) onChange(value);
        }}
      />
    </div>
  );
};

export default Editor;
