import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_PLATFORM_PREFERENCES_MUTATION = gql(`
  mutation UpdatePlatformPreferences(
    $id: ID!
    $whatsappEnabled: Boolean!
    $emailEnabled: Boolean!
    $smsEnabled: Boolean!
    $pushNotificationsEnabled: Boolean!
    $permissionsEnabled: Boolean!
    $redisEnabled: Boolean!
  ) {
    updatePlatformPreferences(
      id: $id
      whatsappEnabled: $whatsappEnabled
      emailEnabled: $emailEnabled
      smsEnabled: $smsEnabled    
      pushNotificationsEnabled: $pushNotificationsEnabled
      permissionsEnabled: $permissionsEnabled
      redisEnabled: $redisEnabled
    ) {
      id
      whatsappEnabled
      emailEnabled
      smsEnabled
      pushNotificationsEnabled
      permissionsEnabled
      redisEnabled
    }
  }
`);

const PlatformPreferences: FC<{
  id: string;
  whatsappEnabled: boolean;
  emailEnabled: boolean;
  smsEnabled: boolean;
  pushNotificationsEnabled: boolean;
  redisEnabled: boolean;
  permissionsEnabled: boolean;
}> = ({
  id,
  whatsappEnabled,
  emailEnabled,
  smsEnabled,
  pushNotificationsEnabled,
  redisEnabled,
  permissionsEnabled,
}) => {
  const [updatePlatformPreferences, { loading, error }] = useMutation(
    UPDATE_PLATFORM_PREFERENCES_MUTATION
  );

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updatePlatformPreferences({
          variables: {
            id,
            whatsappEnabled: data.whatsappEnabled,
            emailEnabled: data.emailEnabled,
            smsEnabled: data.smsEnabled,
            pushNotificationsEnabled: data.pushNotificationsEnabled,
            redisEnabled: data.redisEnabled,
            permissionsEnabled: data.permissionsEnabled,
          },
        });
      }}
      title="Platform Preferences"
    >
      <FormInput
        fullWidth
        type="switch"
        fieldName="whatsappEnabled"
        label="Whatsapp Enabled"
        defaultValue={whatsappEnabled}
      />
      <FormInput
        fullWidth
        type="switch"
        fieldName="emailEnabled"
        label="Email Enabled"
        defaultValue={emailEnabled}
      />
      <FormInput
        fullWidth
        type="switch"
        fieldName="smsEnabled"
        label="SMS Enabled"
        defaultValue={smsEnabled}
      />
      <FormInput
        fullWidth
        type="switch"
        fieldName="pushNotificationsEnabled"
        label="Push Notifications Enabled"
        defaultValue={pushNotificationsEnabled}
      />
      <FormInput
        fullWidth
        type="switch"
        fieldName="redisEnabled"
        label="Redis Enabled"
        defaultValue={redisEnabled}
      />
      <FormInput
        fullWidth
        type="switch"
        fieldName="permissionsEnabled"
        label="Permissions Enabled"
        defaultValue={permissionsEnabled}
      />
    </FormPanelWithReadMode>
  );
};

export default PlatformPreferences;
