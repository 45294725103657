import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import {
  ConvertibleAssetStatusEnumType,
  ConvertibleAssetTypeEnumType,
} from '__generated__/graphql';
import { FC, useRef } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const UPDATE_CONVERTIBLE_ASSET_MUTATION = gql(`
  mutation UpdateConvertibleAssetDialog(
    $id: ID!
    $type: ConvertibleAssetTypeEnumType!
    $value: Float!
    $maturityDate: String
    $interestRate: Float
    $status: ConvertibleAssetStatusEnumType!
  ) {
    updateConvertibleAsset(
      id: $id
      type: $type
      value: $value
      maturityDate: $maturityDate
      interestRate: $interestRate
      status: $status
    ) {
      id
      type
      value
      maturityDate
      interestRate
      status
      updatedAt
    }
  }
`);

const UpdateConvertibleAssetDialog: FC<{
  convertibleAsset: {
    id: string;
    type: ConvertibleAssetTypeEnumType;
    value: number;
    status: ConvertibleAssetStatusEnumType;
    maturityDate?: string | null;
    interestRate?: number | null;
  };
  onClose: () => void;
}> = ({ convertibleAsset, onClose }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [updateConvertibleAsset, { loading, error }] = useMutation(
    UPDATE_CONVERTIBLE_ASSET_MUTATION
  );

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <DialogPanel>
          <DialogTitle>Update Convertible Class</DialogTitle>
          <DialogSubTitle>Make changes to the convertible class</DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={onClose}
            onSubmit={data =>
              updateConvertibleAsset({
                variables: {
                  id: convertibleAsset.id,
                  type: data.type,
                  value: data.value,
                  maturityDate: data.maturityDate,
                  interestRate: data.interestRate,
                  status: data.status,
                },
              }).then(onClose)
            }
            buttonRef={buttonRef}
          >
            <FormInput
              type="select"
              fieldName="type"
              label="Type"
              defaultValue={convertibleAsset.type}
              validators={{ required: true }}
              options={[
                {
                  label: 'Convertible Equity',
                  value: ConvertibleAssetTypeEnumType.ConvertibleEquity,
                },
                { label: 'Convertible Note', value: ConvertibleAssetTypeEnumType.ConvertibleNote },
              ]}
            />
            <FormInput
              type="currency"
              fieldName="value"
              label="Value"
              defaultValue={convertibleAsset.value}
              validators={{ required: true }}
            />
            <FormInput
              type="date"
              fieldName="maturityDate"
              label="Maturity Date"
              defaultValue={convertibleAsset.maturityDate}
              validators={{ required: true }}
            />
            <FormInput
              type="number"
              fieldName="interestRate"
              label="Interest Rate"
              defaultValue={convertibleAsset.interestRate}
              validators={{ required: true }}
            />
            <FormInput
              type="select"
              fieldName="status"
              label="Status"
              defaultValue={convertibleAsset.status}
              validators={{ required: true }}
              options={[
                { label: 'Active', value: ConvertibleAssetStatusEnumType.Active },
                { label: 'Inactive', value: ConvertibleAssetStatusEnumType.Inactive },
                { label: 'Converted', value: ConvertibleAssetStatusEnumType.Converted },
                { label: 'Under Review', value: ConvertibleAssetStatusEnumType.UnderReview },
              ]}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default UpdateConvertibleAssetDialog;
