import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const CREATE_INVESTMENT_ENTITY_MUTATION = gql(`
  mutation CreateInvestmentEntity(
    $userId: ID!
    $name: String!
    $type: InvestmentEntityTypeEnumType!
  ) {
    createInvestmentEntity(userId: $userId, name: $name, type: $type) {
      id
      name
      type
      status
      createdAt
      updatedAt
    }
  }
`);

const CreateInvestmentEntityDialogButton: FC<{
  userId: string;
}> = ({ userId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [createInvestmentEntity, { loading, error }] = useMutation(
    CREATE_INVESTMENT_ENTITY_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;
        cache.modify({
          fields: {
            investmentEntities(existingInvestmentEntitiesRef) {
              const newInvestmentEntityNodeRef = cache.writeFragment({
                data: data.createInvestmentEntity,
                fragment: gql(`
                fragment NewInvestmentEntity on InvestmentEntityType {
                  id
                  name
                  type
                  status
                  createdAt
                  updatedAt
                }
              `),
              });
              const updatedInvestmentEntitiesRef = { ...existingInvestmentEntitiesRef };
              updatedInvestmentEntitiesRef.nodes = [
                newInvestmentEntityNodeRef,
                ...updatedInvestmentEntitiesRef.nodes,
              ];
              return updatedInvestmentEntitiesRef;
            },
          },
        });
      },
    }
  );

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Create InvestmentEntity</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Create an Investment Entity</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              createInvestmentEntity({
                variables: {
                  userId,
                  name: data.name,
                  type: data.type,
                },
              }).then(() => toggleDialog(false));
            }}
            buttonRef={buttonRef}
          >
            <FormInput type="text" fieldName="name" label="Name" defaultValue="" />
            <FormInput
              type="select"
              fieldName="type"
              label="Type"
              defaultValue={'INDIVIDUAL'}
              options={[
                { value: 'INDIVIDUAL', label: 'Individual' },
                { value: 'COMPANY', label: 'Company' },
                { value: 'UNKNOWN', label: 'Unknown' },
              ]}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateInvestmentEntityDialogButton;
