import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ShareAssetStatusEnumType } from '__generated__/graphql';
import { FC, useRef } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

const UPDATE_SHARE_ASSET_MUTATION = gql(`
  mutation UpdateShareAssetDialog(
    $id: ID!
    $issueNumberOfShares: Float!
    $numberOfShares: Float!
    $status: ShareAssetStatusEnumType!
  ) {
    updateShareAsset(
      id: $id
      issueNumberOfShares: $issueNumberOfShares
      numberOfShares: $numberOfShares
      status: $status
    ) {
      id
      issueNumberOfShares
      numberOfShares
      status
      updatedAt
    }
  }
`);

const UpdateShareAssetDialog: FC<{
  shareAsset: {
    id: string;
    issueNumberOfShares: number;
    numberOfShares: number;
    status: ShareAssetStatusEnumType;
  };
  onClose: () => void;
}> = ({ shareAsset, onClose }) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [updateShareAsset, { loading, error }] = useMutation(UPDATE_SHARE_ASSET_MUTATION);

  return (
    <>
      <Dialog open={true} onClose={onClose}>
        <DialogPanel>
          <DialogTitle>Update Share Class</DialogTitle>
          <DialogSubTitle>Make changes to the share class</DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={onClose}
            onSubmit={data =>
              updateShareAsset({
                variables: {
                  id: shareAsset.id,
                  issueNumberOfShares: data.issueNumberOfShares,
                  numberOfShares: data.numberOfShares,
                  status: data.status,
                },
              }).then(onClose)
            }
            buttonRef={buttonRef}
          >
            <FormInput
              type="number"
              fieldName="issueNumberOfShares"
              label="Issue Number of Shares"
              defaultValue={shareAsset.issueNumberOfShares}
              validators={{ required: true }}
            />
            <FormInput
              type="number"
              fieldName="numberOfShares"
              label="Number of Shares"
              defaultValue={shareAsset.numberOfShares}
              validators={{ required: true }}
            />
            <FormInput
              type="select"
              fieldName="status"
              label="Status"
              defaultValue={shareAsset.status}
              validators={{ required: true }}
              options={[
                { value: ShareAssetStatusEnumType.Active, label: 'Active' },
                { value: ShareAssetStatusEnumType.Inactive, label: 'Inactive' },
                { value: ShareAssetStatusEnumType.Exited, label: 'Exited' },
                { value: ShareAssetStatusEnumType.UnderReview, label: 'Under Review' },
              ]}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default UpdateShareAssetDialog;
