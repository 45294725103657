import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_CONVERTIBLE_CLASS_MUTATION = gql(`
  mutation UpdateConvertibleClass(
    $id: ID!
    $name: String!
    $valuationOrCap: Float!
    $discount: Float!
    $issueDate: String!
  ) {
    updateConvertibleClass(
      id: $id
      name: $name
      valuationOrCap: $valuationOrCap
      discount: $discount
      issueDate: $issueDate
    ) {
      id
      name
      valuationOrCap
      discount
      issueDate
      updatedAt
    }
  }
`);

const UpdateConvertibleClass: FC<{
  id: string;
  name: string;
  valuationOrCap: number;
  discount: number;
  issueDate: string;
}> = ({ id, name, valuationOrCap, discount, issueDate }) => {
  const [updateConvertibleClass, { loading, error }] = useMutation(
    UPDATE_CONVERTIBLE_CLASS_MUTATION
  );

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data =>
        updateConvertibleClass({
          variables: {
            id,
            name: data.name,
            valuationOrCap: data.valuationOrCap,
            discount: data.discount,
            issueDate: dayjs(data.issueDate).format('YYYY-MM-DD'),
          },
        })
      }
    >
      <FormInput
        type="text"
        fieldName="name"
        label="Name"
        defaultValue={name}
        validators={{ required: true }}
      />
      <FormInput
        type="currency"
        fieldName="valuationOrCap"
        label="Valuation or Cap"
        defaultValue={valuationOrCap}
        validators={{ required: true }}
      />
      <FormInput
        type="number"
        fieldName="discount"
        label="Discount"
        defaultValue={discount}
        validators={{ required: true }}
      />
      <FormInput
        type="date"
        fieldName="issueDate"
        label="Issue Date"
        defaultValue={issueDate}
        validators={{ required: true }}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateConvertibleClass;
