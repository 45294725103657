const transactionTypeOptions = [
  { label: 'Primary (shares bought from the company)', value: 'PRIMARY' },
  { label: 'Secondary (shares bought from an existing stakeholder)', value: 'SECONDARY' },
];

const fundingRoundNameOptions = [
  { label: 'Pre-Seed', value: 'PRE_SEED' },
  { label: 'Seed', value: 'SEED' },
  { label: 'Seed+', value: 'SEED_PLUS' },
  { label: 'Series A', value: 'SERIES_A' },
  { label: 'Series A+', value: 'SERIES_A_PLUS' },
  { label: 'Series B', value: 'SERIES_B' },
  { label: 'Series B+', value: 'SERIES_B_PLUS' },
  { label: 'Series C', value: 'SERIES_C' },
  { label: 'Series C+', value: 'SERIES_C_PLUS' },
  { label: 'Series D', value: 'SERIES_D' },
  { label: 'Series D+', value: 'SERIES_D_PLUS' },
  { label: 'Series E', value: 'SERIES_E' },
  { label: 'Series E+', value: 'SERIES_E_PLUS' },
  { label: 'Series F', value: 'SERIES_F' },
  { label: 'Series F+', value: 'SERIES_F_PLUS' },
  { label: 'Recap', value: 'RECAP' },
  { label: 'Other', value: 'OTHER' },
];

const dealTypeOptions = [
  { label: 'Syndicate', value: 'SYNDICATED' },
  { label: 'AngelList RUV', value: 'RUV' },
];

const shareClassTypeOptions = [
  { label: 'Preferred', value: 'PREFERRED' },
  { label: 'Common', value: 'COMMON' },
];

const prorataRightsTypeOptions = [
  { label: 'Yes', value: 'YES' },
  { label: 'No', value: 'NO' },
  { label: 'Not Sure', value: 'NOT_SURE' },
];

const premiumToLastRoundTypeOptions = [
  { label: 'Premium', value: 'PREMIUM' },
  { label: 'Discount', value: 'DISCOUNT' },
];

const valuationTypeOptions = [
  { label: 'Pre-Money', value: 'PRE_MONEY' },
  { label: 'Post-Money', value: 'POST_MONEY' },
];

const financialInstrumentTypeOptions = [
  { label: 'Equity', value: 'EQUITY' },
  { label: 'Debt', value: 'DEBT' },
  { label: 'Convertible Note', value: 'CONVERTIBLE_NOTE' },
  { label: 'SAFE', value: 'SAFE' },
];

export {
  transactionTypeOptions,
  fundingRoundNameOptions,
  dealTypeOptions,
  shareClassTypeOptions,
  prorataRightsTypeOptions,
  premiumToLastRoundTypeOptions,
  valuationTypeOptions,
  financialInstrumentTypeOptions,
};
