import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_SYNDICATE_MUTATION = gql(`
  mutation RemoveSyndicate($id: ID!) {
    removeSyndicate(id: $id) {
      id
    }
  }
`);

const RemoveSyndicateButton: FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();

  const [removeSyndicate, { loading, error }] = useMutation(REMOVE_SYNDICATE_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        fields: {
          syndicates(existingSyndicatesRef, { readField }) {
            const updatedSyndicatesRef = { ...existingSyndicatesRef };
            updatedSyndicatesRef.nodes = existingSyndicatesRef.nodes.filter(
              syndicateRef => id !== readField('id', syndicateRef)
            );
            return updatedSyndicatesRef;
          },
        },
      });
    },
    onCompleted() {
      navigate('/syndicates');
    },
  });

  return (
    <ConfirmButton
      onConfirm={() => removeSyndicate({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will delete all data concerning this Syndicate including any deals."
    >
      Remove
    </ConfirmButton>
  );
};

export default RemoveSyndicateButton;
