import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_SHARE_ASSET_MUTATION = gql(`
  mutation RemoveShareAsset($id: ID!) {
    removeShareAsset(id: $id) {
      id
    }
  }
`);

const RemoveShareAssetButton: FC<{ id: string; dealId: string; onRemove?: () => void }> = ({
  id,
  dealId,
  onRemove,
}) => {
  const [removeShareAsset, { loading, error }] = useMutation(REMOVE_SHARE_ASSET_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          id: dealId,
          __typename: 'DealType',
        }),
        fields: {
          shareAssets(existingShareAssetsRef, { readField }) {
            return existingShareAssetsRef.filter(
              shareAssetRef => id !== readField('id', shareAssetRef)
            );
          },
        },
      });
    },
    onCompleted() {
      if (onRemove) onRemove();
    },
  });

  return (
    <ConfirmButton
      variant="secondary"
      onConfirm={() => removeShareAsset({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will delete all data concerning this Share Asset."
    >
      Remove
    </ConfirmButton>
  );
};

export default RemoveShareAssetButton;
