import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import AuditsPreview from 'components/Audit/AuditsPreview';
import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';

import DeleteDealButton from './DeleteDealButton';
import UpdateDealManager from './UpdateDealManager';
import UpdateDealStatus from './UpdateDealStatus';
import UpdateMultipleOnInvestmentOverride from './UpdateMultipleOnInvestmentOverride';

const DEAL_ADMIN_QUERY = gql(`
  query DealAdmin($id: ID!) {
    deal(id: $id) {
      id
      multipleOnInvestmentOverride
      syndicate {
        id
        name
      }
      companyInformation {
        company {
          id
          name
        }
      }
      status
      manager {
        id
        name
      }
      finalisedAt
    }
  }
`);

const DealAdminPage: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const { data, loading, error, refetch } = useQuery(DEAL_ADMIN_QUERY, {
    variables: {
      id: dealId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const deal = data.deal;

  return (
    <>
      <DetailsPanel
        data={[
          {
            label: 'Syndicate',
            value: deal.syndicate.name,
            type: 'LINK',
            navigateTo: `/syndicates/${deal.syndicate.id}`,
          },
          {
            label: 'Company',
            value: deal.companyInformation.company.name,
            type: 'LINK',
            navigateTo: `/companies/${deal.companyInformation.company.id}`,
          },
        ]}
      />
      <UpdateMultipleOnInvestmentOverride
        id={deal.id}
        multipleOnInvestmentOverride={deal.multipleOnInvestmentOverride}
      />
      <UpdateDealStatus id={dealId} status={deal.status} finalisedAt={deal.finalisedAt} />
      <UpdateDealManager
        id={deal.id}
        manager={{ id: deal.manager?.id, name: deal.manager?.name }}
      />
      <AuditsPreview id={deal.id} type="DEAL" />
      <DeleteDealButton id={deal.id} />
    </>
  );
};

export default DealAdminPage;
