import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import EmptyState from 'primitives/EmptyState';
import LoadingIndicator from 'primitives/LoadingIndicator';

import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';

const INVESTMENT_ENTITY_QUERY = gql(`
  query getInvestmentEntityInvestorApplication($id: ID!) {
    investmentEntity(id: $id) {
      id
      investorApplication {
        id
        eligibilityResponse
        eligibilityProofDocument {
          url
          name
        }
        targetAllocation
        investingExperience
      }
    }
  }
`);

const InvestmentEntityInvestorApplication: FC = () => {
  const { investmentEntityId } = useParams<{ investmentEntityId: string }>() as {
    investmentEntityId: string;
  };

  const { loading, error, data, refetch } = useQuery(INVESTMENT_ENTITY_QUERY, {
    variables: {
      id: investmentEntityId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const investmentEntity = data.investmentEntity;

  if (!investmentEntity.investorApplication) {
    return (
      <EmptyState
        title="No Investor Application"
        description="This investment entity does not have an investor application. It could be because it pre-dates the new platform launch."
      />
    );
  }

  return (
    <DetailsPanel
      title="Investor Application"
      data={[
        {
          label: 'Eligibility Response',
          value: investmentEntity.investorApplication.eligibilityResponse,
        },
        {
          label: 'Eligibility Proof Document',
          value: investmentEntity.investorApplication.eligibilityProofDocument.url,
          type: 'FILE',
        },
        {
          label: 'Target Allocation',
          value: investmentEntity.investorApplication.targetAllocation,
        },
        {
          label: 'Investing Experience',
          value: investmentEntity.investorApplication.investingExperience,
        },
      ]}
    />
  );
};

export default InvestmentEntityInvestorApplication;
