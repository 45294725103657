import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import { FormInput, FormPanel } from 'components/FormPanel';

import SearchUsers from '../Leads/SearchUsers';

const ADD_USER_TO_SYNDICATE_MUTATION = gql(`
  mutation AddUserToSyndicate($syndicateId: String!, $userId: String!) {
    addUserToSyndicate(syndicateId: $syndicateId, userId: $userId) {
      id
      user {
        id
        name
        email
      }
      createdAt
    }
  }
`);

const AddUserToSyndicateDialogButton: FC<{ syndicateId: string }> = ({ syndicateId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const [addUserToSyndicate, { loading, error }] = useMutation(ADD_USER_TO_SYNDICATE_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      const { addUserToSyndicate } = data;
      cache.modify({
        id: `SyndicateType:${syndicateId}`,
        fields: {
          syndicateUsers(existingUsersRef = []) {
            const newUserRef = cache.writeFragment({
              data: { ...addUserToSyndicate },
              fragment: gql(`
                fragment NewUserToSyndicate on SyndicateUserType {
                  id
                  user {
                    id
                    name
                    email
                  }
                  createdAt
                }
              `),
            });

            return [...existingUsersRef.nodes, newUserRef];
          },
        },
      });
    },
    onCompleted() {
      toggleDialog(false);
    },
  });

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Add User</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Add a user</DialogTitle>
          <FormPanel
            loading={loading}
            error={error}
            onCancel={() => toggleDialog(false)}
            onSubmit={data => {
              addUserToSyndicate({
                variables: {
                  syndicateId,
                  userId: data.user.id,
                },
              });
            }}
            buttonRef={buttonRef}
          >
            <FormInput
              type="custom"
              fieldName="user"
              label="User"
              defaultValue={''}
              customInput={SearchUsers}
            />
          </FormPanel>
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel();
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default AddUserToSyndicateDialogButton;
