import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogTitle } from 'primitives/Dialog';

import ErrorMessage from 'components/ErrorMessage';

const SEND_DEAL_DIGEST_MUTATION = gql(`
  mutation SendDealDigest($dealIds: [ID]) {
    sendDealDigest(dealIds: $dealIds)
  }
`);

const SendDealDigestDialogButton: FC<{
  selectedDealIds: string[];
}> = ({ selectedDealIds }) => {
  const [showDialog, toggleDialog] = useState(false);

  const [sendDealDigest, { loading, error }] = useMutation(SEND_DEAL_DIGEST_MUTATION);

  return (
    <>
      <Button onClick={() => toggleDialog(true)} disabled={selectedDealIds.length === 0}>
        Send Deal Digest
      </Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Send Deal Digest</DialogTitle>
          Are you sure you want to send out the deal digest?
          {error ? <ErrorMessage error={error} /> : null}
          <DialogActions>
            <Button
              loading={loading}
              onClick={() => {
                sendDealDigest({
                  variables: {
                    dealIds: selectedDealIds,
                  },
                }).then(() => {
                  toggleDialog(false);
                });
              }}
            >
              Send
            </Button>
            <Button variant="secondary" onClick={() => toggleDialog(false)}>
              Close
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default SendDealDigestDialogButton;
