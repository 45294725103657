import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import ConfirmButton from 'primitives/ConfirmButton';

const REMOVE_DEAL_INVITE_MUTATION = gql(`
  mutation RemoveDealInvite($id: ID!) {
    removeDealInvite(id: $id) {
      id
      deal {
        id
      }
    }
  }
`);

const RemoveDealInviteButton: FC<{ id: string }> = ({ id }) => {
  const navigate = useNavigate();

  const [removeDealInvite, { loading, error }] = useMutation(REMOVE_DEAL_INVITE_MUTATION, {
    update(cache, { data }) {
      if (!data) return;
      cache.modify({
        id: cache.identify({
          id: data.removeDealInvite.deal.id,
          __typename: 'DealType',
        }),
        fields: {
          dealInvites(existingDealInvitesRef, { readField }) {
            const updatedDealInvitesRef = { ...existingDealInvitesRef };
            updatedDealInvitesRef.nodes = existingDealInvitesRef.nodes.filter(
              dealInviteRef => id !== readField('id', dealInviteRef)
            );
            return updatedDealInvitesRef;
          },
        },
      });
    },
    onCompleted() {
      navigate(-1);
    },
  });

  return (
    <ConfirmButton
      onConfirm={() => removeDealInvite({ variables: { id } })}
      loading={loading}
      error={error}
      description="This will delete all data concerning this DealInvite."
    >
      Remove
    </ConfirmButton>
  );
};

export default RemoveDealInviteButton;
