import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import EmptyState from 'primitives/EmptyState';
import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import CashAssetCard from './CashAssetCard';
import ConvertibleClassCard from './ConvertibleClassCard';
import ConvertibleValuationChangeCard from './ConvertibleValuationChangeCard';
import CreateConvertibleClassDialogButton from './CreateConvertibleClassDialogButton';
import CreateConvertibleValuationChangeDialogButton from './CreateConvertibleValuationChangeDialogButton';
import CreateShareClassDialogButton from './CreateShareClassDialogButton';
import CreateShareValuationChangeDialogButton from './CreateShareValuationDialogButton';
import ShareClassCard from './ShareClassCard';
import ShareValuationChangeCard from './ShareValuationChangeCard';

const ASSETS_QUERY = gql(`
  query fetchAssetsByCompanyId($id: ID!) {
    company(id: $id) {
      id
      shareClasses {
        id
        name
        issuePricePerShare
        latestPricePerShare
        issueDate
        createdAt
        shareAssets {
          id
          status
          numberOfShares
          createdAt
          deal {
            id
            schemeName
            statistics {
              totalWiredAmount
              multiple
            }
          }
        }
      }
      convertibleClasses {
        id
        name
        valuationOrCap
        latestValuationOrCap
        discount
        issueDate
        createdAt
        convertibleAssets {
          id
          type
          status
          value
          maturityDate
          convertedAt
          createdAt
          deal {
            id
            schemeName
            statistics {
              totalWiredAmount
              multiple
            }
          }
        }
      }
      cashAssets {
        id
        status
        value
        createdAt
        deal {
          id
          schemeName
        }
      }
      shareValuationChanges {
        id
        pricePerShare
        effectiveFrom
        wasConvertedFromUSD
        createdAt
        shareClasses {
          id
          name
        }
      }
      convertibleValuationChanges {
        id
        valuationMultiplier
        effectiveFrom
        createdAt
        convertibleClasses {
          id
          name
        }
      }
    }
  }
`);

const CompanyAssets: FC = () => {
  const { companyId } = useParams<{ companyId: string }>() as { companyId: string };

  const { loading, error, data, refetch } = useQuery(ASSETS_QUERY, {
    variables: {
      id: companyId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const shareClasses = data.company.shareClasses;
  const convertibleClasses = data.company.convertibleClasses;
  const shareValuationChanges = data.company.shareValuationChanges;
  const convertibleValuationChanges = data.company.convertibleValuationChanges;
  const cashAssets = data.company.cashAssets;

  function getDateToCompare(h: any) {
    const date =
      h.__typename === 'ShareClassType' || h.__typename === 'ConvertibleClassType'
        ? h.issueDate
        : h.__typename === 'ShareValuationChangeType' ||
          h.__typename === 'ConvertibleValuationChangeType'
        ? h.effectiveFrom
        : h.createdAt;

    return date;
  }

  // TODO: This entire logic should be handled by the backend
  const combinedHistory = [
    ...shareClasses,
    ...convertibleClasses,
    ...cashAssets,
    ...shareValuationChanges,
    ...convertibleValuationChanges,
  ].sort((a, b) => {
    return dayjs(getDateToCompare(b)).diff(dayjs(getDateToCompare(a)));
  });

  return (
    <>
      <div className="flex justify-between items-end mb-4">
        <h2 className="font-semibold">Asset History</h2>
        <div className="flex gap-x-2">
          <CreateShareClassDialogButton companyId={companyId} />
          <CreateConvertibleClassDialogButton companyId={companyId} />
          <CreateShareValuationChangeDialogButton
            companyId={companyId}
            shareClasses={shareClasses}
          />
          <CreateConvertibleValuationChangeDialogButton
            companyId={companyId}
            convertibleClasses={convertibleClasses}
          />
        </div>
      </div>
      {combinedHistory.length > 0 ? (
        <ul className="space-y-6">
          {combinedHistory.map((history, historyIdx) => (
            <li key={history.id} className="relative flex gap-x-2">
              <div
                className={classNames(
                  historyIdx === combinedHistory.length - 1 ? 'h-1.5' : '-bottom-6',
                  'absolute left-0 top-0 flex w-6 justify-center'
                )}
              >
                <div className="w-px bg-indigo-200" />
              </div>
              <div className="relative flex h-1.5 w-6 flex-none items-center justify-center bg-white">
                <div className="mt-3.5 h-1.5 w-1.5 rounded-full bg-indigo-400 ring-1 ring-indigo-500" />
              </div>
              <div className="w-full">
                <p className="text-sm text-gray-500 mb-2">
                  {dayjs(getDateToCompare(history)).format('DD MMMM YYYY')}
                </p>
                {history.__typename === 'ShareClassType' && (
                  <ShareClassCard
                    id={history.id}
                    key={history.id}
                    name={history.name}
                    issueDate={history.issueDate}
                    issuePricePerShare={history.issuePricePerShare}
                    latestPricePerShare={history.latestPricePerShare}
                    assets={history.shareAssets}
                  />
                )}
                {history.__typename === 'ConvertibleClassType' && (
                  <ConvertibleClassCard
                    id={history.id}
                    key={history.id}
                    name={history.name}
                    valuationOrCap={history.valuationOrCap}
                    latestValuationOrCap={history.latestValuationOrCap}
                    discount={history.discount}
                    issueDate={history.issueDate}
                    assets={history.convertibleAssets}
                  />
                )}
                {history.__typename === 'CashAssetType' && (
                  <CashAssetCard asset={history} onClick={() => {}} />
                )}
                {history.__typename === 'ShareValuationChangeType' && (
                  <ShareValuationChangeCard
                    id={history.id}
                    pricePerShare={history.pricePerShare}
                    effectiveFrom={history.effectiveFrom}
                    wasConvertedFromUSD={history.wasConvertedFromUSD}
                    createdAt={history.createdAt}
                    shareClasses={history.shareClasses}
                  />
                )}
                {history.__typename === 'ConvertibleValuationChangeType' && (
                  <ConvertibleValuationChangeCard
                    id={history.id}
                    valuationMultiplier={history.valuationMultiplier}
                    effectiveFrom={history.effectiveFrom}
                    createdAt={history.createdAt}
                    convertibleClasses={history.convertibleClasses}
                  />
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <EmptyState
          title="No Asset Classes Added Yet"
          description="This company does not have any asset classes yet."
        />
      )}
      <div className="mt-16" />
    </>
  );
};

export default CompanyAssets;
