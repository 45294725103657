import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { ClosingStatusEnumType } from '__generated__/graphql';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_CLOSING_MUTATION = gql(`
  mutation UpdateClosingStatus(
    $id: ID!
    $status: ClosingStatusEnumType!
    $wiredAt: String
    $executedAt: String
    $finalisedAt: String
  ) {
    updateClosingStatus(
      id: $id
      status: $status
      wiredAt: $wiredAt
      executedAt: $executedAt
      finalisedAt: $finalisedAt
    ) {
      id
      status
      wiredAt
      executedAt
      finalisedAt
      updatedAt
      deal {
        id
        statistics {
          totalCommittedAmount
        }
      }
    }
  }
`);

const UpdateClosingStatus: FC<{
  id: string;
  status: ClosingStatusEnumType;
  wiredAt?: string | null;
  executedAt?: string | null;
  finalisedAt?: string | null;
}> = ({ id, status, wiredAt, executedAt, finalisedAt }) => {
  const [updateClosing, { loading, error }] = useMutation(UPDATE_CLOSING_MUTATION, {
    refetchQueries: ['getClosingAudit', 'getClosingEmailAudit'],
  });

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateClosing({
          variables: {
            id,
            status: data.status,
            wiredAt: data.wiredAt,
            executedAt: data.executedAt,
            finalisedAt: data.finalisedAt,
          },
        });
      }}
    >
      <FormInput
        type="select"
        fieldName="status"
        label="Status"
        defaultValue={status}
        options={[
          { value: 'COMMITTED', label: 'Committed', color: 'bg-yellow-500' },
          { value: 'TRANSFERRED', label: 'Transferred', color: 'bg-purple-500' },
          { value: 'ACTIVE', label: 'Active', color: 'bg-green-500' },
          { value: 'CANCELLED', label: 'Cancelled', color: 'bg-red-500' },
        ]}
      />
      <FormInput
        type="date"
        fieldName="wiredAt"
        label="Transferred At"
        defaultValue={wiredAt || ''}
        conditionsToShow={{
          matches: [
            {
              field: 'status',
              condition: '===',
              value: 'TRANSFERRED',
            },
          ],
          type: 'some',
        }}
      />
      <FormInput
        type="date"
        fieldName="finalisedAt"
        label="Finalised At"
        defaultValue={finalisedAt || ''}
        conditionsToShow={{
          matches: [
            {
              field: 'status',
              condition: '===',
              value: 'ACTIVE',
            },
          ],
        }}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateClosingStatus;
