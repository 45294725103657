import { useMutation } from '@apollo/client';
import { UserIcon } from '@heroicons/react/24/outline';
import { gql } from '__generated__/gql';
import { FC, useRef, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogActions, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanel } from 'components/FormPanel';

import SelectSyndicateLead from './SelectSyndicateLead';

const CREATE_CARRY_RECIPIENT_MUTATION = gql(`
  mutation CreateDealCarryRecipient($investmentEntityId: ID!, $dealId: ID!, $percentage: Float!) {
    createDealCarryRecipient(
      investmentEntityId: $investmentEntityId
      dealId: $dealId
      percentage: $percentage
    ) {
      id
      percentage
      investmentEntity {
        id
        user {
          id
          name
        }
      }
    }
  }
`);

const CreateDealCarryRecipientDialogButton: FC<{
  dealId: string;
  syndicateLeads: {
    id: string;
    user: {
      id: string;
      name: string;
      investmentEntities?:
        | {
            id: string;
            name: string;
          }[]
        | null;
    };
  }[];
}> = ({ dealId, syndicateLeads }) => {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [syndicateLeadId, setSyndicateLeadId] = useState<string | null>('');

  const [createDealCarryRecipient, { loading, error }] = useMutation(
    CREATE_CARRY_RECIPIENT_MUTATION,
    {
      update(cache, { data }) {
        if (!data) return;

        cache.modify({
          id: cache.identify({
            __typename: 'DealType',
            id: dealId,
          }),
          fields: {
            carryRecipients(existingCarryRecipients = []) {
              const newCarryRecipientRef = cache.writeFragment({
                data: data.createDealCarryRecipient,
                fragment: gql(`
                  fragment NewCarryRecipient on DealCarryRecipientType {
                    id
                    percentage
                    investmentEntity {
                      id
                      user {
                        id
                        name
                      }
                    }
                  }
              `),
              });

              return [...existingCarryRecipients, newCarryRecipientRef];
            },
          },
        });
      },
    }
  );

  function renderForm() {
    if (!syndicateLeadId) return <div className="h-40 w-96" />;
    const syndicateLead = syndicateLeads.find(
      syndicateLead => syndicateLead.id === syndicateLeadId
    );
    if (!syndicateLead) {
      // This should never happen because syndicateLeadId is always set from the list of syndicateLeads
      throw new Error('Syndicate lead not found');
    }

    const user = syndicateLead.user;
    const investmentEntities = user.investmentEntities;

    if (!investmentEntities || investmentEntities.length === 0)
      return (
        <ErrorMessage
          title="No investment entities found"
          message="Carry can only be assigned to Syndicate Leads with active investment entities. Please contact the Syndicate Lead to add an investment entity."
        />
      );

    return (
      <FormPanel
        loading={loading}
        error={error}
        onCancel={() => setOpen(false)}
        onSubmit={data => {
          createDealCarryRecipient({
            variables: {
              dealId,
              investmentEntityId: data.investmentEntityId,
              percentage: data.percentage,
            },
          }).then(() => {
            setSyndicateLeadId(null);
            setOpen(false);
          });
        }}
        buttonRef={buttonRef}
      >
        <FormInput
          fullWidth
          type="number"
          fieldName="percentage"
          label="Carry Points"
          defaultValue={15}
        />
        <FormInput
          fullWidth
          type="select"
          fieldName="investmentEntityId"
          label="Investment Entity"
          defaultValue=""
          options={investmentEntities.map(investmentEntity => ({
            label: investmentEntity.name,
            value: investmentEntity.id,
          }))}
        />
      </FormPanel>
    );
  }

  return (
    <>
      <Button leadingIcon={<UserIcon />} variant="secondary" onClick={() => setOpen(true)}>
        Create Deal Carry Recipient
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogPanel>
          <DialogTitle>Create a Deal Carry Recipient</DialogTitle>
          <DialogSubTitle>Choose how much carry this recipient will receive</DialogSubTitle>
          <SelectSyndicateLead
            syndicateLeads={syndicateLeads}
            onSelect={syndicateLeadId => {
              setSyndicateLeadId(syndicateLeadId);
              // @ts-ignore
              buttonRef.current?.reset();
            }}
          />
          {renderForm()}
          <DialogActions>
            <Button
              variant="secondary"
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.cancel() || setOpen(false);
              }}
            >
              Cancel
            </Button>
            <Button
              loading={loading}
              disabled={!syndicateLeadId}
              onClick={() => {
                // @ts-ignore
                buttonRef.current?.submit();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default CreateDealCarryRecipientDialogButton;
