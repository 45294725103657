import classNames from 'classnames';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import Badge from 'primitives/Badge';

import { formatCurrency } from 'utils/format-helper';

import ShareValuationChangeSlideOver from './ShareValuationChangeSlideOver';

const ShareValuationChangeCard: FC<{
  id: string;
  pricePerShare: number;
  effectiveFrom: string;
  wasConvertedFromUSD: boolean;
  createdAt: string;
  shareClasses: {
    id: string;
    name: string;
  }[];
}> = ({ id, pricePerShare, effectiveFrom, wasConvertedFromUSD, createdAt, shareClasses }) => {
  const [showSlideOver, toggleSlideOver] = useState(false);

  function lineItem(label: string, value: string, color: string = 'text-gray-800') {
    return (
      <div className="my-0.5 gap-x-2 grid grid-cols-2">
        <span className="text-sm leading-5 text-gray-600">{label}:</span>
        <span className={classNames('text-sm font-semibold leading-5', color)}>{value}</span>
      </div>
    );
  }

  return (
    <>
      <ShareValuationChangeSlideOver
        open={showSlideOver}
        shareValuationChangeId={id}
        onClose={() => toggleSlideOver(false)}
      />
      <div
        className="p-4 ring-1 ring-indigo-100 sm:rounded-xl hover:bg-gray-50 hover:cursor-pointer"
        onClick={() => toggleSlideOver(true)}
      >
        <div>
          <Badge label={'Share Valuation Change'} color="red" />
          <p className="mt-1 font-semibold leading-6 text-gray-700">
            Share price changed to {formatCurrency(pricePerShare)}
          </p>
          <div className="grid grid-cols-2 gap-x-4 mt-2">
            {lineItem('Effective From', dayjs(effectiveFrom).format('DD MMMM YYYY'))}
            {lineItem('Price Per Share', formatCurrency(pricePerShare))}
            {wasConvertedFromUSD && lineItem('Was Converted From USD', 'Yes', 'text-green-500')}
            {lineItem('Affects Share Classes', shareClasses.map(sc => sc.name).join(', '))}
            {lineItem('Created On', dayjs(createdAt).format('DD MMMM YYYY'))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareValuationChangeCard;
