import { ConvertibleAssetTypeEnumType } from '__generated__/graphql';

export default function convertibleAssetTypeToReadable(type: ConvertibleAssetTypeEnumType): {
  label: string;
  color: string;
} {
  switch (type) {
    case ConvertibleAssetTypeEnumType.ConvertibleEquity:
      return {
        label: 'Convertible Equity',
        color: 'blue',
      };
    case ConvertibleAssetTypeEnumType.ConvertibleNote:
      return {
        label: 'Convertible Note',
        color: 'purple',
      };
    default:
      return {
        label: type,
        color: 'gray',
      };
  }
}
