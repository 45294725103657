import { COMPANY_AUDIT_QUERY } from './companyAuditHelper';
import { DEAL_AUDIT_QUERY } from './dealAuditHelper';
import { FUND_AUDIT_QUERY } from './fundAuditHelper';
import { INVESTMENT_ENTITY_AUDIT_QUERY } from './investmentEntityAuditHelper';
import { SYNDICATE_AUDIT_QUERY } from './syndicateAuditHelper';

const getQueryPath = (query: string) => {
  switch (query) {
    case 'DEAL':
      return 'deal';
    case 'COMPANY':
      return 'company';
    case 'SYNDICATE':
      return 'syndicate';
    case 'FUND':
      return 'fund';
    case 'INVESTMENT_ENTITY':
      return 'investmentEntity';
    default:
      return 'deal';
  }
};

const getQuery = (query: string) => {
  switch (query) {
    case 'DEAL':
      return DEAL_AUDIT_QUERY;
    case 'COMPANY':
      return COMPANY_AUDIT_QUERY;
    case 'SYNDICATE':
      return SYNDICATE_AUDIT_QUERY;
    case 'FUND':
      return FUND_AUDIT_QUERY;
    case 'INVESTMENT_ENTITY':
      return INVESTMENT_ENTITY_AUDIT_QUERY;
    default:
      return DEAL_AUDIT_QUERY;
  }
};

export { getQueryPath, getQuery };
