import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import DataTable from 'components/DataTable';
import ErrorMessage from 'components/ErrorMessage';

const COMPANY_DEALS_QUERY = gql(`
  query fetchCompanyDeals($id: ID!) {
    company(id: $id) {
      id
      deals {
        id
        schemeName
        type
        allocation
        closingDate
        status
        createdAt
        statistics {
          totalWiredAmount
          multiple
        }
      }
    }
  }
`);

const CompanyDeals: FC = () => {
  const { companyId } = useParams<{ companyId: string }>() as { companyId: string };

  const navigate = useNavigate();
  const { loading, error, data, refetch } = useQuery(COMPANY_DEALS_QUERY, {
    variables: {
      id: companyId,
    },
  });

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const deals = data.company.deals;

  return (
    <DataTable
      data={deals}
      onClick={deal => navigate(`/deals/${deal.id}`)}
      columns={[
        {
          label: 'Scheme',
          fieldName: 'schemeName',
        },
        {
          label: 'Status',
          fieldName: 'status',
          type: 'STATUS',
        },
        {
          label: 'Type',
          fieldName: 'type',
          type: 'DEAL_TYPE',
        },
        {
          label: 'Allocation',
          fieldName: 'allocation',
          type: 'CURRENCY',
        },
        {
          label: 'Closing Date',
          fieldName: 'closingDate',
          type: 'DATE',
        },
        {
          label: 'Total Wired Amount',
          fieldName: 'statistics.totalWiredAmount',
          type: 'CURRENCY',
        },
        {
          label: 'Multiple',
          fieldName: 'statistics.multiple',
          type: 'MULTIPLE',
        },
        {
          label: 'Created At',
          fieldName: 'createdAt',
          type: 'DATE',
        },
      ]}
    />
  );
};

export default CompanyDeals;
