import { gql } from '__generated__';

function getFieldnameForDeal(field: string): string {
  const fieldToReadableNameMap: { [key: string]: string } = {
    stage: 'Stage',
    monthsOfRunway: 'Months of Runway',
    memo: 'Memo',
    pitchDeck: 'Pitch Deck',
    industries: 'Industries',
    geographicalMarkets: 'Geographical Markets',
    transactionType: 'Transaction Type',
    fundingRoundName: 'Funding Round Name',
    fundingRoundSize: 'Funding Round Size',
    financialInstrument: 'Financial Instrument',
    valuationOrCap: 'Valuation or Cap',
    valuationType: 'Valuation Type',
    expectedSharePrice: 'Expected Share Price',
    premiumToLastRoundType: 'Premium to Last Round Type',
    premiumToLastRound: 'Premium to Last Round',
    shareClass: 'Share Class',
    hasPreviouslyRaisedMoney: 'Has Previously Raised Money',
    previouslyRaisedAmount: 'Previously Raised Amount',
    discount: 'Discount',
    pastFinancingNote: 'Past Financing Note',
    prorataRights: 'Prorata Rights',
    minimumInvestment: 'Minimum Investment',
    availableCarryPercentage: 'Available Carry Percentage',
    maximumNumberOfClosings: 'Maximum Number of Closings',
    hasCap: 'Has Cap',
    otherTermsNote: 'Other Terms Note',
    externalInvestorsNote: 'External Investors Note',
    risksNote: 'Risks Note',
    otherDisclosuresNote: 'Other Disclosures Note',
    hasLeadInvestedInCompanyBefore: 'Has Lead Invested in Company Before',
    doesLeadHaveAdvisoryShares: 'Does Lead Have Advisory Shares',
    isLeadEmployeeAtCompany: 'Is Lead Employee at Company',
    doesLeadHaveRelativesInCompany: 'Does Lead Have Relatives in Company',
    hasSameTermsForCoInvestors: 'Has Same Terms for Co-Investors',
    isLeadInvestingOnSameTerms: 'Is Lead Investing on Same Terms',
    isLeadInvestingFromFund: 'Is Lead Investing from Fund',
    name: 'Name',
    investmentEntityId: 'Investment Entity ID',
    dealId: 'Deal ID',
    percentage: 'Percentage',
    description: 'Description',
    amount: 'Amount',
    isLead: 'Is Lead',
    type: 'Deal Type',
    image: 'Image',
    hasIssueWithForeignCurrency: 'Has Issue with Foreign Currency',
    oldPlatformId: 'Old Platform ID',
    oldPlatformDealId: 'Old Platform Deal ID',
    oldPlatformCompanyId: 'Old Platform Company ID',
    oldPlatformInvestmentEntityId: 'Old Platform Investment Entity ID',
    oldPlatformSyndicateId: 'Old Platform Syndicate ID',
    oldPlatformPreviousDealId: 'Old Platform Previous Deal ID',
    previousDealid: 'Previous Deal ID',
    status: 'Status',
    refreshFrequencyInDays: 'Refresh Frequency in Days',
    allocation: 'Allocation',
    showInDealDigest: 'Show in Deal Digest',
    closingDate: 'Closing Date',
    finalisedAt: 'Finalised At',
    totalLeadCarryPercentage: 'Total Lead Carry Percentage',
    cancelledAt: 'Cancelled At',
    adminComments: 'Admin Comments',
    cancellationReason: 'Cancellation Reason',
    schemeName: 'Scheme Name',
    managerId: 'Manager ID',
    url: 'URL',
  };

  return fieldToReadableNameMap[field] || field;
}

const DEAL_AUDIT_QUERY = gql(`
  query DealAudit($id: ID!, $page: Int, $limit: Int) {
    deal(id: $id) {
      audits(page: $page, limit: $limit) {
        nodes {
          operation
          changes {
            fieldName
            oldValue
            newValue
          }
          updatedAt
          updatedBy {
            name
          }
        }
        pageInfo {
          totalCount
          hasNextPage
        }
      }
    }
  }
`);

export { getFieldnameForDeal, DEAL_AUDIT_QUERY };
