import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { UserRoleEnumType } from '__generated__/graphql';
import { FC } from 'react';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_SYNDICATE_MANAGER_MUTATION = gql(`
  mutation UpdateSyndicateManager($id: ID!, $userId: ID!) {
    updateSyndicateManager(id: $id, userId: $userId) {
      id
      manager {
        id
        name
      }
    }
  }
`);

const GET_OPS_USERS_QUERY = gql(`
  query OpsUsers($cursor: ID, $limit: Int, $filters: UsersFilterType) {
    users(cursor: $cursor, limit: $limit, filters: $filters) {
      nodes {
        id
        name
      }
      pageInfo {
        hasNextPage
        cursor
      }
    }
  }
`);

const UpdateSyndicateManager: FC<{
  id: string;
  manager: { name?: string; id?: string };
}> = ({ id, manager }) => {
  const { data, loading, error, refetch } = useQuery(GET_OPS_USERS_QUERY, {
    variables: {
      filters: {
        role: UserRoleEnumType.OperationsHandler,
      },
    },
  });

  const [updateManager] = useMutation(UPDATE_SYNDICATE_MANAGER_MUTATION);

  if (loading) return <LoadingIndicator />;

  if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

  const users = data.users.nodes;

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data => {
        updateManager({
          variables: {
            id,
            userId: data.accountManager,
          },
        });
      }}
      title="Account Manager"
    >
      <FormInput
        type="select"
        fieldName="accountManager"
        label="Account Manager"
        defaultValue={manager.id || ''}
        options={users?.map(user => {
          return { label: user.name, value: user.id };
        })}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateSyndicateManager;
