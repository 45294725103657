import { XCircleIcon } from '@heroicons/react/20/solid';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { cloneElement } from 'react';

export default function Alert({
  type,
  title,
  message,
  buttonText,
  onClick,
}: {
  type: 'info' | 'success' | 'warning' | 'error';
  title: string;
  message?: string;
  buttonText?: string;
  onClick?: () => void;
}) {
  let style = {
    backgroundColor: 'bg-red-50',
    textColor: 'text-red-800',
    iconColor: 'text-red-400',
    buttonStyles:
      'rounded-md bg-red-50 px-2 py-1.5 text-sm font-medium text-red-800 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-red-600 focus:ring-offset-2 focus:ring-offset-red-50',
    icon: <XCircleIcon />,
  };
  if (type === 'info')
    style = {
      backgroundColor: 'bg-blue-50',
      textColor: 'text-blue-800',
      iconColor: 'text-blue-400',
      buttonStyles:
        'rounded-md bg-blue-50 px-2 py-1.5 text-sm font-medium text-blue-800 hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-blue-50',
      icon: <InformationCircleIcon />,
    };
  if (type === 'success')
    style = {
      backgroundColor: 'bg-green-50',
      textColor: 'text-green-800',
      iconColor: 'text-green-400',
      buttonStyles:
        'rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50',
      icon: <CheckCircleIcon />,
    };
  if (type === 'warning')
    style = {
      backgroundColor: 'bg-yellow-50',
      textColor: 'text-yellow-800',
      iconColor: 'text-yellow-400',
      buttonStyles:
        'rounded-md bg-yellow-50 px-2 py-1.5 text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-yellow-600 focus:ring-offset-2 focus:ring-offset-yellow-50',
      icon: <ExclamationTriangleIcon />,
    };

  return (
    <div className={`rounded-md ${style.backgroundColor} p-3 col-span-full`}>
      <div className="flex">
        <div className="flex-shrink-0">
          {cloneElement(style.icon, { className: `h-5 w-5 ${style.iconColor}` })}
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-semibold ${style.textColor}`}>{title}</h3>
          {message && <p className={`mt-1 max-w-xl text-sm ${style.textColor}`}>{message}</p>}
          {buttonText && (
            <div className="mt-4">
              <div className="-mx-2 -my-1.5 flex">
                <button type="button" className={style.buttonStyles} onClick={onClick}>
                  {buttonText}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
