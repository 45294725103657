import { FundingSourceEnumType } from '__generated__/graphql';

export default function fundingSourceToReadable(fundingSource: FundingSourceEnumType): {
  label: string;
  color: string;
} {
  switch (fundingSource) {
    case FundingSourceEnumType.IndianAccount:
      return {
        label: 'Indian Account',
        color: 'green',
      };
    case FundingSourceEnumType.NonResidentOrdinaryAccount:
      return {
        label: 'NRO Account',
        color: 'amber',
      };
    case FundingSourceEnumType.NonResidentExternalAccount:
      return {
        label: 'NRE Account',
        color: 'purple',
      };
    case FundingSourceEnumType.ForeignAccount:
      return {
        label: 'Foreign Account',
        color: 'blue',
      };
    case FundingSourceEnumType.Unknown:
      return {
        label: 'Unknown',
        color: 'gray',
      };
    case FundingSourceEnumType.UnknownNonResidentAccount:
      return {
        label: 'Unknown Non Resident Account',
        color: 'gray',
      };
    default:
      return {
        label: fundingSource,
        color: 'gray',
      };
  }
}
