import classNames from 'classnames';

function TableContainer({ children }) {
  return (
    <div className="sm:mx-6 lg:mx-8 mx-4 my-1 ring-1 ring-gray-200 rounded-lg w-fit lg:w-auto lg:max-w-full">
      {children}
    </div>
  );
}

function Table({ children }) {
  return <table className="sm:min-w-full">{children}</table>;
}

function TableBody({ children }) {
  return <tbody className="divide-y divide-gray-200">{children}</tbody>;
}

function TableHead({ children }) {
  return <thead className="border-b border-gray-200 hover:bg-white">{children}</thead>;
}

function TableHeadRow({ children }) {
  return <tr className="bg-white">{children}</tr>;
}

function TableRow({ children, onClick }: { children: any; onClick?: () => void }) {
  return (
    <tr
      className="cursor-pointer hover:bg-gray-50 transition-colors duration-200 ease-in-out"
      onClick={onClick}
    >
      {children}
    </tr>
  );
}

function TableHeadCell({
  children,
  align = 'left',
  isRounded,
}: {
  children: any;
  align?: 'left' | 'center' | 'right';
  isRounded?: 'left' | 'right';
}) {
  return (
    <th
      scope="col"
      className={classNames('px-3 py-3.5 text-left text-sm font-semibold text-gray-900 max-w-64', {
        'rounded-tl-lg': isRounded === 'left',
        'rounded-tr-lg': isRounded === 'right',
        'text-left': align === 'left',
        'text-center': align === 'center',
        'text-right': align === 'right',
      })}
    >
      {children}
    </th>
  );
}

function TableCell({
  children,
  align = 'left',
  onClick,
}: {
  children: any;
  align?: 'left' | 'center' | 'right';
  onClick?: (e) => void;
}) {
  return (
    <td
      className={classNames(
        'whitespace-nowrap px-3 py-4 text-sm text-gray-700 max-w-64 min-h-fit text-wrap break-words',
        {
          'text-left': align === 'left',
          'text-center': align === 'center',
          'text-right': align === 'right',
        }
      )}
      onClick={onClick}
    >
      {children}
    </td>
  );
}

export {
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableHeadRow,
  TableRow,
  TableHeadCell,
  TableCell,
};
