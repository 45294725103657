import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import DetailsPanel from 'components/DetailsPanel';
import ErrorMessage from 'components/ErrorMessage';

const COMPANY_EVENT_QUERY = gql(`
  query getCompanyEvent($id: ID!) {
    companyEvent(id: $id) {
      id
      label
      type
      notes
      valuePerShare
      valuationMultiplier
      effectiveFrom
      createdAt
      updatedAt
      oldPlatformId
      company {
        id
      }
    }
  }
`);

const CompanyEventSlideOver: FC<{
  open: boolean;
  companyEventId: string;
  onClose: () => void;
}> = ({ open = false, companyEventId, onClose }) => {
  const { loading, error, data, refetch } = useQuery(COMPANY_EVENT_QUERY, {
    variables: {
      id: companyEventId,
    },
    skip: !open,
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const event = data.companyEvent;

    return (
      <DetailsPanel
        data={[
          {
            label: 'Old Platform ID',
            value: event.oldPlatformId,
          },
        ]}
      />
    );
  };

  return (
    <SlideOver
      open={open}
      title="Company Events"
      subTitle="Manage Company Events"
      onClose={onClose}
    >
      {renderContent()}
    </SlideOver>
  );
};

export default CompanyEventSlideOver;
