import { ChevronRightIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

export interface TabItem {
  key: string;
  label: string;
  description: string;
  route: string;
  icon: any;
  exact?: boolean;
}

export default function VerticalTabs({ tabs }: { tabs: TabItem[] }) {
  const location = useLocation();

  return (
    <nav
      className="flex flex-col space-y-2 border border-gray-200 p-1 rounded-lg"
      aria-label="Tabs"
    >
      {tabs.map(tab => {
        function isActive() {
          if (tab?.exact) {
            return location.pathname === tab.route;
          }
          return (
            location.pathname === tab.route ||
            (location.pathname.startsWith(tab.route) && tab.route !== '/')
          );
        }

        return (
          <NavLink
            key={tab.key}
            to={tab.route}
            replace
            className={classNames(
              isActive()
                ? 'bg-indigo-100 text-indigo-700'
                : 'text-gray-800 hover:text-gray-900 hover:bg-gray-100',
              'rounded-md px-3 py-2 text-sm font-medium flex items-start gap-2'
            )}
            aria-current={isActive() ? 'page' : undefined}
          >
            {tab.icon && (
              <img
                src={tab.icon}
                alt="icon"
                className={classNames(
                  isActive() ? 'text-indigo-600' : 'text-gray-400 group-hover:text-indigo-600',
                  'h-5 w-5 shrink-0'
                )}
                aria-hidden="true"
              />
            )}
            <div className="flex-1">
              {tab.label}
              {tab.description && <p className="text-xs text-gray-500">{tab.description}</p>}
            </div>
            <ChevronRightIcon className="h-4 w-4 self-center stroke-2" />
          </NavLink>
        );
      })}
    </nav>
  );
}
