import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC } from 'react';

import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_SHARE_CLASS_MUTATION = gql(`
  mutation UpdateShareClass(
    $id: ID!
    $name: String!
    $issuePricePerShare: Float!
    $issueDate: String!
  ) {
    updateShareClass(
      id: $id
      name: $name
      issuePricePerShare: $issuePricePerShare
      issueDate: $issueDate
    ) {
      id
      name
      issuePricePerShare
      issueDate
      updatedAt
    }
  }
`);

const UpdateShareClass: FC<{
  id: string;
  name: string;
  issuePricePerShare: number;
  issueDate: string;
}> = ({ id, name, issuePricePerShare, issueDate }) => {
  const [updateShareClass, { loading, error }] = useMutation(UPDATE_SHARE_CLASS_MUTATION);

  return (
    <FormPanelWithReadMode
      loading={loading}
      error={error}
      onSubmit={data =>
        updateShareClass({
          variables: {
            id,
            name: data.name,
            issuePricePerShare: data.issuePricePerShare,
            issueDate: dayjs(data.issueDate).format('YYYY-MM-DD'),
          },
        })
      }
    >
      <FormInput
        type="text"
        fieldName="name"
        label="Name"
        defaultValue={name}
        validators={{ required: true }}
      />
      <FormInput
        type="currency"
        fieldName="issuePricePerShare"
        label="Issue Price Per Share"
        defaultValue={issuePricePerShare}
        validators={{ required: true }}
      />
      <FormInput
        type="date"
        fieldName="issueDate"
        label="Issue Date"
        defaultValue={issueDate}
        validators={{ required: true }}
      />
    </FormPanelWithReadMode>
  );
};

export default UpdateShareClass;
