import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import assetIcon from 'media/icons/asset.svg';
import companyEventIcon from 'media/icons/company-event.svg';
import companyExitIcon from 'media/icons/company-exit.svg';
import dealIcon from 'media/icons/deal.svg';
import informationIcon from 'media/icons/information.svg';

const COMPANY_QUERY = gql(`
  query getCompany($id: ID!) {
    company(id: $id) {
      id
      name
    }
  }
`);

const CompanyPage: FC = () => {
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId: string }>() as { companyId: string };

  const { loading, error, data, refetch } = useQuery(COMPANY_QUERY, {
    variables: {
      id: companyId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const company = data?.company;

    const tabs = [
      {
        key: 'information',
        label: 'Information',
        description: 'Basic details',
        icon: informationIcon,
        route: `/companies/${companyId}`,
        exact: true,
      },
      {
        key: 'deals',
        label: 'Deals',
        description: 'Active and past deals',
        icon: dealIcon,
        route: `/companies/${companyId}/deals`,
      },
      {
        key: 'assets',
        label: 'Assets',
        description: 'Company assets',
        icon: assetIcon,
        route: `/companies/${companyId}/assets`,
      },
      {
        key: 'events',
        label: 'Events',
        description: 'Company events',
        icon: companyEventIcon,
        route: `/companies/${companyId}/events`,
      },
      {
        key: 'exits',
        label: 'Exits',
        description: 'Company exits',
        icon: companyExitIcon,
        route: `/companies/${companyId}/exits`,
      },
    ];

    return (
      <>
        <SidebarLayoutNavigation
          title={company?.name || ''}
          subTitle="Company"
          onBackButtonClick={() => navigate('/companies')}
        />
        <SidebarLayoutContent secondaryTabs={tabs}>
          <Outlet />
        </SidebarLayoutContent>
      </>
    );
  };

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default CompanyPage;
