import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import Button from 'primitives/Button';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';

const UPDATE_MULTIPLE_ON_INVESTMENT_OVERRIDE = gql(`
  mutation UpdateMultipleOnInvestmentOverride($id: ID!, $multipleOnInvestmentOverride: Float!) {
    updateMultipleOnInvestmentOverride(id: $id, multipleOnInvestmentOverride: $multipleOnInvestmentOverride) {
      id
      multipleOnInvestmentOverride
    }
  }
`);

const REMOVE_MULTIPLE_ON_INVESTMENT_OVERRIDE = gql(`
  mutation RemoveMultipleOnInvestmentOverride($id: ID!) {
    removeMultipleOnInvestmentOverride(id: $id) {
      id
      multipleOnInvestmentOverride
    }
  }
`);

const UpdateMultipleOnInvestmentOverride: FC<{
  id: string;
  multipleOnInvestmentOverride: number | null | undefined;
}> = ({ id, multipleOnInvestmentOverride }) => {
  const [updateMultipleOnInvestmentOverride, { loading, error }] = useMutation(
    UPDATE_MULTIPLE_ON_INVESTMENT_OVERRIDE
  );
  const [removeMultipleOnInvestmentOverride, { loading: removeLoading, error: removeError }] =
    useMutation(REMOVE_MULTIPLE_ON_INVESTMENT_OVERRIDE, {
      onCompleted: () => {
        window.location.reload();
      },
    });

  return (
    <>
      <FormPanelWithReadMode
        loading={loading}
        error={error}
        onSubmit={data => {
          updateMultipleOnInvestmentOverride({
            variables: {
              id,
              multipleOnInvestmentOverride: data.multipleOnInvestmentOverride,
            },
          });
        }}
      >
        <FormInput
          type="number"
          fieldName="multipleOnInvestmentOverride"
          label="Multiple On Investment Override"
          defaultValue={multipleOnInvestmentOverride}
        />
      </FormPanelWithReadMode>
      <div>
        {removeError && <ErrorMessage error={removeError} />}
        <Button
          loading={removeLoading}
          onClick={() => removeMultipleOnInvestmentOverride({ variables: { id } })}
        >
          Remove Multiple On Investment Override
        </Button>
      </div>
    </>
  );
};

export default UpdateMultipleOnInvestmentOverride;
