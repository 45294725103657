import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import dealIcon from 'media/icons/deal.svg';
import informationIcon from 'media/icons/information.svg';
import networkIcon from 'media/icons/network.svg';
import teamIcon from 'media/icons/team.svg';

const SYNDICATE_QUERY = gql(`
  query getSyndicate($id: ID!) {
    syndicate(id: $id) {
      id
      name
    }
  }
`);

const SyndicatePage: FC = () => {
  const navigate = useNavigate();
  const { syndicateId } = useParams<{ syndicateId: string }>() as {
    syndicateId: string;
  };

  const { loading, error, data, refetch } = useQuery(SYNDICATE_QUERY, {
    variables: {
      id: syndicateId,
    },
  });

  function renderContent() {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const syndicate = data.syndicate;

    const tabs = [
      {
        key: 'information',
        label: 'Information',
        description: 'Basic details',
        icon: informationIcon,
        route: `/syndicates/${syndicateId}`,
        exact: true,
      },
      {
        key: 'deals',
        label: 'Deals',
        description: 'Active and past deals',
        icon: dealIcon,
        route: `/syndicates/${syndicateId}/deals`,
      },
      {
        key: 'leads',
        label: 'Leads',
        description: 'Team information',
        icon: teamIcon,
        route: `/syndicates/${syndicateId}/leads`,
      },
      {
        key: 'users',
        label: 'Users',
        description: 'Syndicate members',
        icon: networkIcon,
        route: `/syndicates/${syndicateId}/users`,
      },
    ];

    return (
      <>
        <SidebarLayoutNavigation
          title={syndicate.name}
          subTitle={'Syndicate'}
          onBackButtonClick={() => navigate(-1)}
        />
        <SidebarLayoutContent secondaryTabs={tabs}>
          <Outlet />
        </SidebarLayoutContent>
      </>
    );
  }

  return <SidebarLayout>{renderContent()}</SidebarLayout>;
};

export default SyndicatePage;
