import { useMutation, useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useParams } from 'react-router-dom';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';
import { FormInput, FormPanelWithReadMode } from 'components/FormPanel';
import MultiSelectFormInput from 'components/MultiSelect/MultiSelectFormInput';
import EditorFormInput from 'components/QuillEditor/EditorFormInput';

import { industryOptions } from '../CompanyInformation/constants';

const COMPANY_MEMO_QUERY = gql(`
  query CompanyMemo($id: ID!) {
    deal(id: $id) {
      id
      status
      companyInformation {
        id
        memo
        industries
        pitchDeck
      }
    }
  }
`);

const UPDATE_COMPANY_MEMO_MUTATION = gql(`
  mutation UpdateCompanyInformationForCreate(
    $dealId: ID!
    $memo: String
    $industries: [IndustryTypeEnumType]
    $pitchDeck: Upload
  ) {
    updateCompanyInformation(
      id: $dealId
      memo: $memo
      industries: $industries
      pitchDeck: $pitchDeck
    ) {
      id
      companyInformation {
        id
        memo
        industries
        pitchDeck
      }
    }
  }
`);

const DealMemo: FC = () => {
  const { dealId } = useParams<{ dealId: string }>() as { dealId: string };

  const { loading, error, data, refetch } = useQuery(COMPANY_MEMO_QUERY, {
    variables: {
      id: dealId,
    },
  });

  const [updateCompanyMemo, { loading: updateLoading, error: updateError }] = useMutation(
    UPDATE_COMPANY_MEMO_MUTATION
  );

  if (loading) return <LoadingIndicator />;

  if (error || !data || !dealId) return <ErrorMessage error={error} refetch={refetch} />;

  return (
    <FormPanelWithReadMode
      loading={updateLoading}
      error={updateError}
      onSubmit={data => {
        console.log('data', data);
        updateCompanyMemo({
          variables: {
            dealId,
            memo: data.dealMemo,
            industries: data.industries,
            pitchDeck: typeof data.pitchDeck === 'string' ? undefined : data.pitchDeck,
          },
        });
      }}
    >
      <FormInput
        fieldName="dealMemo"
        type="custom"
        fullWidth
        defaultValue={data.deal.companyInformation.memo}
        helperText="What’s your investment thesis? Why are you excited about this deal?"
        label="Deal Memo *"
        readOnlyMode={false}
        customInput={EditorFormInput}
        validators={{
          required: true,
        }}
      />
      <FormInput
        type="upload"
        fieldName="pitchDeck"
        label="Pitch Deck"
        helperText="Upto 30MB. Only PDF files are allowed."
        defaultValue={data.deal.companyInformation.pitchDeck || ''}
        maxFileSize={30}
        onlyAcceptsPDF={true}
      />
      <FormInput
        fullWidth
        type="custom"
        fieldName="industries"
        customInput={MultiSelectFormInput}
        label="Select the investment industry of the deal"
        helperText="The business carried out by the company should be such that 100% foreign investment is allowed under the automatic route as per the prevailing foreign exchange laws of India."
        defaultValue={data.deal.companyInformation.industries || []}
        options={industryOptions}
      />
    </FormPanelWithReadMode>
  );
};
export default DealMemo;
