import { FC } from 'react';

import MultiSelect from '.';

const MultiSelectFormInput: FC<{
  onChange: (data: any) => void;
  value?: string;
  readOnly?: boolean;
  label: string;
  options?: {
    label: string;
    value: string;
    color?: string;
  }[];
}> = ({ onChange, value, readOnly, label, options }) => {
  return (
    <>
      <legend className="sr-only">{label}</legend>
      <span className="block text-sm font-medium leading-6 text-gray-900">{label}</span>
      <MultiSelect
        onChange={data => {
          if (onChange) onChange(data);
        }}
        prevData={value}
        readOnly={readOnly}
        options={options}
      />
    </>
  );
};

export default MultiSelectFormInput;
