import { gql } from '__generated__';

const getFieldNameForInvestmentEntity = (field: string) => {
  const fieldToReadableNameMap = {
    oldPlatformId: 'Old Platform ID',
    name: 'Name',
    type: 'Type',
    status: 'Status',
    userId: 'User ID',
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    lineOne: 'Line One',
    lineTwo: 'Line Two',
    city: 'City',
    state: 'State',
    country: 'Country',
    pincode: 'Pincode',
    pan: 'PAN',
    identityProofDocument: 'Identity Proof Document',
    addressProofDocument: 'Address Proof Document',
    dateOfBirth: 'Date of Birth',
    investmentEntityId: 'Investment Entity ID',
    addressId: 'Address ID',
    accountNumber: 'Account Number',
    bankName: 'Bank Name',
    ifscCode: 'IFSC Code',
    accountType: 'Account Type',
    isVerified: 'Is Verified',
  };

  return fieldToReadableNameMap[field] || field;
};

const INVESTMENT_ENTITY_AUDIT_QUERY = gql(`
    query InvestmentEntityAudit($id: ID!, $page: Int, $limit: Int) {
      investmentEntity(id: $id) {
        audits(page: $page, limit: $limit) {
          nodes {
            operation
            changes {
              fieldName
              oldValue
              newValue
            }
            updatedAt
            updatedBy {
              name
            }
          }
          pageInfo {
            totalCount
            hasNextPage
          }
        }
      }
    }
  `);

export { getFieldNameForInvestmentEntity, INVESTMENT_ENTITY_AUDIT_QUERY };
