import { useMutation } from '@apollo/client';
import { gql } from '__generated__/gql';
import dayjs from 'dayjs';
import { FC, useState } from 'react';

import Button from 'primitives/Button';
import { Dialog, DialogPanel, DialogSubTitle, DialogTitle } from 'primitives/Dialog';
import Divider from 'primitives/Divider';

import { FormInput, FormPanel } from 'components/FormPanel';

import countries from 'utils/countries.json';
import states from 'utils/states.json';

const UPSERT_KYC_MUTATION = gql(`
  mutation UpsertKyc(
    $investmentEntityId: ID!
    $pan: String!
    $identityProofDocument: Upload
    $addressProofDocument: Upload
    $dateOfBirth: String!
    $lineOne: String!
    $lineTwo: String
    $city: String!
    $pincode: String!
    $state: String!
    $country: String!
  ) {
    upsertKyc(
      investmentEntityId: $investmentEntityId
      pan: $pan
      identityProofDocument: $identityProofDocument
      addressProofDocument: $addressProofDocument
      dateOfBirth: $dateOfBirth
      lineOne: $lineOne
      lineTwo: $lineTwo
      city: $city
      pincode: $pincode
      state: $state
      country: $country
    ) {
      id
      kyc {
        id
        pan
        identityProofDocument {
          url
          name
        }
        addressProofDocument {
          url
          name
        }
        dateOfBirth
        address {
          id
          lineOne
          lineTwo
          city
          state
          pincode
          country
        }
      }
    }
  }
`);

const UpsertKyc: FC<{
  investmentEntityId: string;
}> = ({ investmentEntityId }) => {
  const [showDialog, toggleDialog] = useState(false);
  const [upsertKyc, { loading, error }] = useMutation(UPSERT_KYC_MUTATION);

  return (
    <>
      <Button onClick={() => toggleDialog(true)}>Add KYC</Button>
      <Dialog open={showDialog} onClose={() => toggleDialog(false)}>
        <DialogPanel>
          <DialogTitle>Add KYC Information</DialogTitle>
          <DialogSubTitle>Add KYC Information</DialogSubTitle>
          <FormPanel
            loading={loading}
            error={error}
            onSubmit={data => {
              upsertKyc({
                variables: {
                  investmentEntityId,
                  pan: data.pan,
                  identityProofDocument: data.identityProofDocument[0],
                  addressProofDocument: data.addressProofDocument[0],
                  dateOfBirth: data.dateOfBirth,
                  lineOne: data.lineOne,
                  lineTwo: data.lineTwo,
                  city: data.city,
                  state: data.state,
                  pincode: data.pincode,
                  country: data.country,
                },
              });
            }}
          >
            <FormInput
              fieldName="pan"
              type="text"
              fullWidth
              defaultValue={''}
              label="PAN"
              validators={{
                required: true,
                isPAN: true,
              }}
              span="3"
            />
            <FormInput
              fieldName="dateOfBirth"
              type="date"
              fullWidth
              defaultValue={''}
              label="Date of Birth"
              maxDate={dayjs()}
              validators={{
                required: true,
              }}
              span="3"
            />
            <Divider className="col-span-full" />
            <FormInput
              fieldName="lineOne"
              type="text"
              fullWidth
              defaultValue={''}
              label="Line One"
              validators={{
                required: true,
              }}
            />
            <FormInput
              fieldName="lineTwo"
              type="text"
              fullWidth
              defaultValue={''}
              label="Line Two"
            />
            <FormInput
              fieldName="city"
              type="text"
              fullWidth
              defaultValue={''}
              label="City"
              validators={{
                required: true,
              }}
            />
            <FormInput
              fieldName="state"
              type="select"
              fullWidth
              defaultValue={''}
              label="State"
              validators={{
                required: true,
              }}
              options={Object.keys(states).map(state => ({ label: states[state], value: state }))}
            />
            <FormInput
              fieldName="pincode"
              type="text"
              fullWidth
              defaultValue={''}
              label="Pincode"
              validators={{
                required: true,
              }}
            />
            <FormInput
              fieldName="country"
              type="select"
              fullWidth
              defaultValue={''}
              label="Country"
              options={Object.keys(countries).map(country => ({
                label: countries[country],
                value: country,
              }))}
              validators={{
                required: true,
              }}
            />
            <Divider className="col-span-full" />
            <FormInput
              type="upload"
              fieldName="identityProofDocument"
              label="Upload Aadhaar Card"
              defaultValue={''}
              validators={{
                required: true,
              }}
              span="3"
            />
            <FormInput
              type="upload"
              fieldName="addressProofDocument"
              label="Upload PAN Card"
              defaultValue={''}
              validators={{
                required: true,
              }}
              span="3"
            />
          </FormPanel>
        </DialogPanel>
      </Dialog>
    </>
  );
};

export default UpsertKyc;
