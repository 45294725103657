import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';

import LoadingIndicator from 'primitives/LoadingIndicator';
import SlideOver from 'primitives/SlideOver';

import ErrorMessage from 'components/ErrorMessage';

import RemoveConvertibleClassButton from './RemoveConvertibleClassButton';
import UpdateConvertibleClass from './UpdateConvertibleClass';

const CONVERTIBLE_CLASS_QUERY = gql(`
  query getConvertibleClass($id: ID!) {
    convertibleClass(id: $id) {
      id
      name
      valuationOrCap
      discount
      issueDate
      createdAt
      updatedAt
    }
  }
`);

const ConvertibleClassSlideOver: FC<{
  open: boolean;
  convertibleClassId: string;
  onClose: () => void;
}> = ({ open = false, convertibleClassId, onClose }) => {
  const { loading, error, data, refetch } = useQuery(CONVERTIBLE_CLASS_QUERY, {
    variables: {
      id: convertibleClassId,
    },
    skip: !open,
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const convertibleClass = data.convertibleClass;

    return (
      <>
        <UpdateConvertibleClass
          id={convertibleClass.id}
          name={convertibleClass.name}
          valuationOrCap={convertibleClass.valuationOrCap}
          discount={convertibleClass.discount}
          issueDate={convertibleClass.issueDate}
        />
        <RemoveConvertibleClassButton id={convertibleClass.id} onRemove={onClose} />
      </>
    );
  };

  return (
    <SlideOver
      open={open}
      onClose={onClose}
      title="Convertible Class"
      subTitle="Manage the convertible class and it's underlying assets"
    >
      {renderContent()}
    </SlideOver>
  );
};

export default ConvertibleClassSlideOver;
