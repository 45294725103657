import { useQuery } from '@apollo/client';
import { gql } from '__generated__/gql';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import SidebarLayout, {
  SidebarLayoutContent,
  SidebarLayoutNavigation,
} from 'layouts/SidebarLayout';

import LoadingIndicator from 'primitives/LoadingIndicator';

import ErrorMessage from 'components/ErrorMessage';

import DeletePostButton from './DeletePostButton';
import UpdatePost from './UpdatePost';
import UpdatePostStatus from './UpdatePostStatus';

const POST_QUERY = gql(`
  query getPost($id: ID!) {
    post(id: $id) {
      id
      title
      message
      status
      createdAt
      updatedAt
    }
  }
`);

const PostPage: FC = () => {
  const navigate = useNavigate();
  const { postId } = useParams<{ postId: string }>() as { postId: string };

  const { loading, error, data, refetch } = useQuery(POST_QUERY, {
    variables: {
      id: postId,
    },
  });

  const renderContent = () => {
    if (loading) return <LoadingIndicator />;

    if (error || !data) return <ErrorMessage error={error} refetch={refetch} />;

    const Post = data.post;

    return (
      <>
        <UpdatePost id={Post.id} title={Post.title} message={Post.message} />
        <UpdatePostStatus id={Post.id} status={Post.status} />
        <DeletePostButton id={Post.id} />
      </>
    );
  };

  return (
    <SidebarLayout>
      <SidebarLayoutNavigation
        title="Post"
        subTitle="Manage Post"
        onBackButtonClick={() => navigate(-1)}
      />
      <SidebarLayoutContent>{renderContent()}</SidebarLayoutContent>
    </SidebarLayout>
  );
};

export default PostPage;
